import React, { useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import {
  AppTranslation,
  IAppTranslation,
  translationLabelTape,
} from '../AppTranslation/AppTranslation'
import { FileAddOutlined } from '@ant-design/icons'

export enum AppButtonColor {
  blue = 'blue',
  lightBlue = 'light-blue',
  aquaBlue = 'aqua-blue',
  red = 'red',
  lightRed = 'light-red',
  lavender = 'lavender',
  green = 'green'
}

export enum AppButtonSize {
  base = 'base',
  small = 'small',
  square = 'square',
}

interface IAppButtonBase {
  disabled?: boolean
  onClick?: () => void
  fullWidth?: boolean
  path?: string
  link?: string
  color?: AppButtonColor
  size?: AppButtonSize
  active?: boolean
  btnRef?: React.Ref<HTMLDivElement>
}

interface IAppButtonChildren extends IAppButtonBase {
  children: JSX.Element
  title?: never
  icoClassName?: never
  imgSrc?: never
}

interface IAppSwipeButton extends IAppButtonBase {
  children: JSX.Element
  title?: never
  icoClassName?: never
  imgSrc?: never
}

interface IAppButtonText extends IAppButtonBase {
  title?: translationLabelTape | IAppTranslation | null
  icoClassName?: string | null
  children?: never
  imgSrc?: never
}

interface IAppButtonImg extends IAppButtonBase {
  imgSrc?: string
  title?: translationLabelTape | IAppTranslation | null
  children?: never
  icoClassName?: never
}

type TProps =
  | IAppButtonChildren
  | IAppButtonText
  | IAppButtonImg
  | IAppSwipeButton

export const AppButton = ({
  children,
  title = null,
  onClick,
  color = AppButtonColor.blue,
  size,
  path,
  link,
  disabled,
  icoClassName = null,
  imgSrc,
  active,
  btnRef,
  fullWidth,
}: TProps) => {
  const btnChildren = useMemo(() => {
    if (!title) {
      return children
    }
    if (typeof title === 'object') {
      return <AppTranslation label={title.label} options={title.options} />
    } else {
      return <AppTranslation label={title} />
    }
  }, [title, children])

  const squareClassNameFromUsage =
    (!title && !!icoClassName) || (!title && !!imgSrc)
  const btnSize = size
    ? size
    : squareClassNameFromUsage
    ? AppButtonSize.square
    : AppButtonSize.base
  const className = `btn ${color} ${btnSize}${disabled ? ' disabled' : ''}${
    active ? ' active' : ''
  } ${fullWidth && 'full-width'}`
  const onClickProps = disabled ? {} : { onClick }
  if (!!link && !disabled) {
    return (
      <a href={link} className={className}>
        {icoClassName && <i className={`an-ico ${icoClassName}`} />}
        {imgSrc && <img src={`${imgSrc}`} alt={''} />}
        {btnChildren && btnChildren}
      </a>
    )
  }
  if (!!path && !disabled) {
    return (
      <NavLink to={path} className={className}>
        {icoClassName && <i className={`an-ico ${icoClassName}`} />}
        {imgSrc && <img src={`${imgSrc}`} alt={''} />}
        {btnChildren && btnChildren}
      </NavLink>
    )
  } else {
    return (
      <div className={className} {...onClickProps} ref={btnRef}>
        {icoClassName && icoClassName != 'icoFile' && <i className={`an-ico ${icoClassName}`} />}
        {icoClassName && icoClassName == 'icoFile' && <FileAddOutlined style={{ fontSize: '20px', color: '#a4a4a4' }}/>}
        {' '}
        {imgSrc && <img src={`${imgSrc}`} alt={''} />}
        {btnChildren && btnChildren}
      </div>
    )
  }
}
