import React, { useCallback, useState } from 'react'
import { AppDropdown } from '../../common/components/AppDropdown/AppDropdown'
import { AppFilterDropdown } from '../../common/components/AppDropdown/AppFilterDropdown'
import { MultiselectDropdown } from '../../common/components/AppDropdown/MultiselectDropdown'

enum ChargingPointStatusTypes {
  STATUS_DISABLED = 1,
  STATUS_ENABLE = 2,
  STATUS_READY = 3,
}

const arrayOfObjects = [
  { id: 1, value: 'значение-1' },
  { id: 11, value: 'значение-11' },
  { id: 2, value: 'значение-2' },
  { id: 3, value: 'значение-3' },
  { id: 4, value: 'значение-4' },
  { id: 5, value: 'значение-5' },
  { id: 6, value: 'значение-6' },
]

const arrayOfStrings = [
  'значение-1',
  'значение-11',
  'значение-2',
  'значение-3',
  'значение-4',
  'значение-5',
  'значение-6',
]

const demoDataFilter = [
  { name: 'фильтер-1', value: 'filter-1' },
  { name: 'фильтер-2', value: 'filter-2' },
  { name: 'фильтер-3', value: 'filter-3' },
  { name: 'фильтер-4', value: 'filter-4' },
  { name: 'фильтер-5', value: 'filter-5' },
  { name: 'фильтер-6', value: 'filter-6' },
]

const variantByStatus = {
  [ChargingPointStatusTypes.STATUS_DISABLED]: [
    {
      name: 'Enabled',
      id: ChargingPointStatusTypes.STATUS_ENABLE,
    },
  ],
  [ChargingPointStatusTypes.STATUS_READY]: [
    {
      name: 'Disabled',
      id: ChargingPointStatusTypes.STATUS_DISABLED,
    },
  ],
  [ChargingPointStatusTypes.STATUS_ENABLE]: [
    {
      name: 'Disable',
      id: ChargingPointStatusTypes.STATUS_DISABLED,
    },
  ],
}

const ChargingPointStatusData = [
  {
    name: 'Disabled',
    id: ChargingPointStatusTypes.STATUS_DISABLED,
  },
  {
    name: 'Enabled',
    id: ChargingPointStatusTypes.STATUS_ENABLE,
  },
  {
    name: 'Ready',
    id: ChargingPointStatusTypes.STATUS_READY,
  },
]

export const UiDropdownsPage = () => {
  const [arrayOfObjectsValue, setArrayOfObjectsValue] = useState(
    arrayOfObjects[0],
  )

  const [multiselectStateValue, setMultiselectStateValue] = useState<
    {
      id: number
      value: string
    }[]
  >([])
  const [arrayOfStringsValue, setArrayOfStringsValue] = useState(
    arrayOfStrings[0],
  )
  const [chargingPointStatus, setChargingPointStatus] = useState<{
    name: string
    id: ChargingPointStatusTypes
  }>(ChargingPointStatusData[0])
  const [arrayOfObjectsValueWithFilter, setArrayOfObjectsValueWithFilter] =
    useState(arrayOfObjects[0])
  const [arrayOfStringsValueWithFilter, setArrayOfStringsValueWithFilter] =
    useState(arrayOfStrings[0])

  const multiselectChangeHandler = useCallback(
    (value) => setMultiselectStateValue(value),
    [setMultiselectStateValue],
  )

  return (
    <div className="row">
      <div className="col-2">
        <AppDropdown
          data={arrayOfObjects}
          label={'Array of objects'}
          propToShowInList={'value'}
          value={arrayOfObjectsValue}
          onChange={(value) => setArrayOfObjectsValue(value)}
        />
      </div>
      <div className="col-2">
        <AppDropdown
          data={arrayOfStrings}
          label={'Array of strings'}
          value={arrayOfStringsValue}
          onChange={(value) => setArrayOfStringsValue(value)}
        />
      </div>
      <div className="col-2">
        <AppFilterDropdown
          data={demoDataFilter}
          label={'As search params filter'}
          propToShowInList={'name'}
          valuePropName={'value'}
          searchParam={'testFilter'}
        />
      </div>
      <div className="col-2">
        <AppDropdown
          data={arrayOfStrings}
          label={'Array of strings with filter'}
          value={arrayOfStringsValueWithFilter}
          onChange={(value) => setArrayOfStringsValueWithFilter(value)}
          withFilter={true}
        />
      </div>
      <div className="col-2">
        <AppDropdown
          data={arrayOfObjects}
          label={'Array of objects with filter'}
          propToShowInList={'value'}
          value={arrayOfObjectsValueWithFilter}
          onChange={(value) => setArrayOfObjectsValueWithFilter(value)}
          withFilter={true}
        />
      </div>
      <div className="col-2">
        <AppDropdown
          data={variantByStatus[chargingPointStatus.id]}
          propToShowInInput={'name'}
          label={'With custom represent'}
          value={chargingPointStatus}
          customInput={() => {
            return (
              <div className={'dropdown-status-ico'}>
                <div>{chargingPointStatus.name}</div>
              </div>
            )
          }}
          showInListRepresent={(item) => {
            return (
              <div className={'dropdown-status-ico'}>
                <div>{item.name}</div>
              </div>
            )
          }}
          onChange={(value) => setChargingPointStatus(value)}
        />
      </div>
      <div className="col-2">
        <AppDropdown
          data={arrayOfObjects}
          label={'Array of objects'}
          propToShowInList={'value'}
          value={arrayOfObjectsValue}
          onChange={(value) => setArrayOfObjectsValue(value)}
          error={'message'}
        />
      </div>
      <div className="col-2">
        <AppDropdown
          data={arrayOfObjects}
          label={'Disabled dropdown'}
          propToShowInList={'value'}
          value={arrayOfObjectsValue}
          onChange={(value) => setArrayOfObjectsValue(value)}
          disabled={true}
        />
      </div>
      <div className="col-4">
        <MultiselectDropdown
          data={arrayOfObjects}
          label={'Multiselect Dropdown'}
          propToShowInList={'value'}
          valuePropName={'id'}
          value={multiselectStateValue}
          onChange={multiselectChangeHandler}
          disabled={false}
        />
      </div>
    </div>
  )
}
