import { api } from '../../BaseApi'
import { ICompaniesDto, ICompanyDto } from '../../dto/CompaniesDto'

export const companiesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllCompanies: builder.query<ICompaniesDto, void>({
      query: () => ({
        url: `/company/`,
      }),
      providesTags: ['Company'],
    }),
    getCompanyById: builder.query<ICompanyDto, { companyId: string }>({
      query: ({ companyId }) => ({
        url: `/company/${companyId}/`,
      }),
      providesTags: ['Company'],
    }),
  }),
})

export const { useGetAllCompaniesQuery, useGetCompanyByIdQuery } = companiesApi
