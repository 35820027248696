import { api } from '../../BaseApi'
import {
  CreateSourceDTO,
  ISourceGroupDTO,
  ISourcesGroupCreateDTO,
  ISourcesGroupDTO,
  SourceDTO,
  SourcesDTO,
} from '../../dto/SourcesDto'

export const sourcesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllSources: builder.query<
      SourcesDTO,
      { searchParamsString?: string | void | null }
    >({
      query: ({ searchParamsString }) => ({
        url: `/sources/${searchParamsString ? `?${searchParamsString}` : ''}`,
      }),
      providesTags: ['Source'],
    }),
    getSourcesFileTemplate: builder.query<Blob, void>({
      query: () => ({
        url: `/source/download/template/`,
        responseHandler: (response) => response.blob(),
      }),
      providesTags: ['Source'],
    }),
    createSource: builder.mutation<SourceDTO, CreateSourceDTO>({
      query: (body) => ({
        url: `/source/`,
        body,
        method: 'POST',
      }),
      invalidatesTags: ['Source'],
    }),
    delSource: builder.mutation<SourceDTO, { ids: string }>({
      query: (body) => ({
        url: `/source/delete/`,
        body,
        method: 'DELETE',
      }),
      invalidatesTags: ['Source'],
    }),
    delGroupSource: builder.mutation<SourceDTO, { ids: string }>({
      query: (body) => ({
        url: `/source-list/delete/`,
        body,
        method: 'DELETE',
      }),
      invalidatesTags: ['Source'],
    }),
    createSourcesFromList: builder.mutation<string, { data: FormData }>({
      query: ({ data }) => ({
        url: `/source/upload/`,
        body: data,
        method: 'POST',
        responseHandler: (response) => response.text(),
      }),
      transformResponse: (rawResult: string) => {
        return rawResult
      },
      invalidatesTags: ['Source'],
    }),
    getAllSourcesGroups: builder.query<
      ISourcesGroupDTO,
      { searchParamsString?: string | void | null }
    >({
      query: ({ searchParamsString }) => ({
        url: `/source-lists/${
          searchParamsString ? `?${searchParamsString}` : ''
        }`,
      }),
      providesTags: ['Source'],
    }),
    createSourcesGroup: builder.mutation<
      ISourceGroupDTO,
      ISourcesGroupCreateDTO
    >({
      query: (body) => ({
        url: `/source-list/`,
        body,
        method: 'POST',
      }),
      invalidatesTags: ['Source'],
    }),
    getSourceById: builder.query<any, { sourceId: number }>({
      query: ({ sourceId }) => ({
        url: `/source/${sourceId}/`,
      }),
      providesTags: ['Source'],
    }),
  }),
})

export const {
  useGetAllSourcesQuery,
  useLazyGetSourcesFileTemplateQuery,
  useCreateSourceMutation,
  useDelSourceMutation,
  useDelGroupSourceMutation,
  useCreateSourcesFromListMutation,
  useGetAllSourcesGroupsQuery,
  useCreateSourcesGroupMutation,
  useGetSourceByIdQuery,
} = sourcesApi
