import { api } from '../../BaseApi'
import { ITask, ITaskCreateDTO, ITasksDto } from '../../dto/TasksDto'
import { EntityDataType, IEntityItemDto } from '../../dto/EntityDto'

export const tasksApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTasks: builder.query<
      ITasksDto,
      { searchParamsString?: string | void | null }
    >({
      query: ({ searchParamsString }) => ({
        url: `/tasks/${searchParamsString ? `?${searchParamsString}` : ''}`,
      }),
      providesTags: ['Tasks'],
    }),
    getTaskById: builder.query<ITask, { taskId: number }>({
      query: ({ taskId }) => ({
        url: `/task/${taskId}/`
      }),
      providesTags: ['Tasks'],
    }),
    getTaskByIdHistory: builder.query<any, { taskId: number }>({
      query: ({ taskId }) => ({
        url: `/task/${taskId}/history/`,
      }),
      providesTags: ['Tasks'],
    }),
    getTasksCounts: builder.query<{ status: string; count: number }[], void>({
      query: () => ({
        url: `/tasks/names/`,
      }),
      providesTags: ['Entity'],
    }),
    createTask: builder.mutation<ITask, ITaskCreateDTO>({
      query: (data) => ({
        url: `/task/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Tasks'],
    }),
    updateTask: builder.mutation<
      ITask,
      {
        body: ITaskCreateDTO
        taskId: string
      }
    >({
      query: ({ taskId, body }) => ({
        url: `/task/${taskId}/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Tasks'],
    }),
    taskUploadMedia: builder.mutation<
      ITask,
      { data: FormData; taskId: number }
    >({
      query: ({ data, taskId }) => ({
        url: `/task/${taskId}/upload/images/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Tasks'],
    }),
    taskUploadDocuments: builder.mutation<
      ITask,
      { data: FormData; taskId: number }
    >({
      query: ({ data, taskId }) => ({
        url: `/task/${taskId}/upload/docs/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Tasks'],
    }),
    delTask: builder.mutation<
      any,
      {
        taskId: string
      }
    >({
      query: ({ taskId }) => ({
        url: `/task/${taskId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Tasks'],
    }),
  }),
})

export const {
  useGetTasksQuery,
  useGetTaskByIdQuery,
  useGetTaskByIdHistoryQuery,
  useGetTasksCountsQuery,
  useCreateTaskMutation,
  useUpdateTaskMutation,
  useTaskUploadMediaMutation,
  useTaskUploadDocumentsMutation,
  useDelTaskMutation,
} = tasksApi
