import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
} from '@react-pdf/renderer'
import { format, subDays } from 'date-fns'
import { ru } from 'date-fns/locale'
import { Fragment } from 'react'
import { callbackify } from 'util'

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5vAx05IsDqlA.ttf',
    },
    {
      src: 'https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9vAx05IsDqlA.ttf',
      fontWeight: 500,
    },
  ],
})

export const PDF = ({ data, title }: any) => {
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: 'white',
    },
    section: {
      margin: 20,
      padding: 10,
      flexGrow: 1,
      fontFamily: 'Roboto',
      fontSize: '8',
    },
    title: {
      fontWeight: 500,
      marginBottom: 20,
    },
    item: {
      //marginTop: 10,
      width: '87.5vw',
      border: '1px solid black',
      height: 20,
      fontWeight: 500,
      padding: 5,
      wordBreak: 'break-word',
      flexDirection: 'column',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      backgroundColor: '#eff876',
      alignItems: 'center',
    },
    tableContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      wordBreak: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    row: {
      flexDirection: 'row',
      //alignItems: 'center',
    },
    header: {
      width: '12.5vw',
      border: '1px solid black',
      height: 30,
      fontWeight: 500,
      padding: 5,
      wordBreak: 'break-word',
      flexDirection: 'column',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    name: {
      width: '100vw',
      height: 20,
      fontWeight: 500,
      padding: 5,
    },
    description: {
      width: '12.5vw',
      border: '1px solid black',
      height: 120,
      padding: 5,
      wordBreak: 'break-word',
      flexDirection: 'column',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    district: {
      width: '12.5vw',
      border: '1px solid black',
      height: 120,
      padding: 5,
      wordBreak: 'break-word',
      flexDirection: 'column',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      backgroundColor: '#e0aeff',
    },
    kommit: {
      width: '12.5vw',
      border: '1px solid black',
      height: 120,
      padding: 5,
      wordBreak: 'break-word',
      flexDirection: 'column',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      backgroundColor: '#fbea89',
    },
    guber: {
      width: '12.5vw',
      border: '1px solid black',
      height: 120,
      padding: 5,
      wordBreak: 'break-word',
      flexDirection: 'column',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      backgroundColor: '#c5ddfe',
    },
    vis_guber: {
      width: '12.5vw',
      border: '1px solid black',
      height: 120,
      padding: 5,
      wordBreak: 'break-word',
      flexDirection: 'column',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      backgroundColor: '#aeffb9',
    },
    level: {
      flexDirection: 'column',
      border: '1px solid black',
    },
  })

  let date = new Date(),
    targetDay = 1,
    mo = new Date(),
    tu = new Date(),
    we = new Date(),
    th = new Date(),
    fr = new Date(),
    sa = new Date(),
    su = new Date(),
    delta = targetDay - date.getDay()
  if (title == 'за текущую неделю') {
    mo.setDate(date.getDate() + delta)
    tu.setDate(date.getDate() + delta + 1)
    we.setDate(date.getDate() + delta + 2)
    th.setDate(date.getDate() + delta + 3)
    fr.setDate(date.getDate() + delta + 4)
    sa.setDate(date.getDate() + delta + 5)
    su.setDate(date.getDate() + delta + 6)
  }
  if (title == 'за прошлую неделю') {
    mo.setDate(date.getDate() - 7 + delta)
    tu.setDate(date.getDate() - 6 + delta)
    we.setDate(date.getDate() - 5 + delta)
    th.setDate(date.getDate() - 4 + delta)
    fr.setDate(date.getDate() - 3 + delta)
    sa.setDate(date.getDate() - 2 + delta)
    su.setDate(date.getDate() - 1 + delta)
  }

  const week = [mo, tu, we, th, fr, sa, su]

  const levels = [
    {
      id: 2,
      title: 'Вице-губернатор',
    },
    {
      id: 3,
      title: 'Губернатор',
    },
    {
      id: 4,
      title: 'Комитет',
    },
    {
      id: 5,
      title: 'Район',
    },
  ]

  const findLongest = (arr: any) => {
    var longest = arr?.sort(function (a: any, b: any) {
      return b?.length - a?.length
    })
    return longest[0]?.length
  }

  const dataLevel = (num: number) => {
    let dataFilter = data?.items
    let filter = dataFilter?.filter(function (it: any) {
      return it?.recommendation_planning_level == num
    })
    return filter
  }

  const calcCells = (num: number) => {
    const cells = []
    for (let i = 0; i < 7; i++) {
      cells[i] = [
        dataLevel(num)?.filter(function (it: any) {
          return (
            it?.event_key_date?.split('T')[0] ==
            format(subDays(new Date(week[i]), 0), 'yyyy-MM-dd', { locale: ru })
          )
        }),
      ]
    }
    return cells
  }

  const calcLongest = (num: number) => {
    let copy = []
    for (let i = 0; i < 7; i++) {
      copy[i] = calcCells(num)[i][0]
    }
    let maxI = findLongest(copy)
    return maxI
  }

  const calc = (num: number) => {
    let dataArr: any[] = []
    for (let i = 0; i < calcLongest(num); i++) {
      for (let j = 0; j < 8; j++) {
        calcCells(num)[j]?.map((item: any, id) =>
          item?.length > 0 && item[i]
            ? (dataArr[i * 7 + j + 1] = item[i])
            : (dataArr[i * 7 + j + 1] = { title: '' }),
        )
      }
    }
    return dataArr
  }

  const TableHeader = ({ items }: any) => {
    const rows = items?.map((item: any, index: number) => (
      <View style={styles.tableContainer} key={index}>
        <Text style={styles.header}>
          {format(subDays(new Date(item), 0), 'eeee            dd.MM.yy', {
            locale: ru,
          })}
        </Text>
      </View>
    ))
    return <Fragment>{rows}</Fragment>
  }

  const TableName = ({ name }: any) => {
    return (
      <Fragment>
        <View style={styles.tableContainer}>
          <Text style={styles.name}>{name}</Text>
        </View>
      </Fragment>
    )
  }

  const TableRow = ({ items, name }: any) => {
    const rows = items?.map((item: any, index: number) => (
      <View style={styles.tableContainer} key={index}>
        <Text
          style={
            item?.title && name == 'Уровень района'
              ? styles.district
              : item?.title && name == 'Уровень комитета'
              ? styles.kommit
              : item?.title && name == 'Уровень губернатора'
              ? styles.guber
              : item?.title && name == 'Уровень вице-губернатора'
              ? styles.vis_guber
              : styles.description
          }
        >
          {item?.title}
        </Text>
      </View>
    ))

    return <Fragment>{rows}</Fragment>
  }

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.title}>
            План основных мероприятий с{' '}
            {format(subDays(new Date(week[0]), 0), 'dd.MM.yy', {
              locale: ru,
            })} по{' '}
            {format(subDays(new Date(week[6]), 0), 'dd.MM.yy', {
              locale: ru,
            })}
          </Text>
          <View style={styles.tableContainer}>
            <TableHeader items={week} />
            {data?.dates?.map((item: any) => {
            return (
              <View>
              <Text style={styles.item}>
                Темы недели: { format(
                  subDays(new Date(item?.event_key_date.split('T')[0]), 0),
                  'dd MMMM',
                  { locale: ru },
                )}{' - '}{ item?.title}
              </Text>
              </View>
            )
          })}
            {calc(2).length > 0 && <TableName name={'Уровень вице-губернатора'} />}
            <TableRow items={calc(2)} name={'Уровень вице-губернатора'} />
            {calc(3).length > 0 && <TableName name={'Уровень губернатора'} />}
            <TableRow items={calc(3)} name={'Уровень губернатора'} />
            {calc(4).length > 0 && <TableName name={'Уровень комитета'} />}
            <TableRow items={calc(4)} name={'Уровень комитета'} />
            {calc(5).length > 0 && <TableName name={'Уровень района'} />}
            <TableRow items={calc(5)} name={'Уровень района'} />
          </View>
        </View>
      </Page>
    </Document>
  )
}
