import React, { useCallback, useMemo, useState } from 'react'
import { Breadcrumbs } from '../../../common/components/Breadcrumbs/Breadcrumbs'
import { AppTranslation } from '../../../common/components/AppTranslation/AppTranslation'
import { AllRoutes } from '../../../core/routes/AllRoutes'
import {
  IFormRowData,
  inputControllerType,
  IRowItem,
  PageFormController,
} from '../../../common/components/PageFormController/PageFormController'
import { IFormErrorDto } from '../../../core/api/BaseApi'
import { useGoBack } from '../../../common/components/HeaderBackButton/HeaderBackButton'
import {
  AppButton,
  AppButtonColor,
  AppButtonSize,
} from '../../../common/components/AppButton/AppButton'
import { ITaskCreateDTO, ITaskPriority } from '../../../core/api/dto/TasksDto'
import { IExecutorsState } from '../../../common/components/FormExecutors/FormExecutors'
import {
  useCreateTaskMutation,
  useGetTaskByIdQuery,
  useTaskUploadDocumentsMutation,
  useTaskUploadMediaMutation,
  useUpdateTaskMutation,
} from '../../../core/api/BaseApiEndpoints/Tasks/TasksApi'
import {
  AppNotification,
  NotificationType,
} from '../../../common/components/Notification/Notification'
import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router'
import { formStateItemType } from '../../../common/components/useFormHook'
import { IBaseProfileDto } from '../../../core/api/dto/ProfilesDto'
import {
  useGetEntityByIdQuery,
  useUpdateEntityMutation,
} from 'core/api/BaseApiEndpoints/Entity/EntityApi'
import { useGetProjectByIdQuery, useUpdateProjectMutation } from 'core/api/BaseApiEndpoints/Projects/ProjectsApi'

export const TaskCreateUpdate = () => {
  const { taskId } = useParams()
  const { entitySymbolKey, entityId, relatedEntitySymbolKey, projectId } = useParams()
  const isCreatePage = useMemo(
    () => !taskId || !!relatedEntitySymbolKey,
    [taskId, relatedEntitySymbolKey],
  )
  const navigate = useNavigate()
  const [createTask] = useCreateTaskMutation()
  const [uploadMedia] = useTaskUploadMediaMutation()
  const [updateTask] = useUpdateTaskMutation()
  const [uploadDocuments] = useTaskUploadDocumentsMutation()
  const { data: detailsData } = useGetTaskByIdQuery(
    { taskId: Number(taskId) },
    {
      skip: relatedEntitySymbolKey ? false : isCreatePage,
    },
  )

  const { data: entityData } = useGetEntityByIdQuery(
    { entitySymbolKey: entitySymbolKey!, entityId: entityId! },
    {
      skip: relatedEntitySymbolKey ? false : isCreatePage,
    },
  )

  const { data: projectData } = useGetProjectByIdQuery(
    { projectId: Number(projectId) },
    {
      skip: !!Number(projectId) ? false : true,
    },
  )

  const [willSubmit, setWillSubmit] = useState(false)
  const [isValidForm, setIsValidForm] = useState(!isCreatePage)
  const [formError, setFormError] = useState<IFormErrorDto | null>(null)
  const [isFormDirty, setIsFormDirt] = useState(false)
  const [updateEntity] = useUpdateEntityMutation()
  const [updateProject] = useUpdateProjectMutation()

  const { goBackHandler } = useGoBack()
  const backBtnHandler = useCallback(() => {
    if (!isFormDirty) {
      goBackHandler()
    } else alert('Есть несохраненные данные')
  }, [isFormDirty, goBackHandler])

  const baseFieldItems: IRowItem[] = [
    {
      type: inputControllerType.input,
      name: 'name',
      label: 'task_create_page__form_name',
      colClassName: 'col-12',
      required: 'general__txt_required_field',
    },
    {
      type: inputControllerType.taskPrioritySelect,
      name: 'priority',
      label: 'task_create_page__form_priority',
      colClassName: 'col-6',
      required: 'general__txt_required_field',
    },
    {
      type: inputControllerType.datePicker,
      name: 'date_end',
      label: 'general_date_end__txt_default_label',
      colClassName: 'col-6',
      required: 'general__txt_required_field',
    },
    {
      type: inputControllerType.executor,
      name: 'executor',
      label: 'task_create_page__form_executor',
      colClassName: 'col-12',
      required: 'general__txt_required_field',
    },
    {
      type: inputControllerType.textarea,
      name: 'description',
      rows: 8,
      label: 'task_create_page__form_description',
      colClassName: 'col-12',
      required: 'general__txt_required_field',
    },
  ]

  const baseMediaItems: IRowItem[] = []

  const overFieldItems: IRowItem[] = []

  if (isCreatePage) {
    overFieldItems.push(
      {
        type: inputControllerType.entity,
        name: 'entity',
        label: 'task_create_page__form_entity',
        colClassName: 'col-6',
      },
      {
        type: inputControllerType.co_executor,
        name: 'executor',
        label: 'task_create_page__form_executor',
        colClassName: 'col-3',
        required: 'general__txt_required_field',
      },
      {
        type: inputControllerType.file,
        name: 'task_document',
        label: 'Документы',
        colClassName: 'col-3',
      },
      {
        type: inputControllerType.file,
        name: 'task_media',
        label: 'Медиа',
        colClassName: 'col-3',
      },

      {
        type: inputControllerType.baseFields,
        colClassName: 'col-12',
        rowItems: baseMediaItems,
      },
    )
  } else
    overFieldItems.push(
      {
        type: inputControllerType.co_executor,
        name: 'executor',
        label: 'task_create_page__form_executor',
        colClassName: 'col-3',
        required: 'general__txt_required_field',
      },

      {
        type: inputControllerType.baseFields,
        colClassName: 'col-12',
        rowItems: baseMediaItems,
      },
    )

  const taskFormConfig = useMemo<IFormRowData[] | null>(() => {
    return [
      {
        title: null,
        rowItems: [
          {
            type: inputControllerType.baseFields,
            colClassName: 'col-12',
            rowItems: baseFieldItems,
          },
        ],
      },
      {
        title: null,
        canBeHide: true,
        rowItems: overFieldItems,
      },
    ]
  }, [])

  const companiesBreadcrumbs = useMemo(() => {
    return isCreatePage
      ? [
          { name: 'tasks_page__txt_title', path: '../' },
          {
            name: {
              label: 'task_create_page__txt_title',
            },
          },
        ]
      : [
          { name: 'tasks_page__txt_title', path: '../../' },
          {
            name: {
              label: 'tasks_detail_page__txt_nav_button_title',
              options: { taskName: `${detailsData ? detailsData.name : ''}` },
            },
            path: '../',
          },
          {
            name: isCreatePage
              ? 'task_create_page__txt_title'
              : 'task_edit_page__txt_title',
          },
        ]
  }, [isCreatePage, taskId])

  const formDataChangeHandler = useCallback(
    (
      {
        executor,
        task_document,
        task_media,
        entity,
        ...data
      }: {
        name: string
        priority: ITaskPriority
        date_end: Date
        description: string
        executor: IExecutorsState
        task_document: FileList
        task_media: FileList
        entity: any
      },
      isValid: boolean,
    ) => {
      if (isValid) {
        const fetchArray: string[] = []
        if (task_document && task_document.length > 0) {
          fetchArray.push('isDocumentsDirty')
        }
        if (task_media && task_media.length > 0) {
          fetchArray.push('isMediaDirty')
        }

        const processArray = async (array: string[], taskId: number) => {
          const result = []
          for (const item of array) {
            switch (item) {
              case 'isDocumentsDirty':
                const formDocData = new FormData()
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < task_document.length; i++) {
                  formDocData.append(
                    'file',
                    task_document[i],
                    task_document[i].name,
                  )
                }
                result.push(
                  await uploadDocuments({
                    taskId,
                    data: formDocData,
                  }),
                )
                break
              case 'isMediaDirty':
                const formMediaData = new FormData()
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < task_media.length; i++) {
                  formMediaData.append(
                    'file',
                    task_media[i],
                    task_media[i].name,
                  )
                }
                result.push(
                  await uploadMedia({
                    taskId,
                    data: formMediaData,
                  }),
                )
                break

              default:
                break
            }
          }
          return result
        }

        const newData: ITaskCreateDTO = {
          ...data,
          executor: executor.executor.map((item) =>
            typeof item === 'number' ? item : item.id,
          ),
          co_executor: executor.co_executor.map((item) =>
            typeof item === 'number' ? item : item.id,
          ),
          date_start: detailsData?.date_start || new Date(),
        }

        isCreatePage
          ? createTask(newData)
              .unwrap()
              .then((item) => {
                if (fetchArray.length > 0) {
                  processArray(fetchArray, item.id)
                    .then((result) => {
                      const isAnyError =
                        result.findIndex(
                          (item) => item && 'error' in item && item.error,
                        ) >= 0

                      if (!isAnyError) {
                        setIsFormDirt(false)
                        AppNotification({
                          msg: [t('general__msg_saved')],
                          type: NotificationType.success,
                        })
                        navigate(`../${item.id}`)
                      }
                    })
                    .catch((error) => setFormError(error))
                } else {
                  AppNotification({
                    msg: [t('general__msg_saved')],
                    type: NotificationType.success,
                  })
                  navigate(`../${item.id}`)
                }
                // @ts-ignore
                let entityTasks = entityData?.tasks ? entityData?.tasks?.map((task:any)=> task) : []
                entityTasks[entityTasks.length] = item.id
                if (relatedEntitySymbolKey && entitySymbolKey && entityId)
                updateEntity({
                  entitySymbolKey,
                  body: { tasks: entityTasks },
                  entityId: entityId,
                })
                let projectTasks = projectData?.tasks?.map((task:any)=> task.id)
                projectTasks[projectTasks.length] = item.id
                if (relatedEntitySymbolKey && projectId)
                updateProject({
                  body: { tasks: projectTasks},
                  projectId: projectId,
                })
                if ('nameSchema' in entity.entity[0])
                  updateEntity({
                    entitySymbolKey: entity.entity[0].nameSchema,
                    body: { tasks: [`${item.id}`] },
                    entityId: entity.entity[0].pk,
                  })
                else
                  updateProject({
                    body: { tasks: [`${item.id}`] },
                    projectId: entity.entity[0].id,
                  })
              })
              .catch((error) => setFormError(error))
          : updateTask({ body: newData, taskId: taskId! })
              .unwrap()
              .then((item) => {
                if (fetchArray.length > 0) {
                  processArray(fetchArray, item.id)
                    .then((result) => {
                      const isAnyError =
                        result.findIndex(
                          (item) => item && 'error' in item && item.error,
                        ) >= 0

                      if (!isAnyError) {
                        setIsFormDirt(false)
                        AppNotification({
                          msg: [t('general__msg_saved')],
                          type: NotificationType.success,
                        })
                        navigate(`/tasks/${item.id}`)
                      }
                    })
                    .catch((error) => setFormError(error))
                } else {
                  AppNotification({
                    msg: [t('general__msg_saved')],
                    type: NotificationType.success,
                  })
                  navigate(`/tasks/${item.id}`)
                }
              })
              .catch((error) => setFormError(error))
      }
      setWillSubmit(false)
    },
    [createTask, updateTask, navigate, projectData, entityData],
  )

  let fields: any = !isCreatePage
    ? ['name', 'description', 'priority', 'date_end', 'executor']
    : []
  const isValid = useCallback((isValid: any) => {
    if (fields.length == 0) fields.push(Object.keys(isValid).join())
    else {
      let currentField = fields.findIndex(
        (field: any) => Object.keys(isValid).join() == field,
      )
      if (currentField == -1) fields.push(Object.keys(isValid).join())
      else if (Object.values(isValid).join() == '') {
        fields.splice(currentField, 1)
      }
    }
    setIsValidForm(
      (fields.includes('name') &&
        fields.includes('description') &&
        fields.includes('priority') &&
        fields.includes('date_end') &&
        fields.includes('executor')) || relatedEntitySymbolKey
    )
  }, [])

  const dirtyFormCallBack = useCallback(
    (value: boolean) => {
      setIsFormDirt(value)
    },
    [setIsFormDirt],
  )

  const initFormState = useMemo(() => {
    if (!isCreatePage) {
      if (!detailsData) {
        return null
      }
      return {
        name: detailsData.name,
        priority: detailsData.priority,
        date_end: detailsData.date_end,
        description: detailsData.description,
        executor: {
          executor: detailsData.executor,
          co_executor: detailsData.co_executor,
        },
      }
    } else if (isCreatePage && relatedEntitySymbolKey && entityId) {
      if (!entityData) {
        return null
      }
      return {
        name: entityData?.title,
        priority: 'LOW',
        date_end: entityData?.event_end_date,
        description: entityData?.description,
        executor: {
          executor: [],
          co_executor: [],
        },
      }
    } else if (isCreatePage && relatedEntitySymbolKey && projectId) {
      if (!projectData) {
        return null
      }
      return {
        name: projectData?.title,
        priority: 'LOW',
        date_end: projectData?.end_date,
        description: projectData?.description,
        executor: {
          executor: [],
          co_executor: [],
        },
      }
    } else
      return {
        name: '',
        priority: '',
        date_end: '',
        description: '',
        executor: '',
      }
  }, [isCreatePage, detailsData, entityData, projectData])

  return (
    <React.Fragment>
      <div className={'back-office-header'}>
        {/*<Breadcrumbs breadcrumbs={companiesBreadcrumbs} />*/}
        <h1>
          {/*<AppTranslation
            label={
              isCreatePage
                ? AllRoutes.tasksCreate.name
                : detailsData
                ? detailsData?.name.toString()
                : AllRoutes.tasksEdit.name
            }
          />*/}
          <AppTranslation
            label={
              relatedEntitySymbolKey
                ? AllRoutes.entityElementCreateRelated.name
                : isCreatePage
                ? AllRoutes.tasksCreate.name
                : detailsData
                ? detailsData?.name.toString()
                : AllRoutes.tasksEdit.name
            }
            options={
              relatedEntitySymbolKey
                ? { relatedEntitySymbolKey: 'задачу', entityName: projectId ? 'Проект' : entitySymbolKey }
                : {}
            }
          />
        </h1>
        <p className="base-field-desc">
          * - звездочкой отмечены обязательные для заполнения поля
        </p>
      </div>
      <PageFormController
        formData={taskFormConfig ? taskFormConfig : []}
        willSubmit={willSubmit}
        formDataChangeHandler={formDataChangeHandler}
        fetchError={formError}
        dirtyFormCallBack={dirtyFormCallBack}
        initFormState={initFormState}
        isValid={isValid}
      />
      <div className={'col-4 btn-block'} style={{ marginTop: '30px' }}>
        <AppButton
          onClick={() => setWillSubmit(true)}
          color={AppButtonColor.aquaBlue}
          size={AppButtonSize.base}
          title={'general__button_save'}
          disabled={!isValidForm}
        />
        <div style={{ width: '20px' }} />
        <AppButton
          onClick={backBtnHandler}
          color={AppButtonColor.lightRed}
          size={AppButtonSize.base}
          title={'general__button_cancel'}
        />
      </div>
    </React.Fragment>
  )
}
