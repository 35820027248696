import React, { useMemo } from 'react'
import { TagsListingBlock } from '../TagsListingBlock/TagsListingBlock'
import { EntityForeignKeyFormViewExtractor } from '../EntityForeignKeyFormViewExtractor/EntityForeignKeyFormViewExtractor'

interface ITargetAudienceWidget {
  targetAudienceData: number[] | undefined
  title?: string
  type?: string
}

export const SphereWidget = ({
  targetAudienceData, title, type
}: ITargetAudienceWidget) => {
  const extractedData = useMemo(() => {
    return targetAudienceData
      ? targetAudienceData.map((item) => (
          <EntityForeignKeyFormViewExtractor
            dataType={type ? type : 'planned_material_type'}
            value={item}
          />
        ))
      : []
  }, [targetAudienceData])

  return (
    <div className="section-widget">
      <h2>{title ? title : 'Сфера'}</h2>
      <div className="widget-content">
        <div className="content-children">
          {<TagsListingBlock tagsData={extractedData} />}
        </div>
      </div>
    </div>
  )
}
