import React, { useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { translationLabelTape } from '../AppTranslation/AppTranslation'
import { useTranslation } from 'react-i18next'
import { AppInput } from '../AppInput/AppInput'

interface IHeaderSearchInput {
  searchParam: string
  placeholder?: translationLabelTape
}

export const HeaderSearchInput = ({
  searchParam,
  placeholder = 'general__txt_search',
}: IHeaderSearchInput) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { t } = useTranslation()
  const currentPlaceholder = useMemo(() => {
    return t(placeholder)
  }, [placeholder, t])

  const onChangeHandler = useCallback(
    (item) => {
      const isParameterEntry = !!searchParams.get(searchParam)
      if (!isParameterEntry && !!item) {
        searchParams.append(searchParam, item)
      } else if (!!item) {
        searchParams.set(searchParam, item)
      } else if (!item) {
        searchParams.delete(searchParam)
        setSearchParams(searchParams.toString())
      }
      setSearchParams(searchParams.toString())
    },
    [searchParams, setSearchParams, searchParam],
  )
  const value = searchParams.get(searchParam)

  return (
    <div className={'header-search-wrapper'} >
      <AppInput
        value={value ? value : ''}
        onChange={onChangeHandler}
        placeholder={currentPlaceholder}
        inputIcoClassName={'an-ico-search color-Light-blue'}
        fullWidth={true}
      />
    </div>
  )
}
