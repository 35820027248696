import { EyeOutlined, LogoutOutlined, PrinterOutlined } from '@ant-design/icons'
import { useGetOrganizationStructureQuery } from 'core/api/BaseApiEndpoints/Members/MembersApi'
import React from 'react'
import { Link } from 'react-router-dom'

const controlStyles = { fontSize: 10 }

type Props = {
  data: any
  position: number
}

export default function UserInfoTab({ data, position }: Props) {
  //const { data } = useGetOrganizationStructureQuery()
  console.log('data', data)
  return (
    <>
      <defs>
        <rect
          id={position + 'rect'}
          width="40px"
          height="40px"
          x={position <= 2 ? -160 : 10}
          y={position <= 2 ? (25 + position * 47) : (25 + (position-2) * 47)}
          rx="50"
          stroke="none"
        />
        <clipPath id={'clip' + position}>
          <use xlinkHref={'#' + position + 'rect'} />
        </clipPath>
      </defs>
      <use
        xlinkHref={'#' + position + 'rect'}
        strokeWidth=".5"
        fill="#c6c6c6"
        y={'0px'}
        stroke="none"
      />

      <image
        //xlinkHref={data?.url[`Avatar${data?.id - 1}`]}
        xlinkHref={typeof data?.photo == 'string' ? data?.photo : ''}
        x={position <= 2 ? -160 : 10}
        y={position <= 2 ? (25 + position * 47) : (25 + (position-2) * 47)}
        width="40px"
        height="40px"
        clipPath={'url(#clip' + position + ')'}
      />

      <foreignObject
        //onClick={addSourceBtnCLickHandler}
        className="user-name"
        y={position <= 2 ? (25 + position * 47) : (25 + (position-2) * 47)}
        x={position <= 2 ? -110 : 60}
        width={data.title ? 250 : 130}
        height="35"
        fontSize={15}
        fontFamily="Arial"
        style={{ pointerEvents: 'none' }}
        color={'black'}
      >
        {data?.last_name ? ' ' + data?.last_name?.toString().substring(0, 30) : data?.name?.toString().substring(0, 30)}
        {data?.first_name && ' ' + data?.first_name?.toString().substring(0, 30) }
        {data?.patronymic && ' ' + data?.patronymic?.toString().substring(0, 30) }
      </foreignObject>
      { (
        <foreignObject
          y={position <= 2 ? (57 + position * 47) : (40 + (position-2) * 47)}
          x={position <= 2 ? -110 : 60}
          width={data.title ? 250 : 130}
          height="50"
          fontSize={10}
          fontFamily="Arial"
          style={{ pointerEvents: 'none' }}
          color={'grey'}
        >
          {data?.subs?.toLocaleString()}
          {data?.post?.toLocaleString()}
        </foreignObject>
      )}
    </>
  )
}
