import React from 'react'
import { ITask } from '../../../core/api/dto/TasksDto'
import { TableExecutors } from './TableExecutors'
import { PriorityWidget } from '../PriorityWidget/PriorityWidget'
import { TaskCountDown } from './TaskCountDown'
import { TaskStatusBar } from './TaskStatusBar'
import { colorByStatus } from './TaskStatus'
import { AppTranslation } from '../AppTranslation/AppTranslation'

interface ITaskDetailWidget {
  task: ITask
}

export const TaskDetailWidget = ({ task }: ITaskDetailWidget) => {
  return (
    <div className="task-detail-widget">
      <div className={`status-color-label ${colorByStatus[task.status]}`}></div>
      <div className="task-detail-widget-content">
        <div className="content-row">
          <div className="task-name" data-title={task.name}>
            <h2>{task.name}</h2>
          </div>
        </div>
        <div className="content-row">
          {/*<TaskStatusBar
            date_start={task.date_start}
            date_end={task.date_end}
            showLabel={true}
          />*/}
          <div>
            <PriorityWidget priority={task.priority} />
          </div>
          <div className="priority-widget">
            <span className="title">
              <span className="translation-wrapper">Статус:</span>
            </span>
            <span className="value color-Task-label-background-yellow">
              <span className="translation-wrapper">
                <AppTranslation
                  label={`task_status_${task.status.toUpperCase()}`}
                />
              </span>
            </span>
          </div>
        </div>
        {task.status.toUpperCase() != 'DONE' && (
          <div className="content-row">
            <TaskCountDown date_end={task.date_end} />
          </div>
        )}
      </div>
    </div>
  )
}
