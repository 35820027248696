import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { AppTranslation } from '../../../common/components/AppTranslation/AppTranslation'
import { AllRoutes } from '../../../core/routes/AllRoutes'
import AppDatePicker from 'common/components/AppDatePicker/AppDatePicker'
import {
  useGetEntitiesSchemasQuery,
  useGetEntityByIdQuery,
  useGetEntityCalendarFullQuery,
  useGetEntityCalendarQuery,
  useGetEntityItemsQuery,
  useGetEntityTypesQuery,
} from 'core/api/BaseApiEndpoints/Entity/EntityApi'
import { TaskStatusBar } from 'common/components/Tasks/TaskStatusBar'
import { TaskCountDown } from 'common/components/Tasks/TaskCountDown'
import { Link, useParams } from 'react-router-dom'
import { AppFilterDropdown } from 'common/components/AppDropdown/AppFilterDropdown'
import { IEntitiesObject, IEntityItemDto } from 'core/api/dto/EntityDto'
import {
  useGetProjectByIdQuery,
  useGetProjectItemsQuery,
} from 'core/api/BaseApiEndpoints/Projects/ProjectsApi'
import { ConfigProvider, DatePicker } from 'antd'
import ruRU from 'antd/es/locale/ru_RU'
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns'
import generatePicker from 'antd/es/date-picker/generatePicker'
import { endOfDay } from 'date-fns'

type Event = {
  id: number
  title: string
  statusDone: number
  date_start: string
  date_end: string
  performer: string
}

const EventCalendarPage = () => {
  const now = new Date()
  now.setDate(now.getDate() - 6)
  const [dateStart, setDateStart] = useState<any>(now)
  const [dateEnd, setDateEnd] = useState<any>(new Date())
  const [dates, setDates] = useState<any>()
  const [dateDiff, setDateDiff] = useState<number>(7)
  const [entityId, setEntityId] = useState('1')
  const [entityCurrentId, setEntityCurrentId] = useState('')
  const [entitySymbolKeys, setEntitySymbolKeys] = useState<string>('')
  const [checkboxName, setCheckboxName] = useState<string>('')
  const widthRef = useRef<any>(null)

  if (ruRU.DatePicker) ruRU.DatePicker.lang.locale = 'ru'
  const { RangePicker } = generatePicker<Date>(dateFnsGenerateConfig)

  const newDates = (dates: any) => {
    setDateStart(dates[0])
    setDateEnd(dates[1])
    setDates(dates)
  }

  const disabledDate = (current: any) => {
    // Can not select days before today
    const date = new Date()
    return current && current < endOfDay(date.setDate(date.getDate() - 1))
  }

  const { data: entityNames } = useGetEntityTypesQuery()
  let entityNamesAll = entityNames ? [...entityNames] : []
  entityNamesAll[4] = { name: 'Проект', items_count: 99 }

  const { data: entityItems } = useGetProjectItemsQuery({
    searchParamsString: '',
  })
  const { data: detailsData } = useGetProjectByIdQuery({
    projectId: Number(entityId),
  })

  const detailData = useMemo<{
    reservedFields:
      | {
          can_be_used_with: string[]
          title: string
          id: number
          entities: any
        }
      | {}
    dynamicFields: IEntityItemDto
  }>(() => {
    let result = {
      reservedFields: {},
      dynamicFields: {},
    }

    if (detailsData) {
      const { title, id, can_be_used_with, entities, ...dynamicFields } =
        detailsData
      result = {
        reservedFields: {
          title: title as string,
          can_be_used_with: can_be_used_with as unknown as string[],
          entities: entities as any,
          id: id as number,
        },
        dynamicFields: { dynamicFields },
      }
    }
    return result
  }, [detailsData])

  const { data: fullData } = useGetEntityCalendarFullQuery({
    entitySymbolKey: `items=${entitySymbolKeys}&event_start_date=${dateStart.toISOString()}&event_end_date=${dateEnd.toISOString()}`,
  })
  {
    /*const { data } = useGetEntityCalendarQuery({
    entitySymbolKey: entityNames
      ? entityNames[checkboxId].name
      : 'Медиакампания',
    searchParamsString: `start_date=${dateStart.toISOString()}&end_date=${dateEnd.toISOString()}`,
  })*/
  }

  const сhangeValue = useCallback((project) => {
    if (typeof project[0]['name'] !== 'undefined') {
      setCheckboxName(project[0].name)
      setEntitySymbolKeys(project[0].name)
    } else if (typeof project[0]['id'] !== 'undefined') {
      setEntityId(project[0].id)
      setEntitySymbolKeys('')
      setEntityCurrentId(project[0].entities.join(' , '))
      console.log('hhh', project[0].entities.join(' , '))
    }
  }, [])

  const getDate = (date: number | string) => {
    const newDate = new Date(date)
    return newDate
  }

  const resetValueHandler = () => {
    setEntitySymbolKeys('')
  }

  useEffect(() => {
    resetValueHandler()
  }, [])

  useEffect(() => {
    if (
      entityId != '' &&
      'entities' in detailData.reservedFields &&
      detailData.reservedFields?.entities?.length > 0
    ) {
      setEntitySymbolKeys(
        Object.keys(detailData.reservedFields?.entities)?.join(','),
      )
    }
  }, [entityId])

  useEffect(() => {
    const diff =
      Math.ceil(Math.abs(dateEnd - dateStart) / (1000 * 3600 * 24)) + 1
    setDateDiff(diff)
  }, [dateEnd, dateStart, checkboxName])

  return (
    <>
      <div className="back-office-header calendar-header">
        <h1>
          <AppTranslation label={AllRoutes.eventCalendar.name} />
        </h1>

        <div className={'header-actions-block'}>
          <div className="calendar-event-checkboxes">
            {/*entityNames?.map((entity, index) => {
              return (
                <AppCheckbox
                  key={index}
                  value={index == checkboxId}
                  uniqId={entity.name}
                  label={entity.name}
                  onChange={() => setCheckboxId(index)}
                ></AppCheckbox>
              )
            })*/}
          </div>
          {checkboxName == 'Проект' && (
            <AppFilterDropdown
              searchParam={'project'}
              propToShowInList={'title'}
              valuePropName={'title'}
              data={entityItems?.items ? entityItems?.items : []}
              placeHolder={'Выберите проект'}
              withFilter={true}
              onChange={сhangeValue}
              resetValueHandler={resetValueHandler}
            />
          )}
          <AppFilterDropdown
            searchParam={'entity'}
            propToShowInList={'name'}
            valuePropName={'name'}
            data={entityNames ? entityNamesAll : []}
            placeHolder={'Выберите сущность'}
            withFilter={true}
            onChange={сhangeValue}
            resetValueHandler={resetValueHandler}
          />
        </div>
      </div>
      <div className="app-table-wrapper">
        <div className="calendar-event-main">
          <div className="calendar-event-main-pickers">
            <ConfigProvider locale={ruRU}>
              <RangePicker
                onChange={newDates}
                //disabledDate={disabledDate}
                //open={true}
                value={dates}
              />
            </ConfigProvider>
          </div>
          {/*<div className="calendar-event-main-pickers">
            <AppDatePicker
              open={true}
              value={dateStart}
              onChange={(value) => setDateStart(value)}
            />
            <AppDatePicker
              open={true}
              value={dateEnd}
              onChange={(value) => setDateEnd(value)}
            />
          </div>*/}
          <div>
            {fullData?.length
              ? fullData?.map((item, index) => {
                  if (
                    entityCurrentId.includes(item?.id?.toString() + ' ') ||
                    checkboxName != 'Проект'
                  )
                    return (
                      <div className="calendar-event-item" key={index}>
                        {checkboxName != 'Проект' ? (
                          <Link to={`/entity/${item.schemaName}/${item.id}`}>
                            {item.title}
                          </Link>
                        ) : (
                          <div>{item.title}</div>
                        )}
                        <div className="task-full-info">
                          {item?.event_end_date && (
                            <TaskCountDown
                              date_end={getDate(
                                item?.event_end_date?.toString(),
                              )}
                            />
                          )}
                          <div>{item.schemaName}</div>
                        </div>
                        <TaskStatusBar
                          date_end={getDate(item?.event_end_date?.toString())}
                          date_start={getDate(
                            item?.event_start_date?.toString(),
                          )}
                        />
                      </div>
                    )
                })
              : <p>Событий не найдено. Попробуйте изменить параметры фильтрации</p>}
          </div>
        </div>
      </div>
    </>
  )
}

export default EventCalendarPage
