import React, { useEffect, useRef, useState } from 'react'
import { AppTranslation } from '../../../common/components/AppTranslation/AppTranslation'
import { AllRoutes } from '../../../core/routes/AllRoutes'
import AppDatePicker from 'common/components/AppDatePicker/AppDatePicker'
import { subDays } from 'date-fns'
import { EventsCards } from 'common/components/Events/EvensForCalendar'
import {
  AppButton,
  AppButtonColor,
  AppButtonSize,
} from 'common/components/AppButton/AppButton'
import { useGetTasksQuery } from 'core/api/BaseApiEndpoints/Tasks/TasksApi'
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns'
import generatePicker from 'antd/es/date-picker/generatePicker'
import { Link } from 'react-router-dom'
import {
  useGetEntityCalendarFullQuery,
  useGetEntityItemsQuery,
  useGetInfoReportQuery,
} from 'core/api/BaseApiEndpoints/Entity/EntityApi'
import { ConfigProvider } from 'antd'
import locale from 'antd/es/date-picker/locale/ru_RU'
import 'dayjs/locale/ru'
import dayjs from 'dayjs'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { PDF } from './PDF'

type Event = {
  id: number
  title: string
  statusDone: number
  date_start: string
  date_end: string
  performer: string
}
const DatePicker = generatePicker<Date>(dateFnsGenerateConfig)

const InfoCalendarPage = () => {
  const [date, setDate] = useState<any>([])
  const now = new Date()
  now.setDate(now.getDate() - 6)
  const [dateStart, setDateStart] = useState<any>(now)
  const [dateEnd, setDateEnd] = useState<any>(new Date())
  const [dateDiff, setDateDiff] = useState<number>(7)
  const [widthItem, setWidthItem] = useState<number>(190)
  const [changeDate, setChangeDate] = useState<boolean>(false)
  const [dateArr, setDateArr] = useState<any>([])
  const widthRef = useRef<any>(null)
  const formatDates = (date: any) => {
    let fulldate = date
    let dd = fulldate?.getDate()
    let mm = fulldate?.getMonth() + 1
    let yy = fulldate?.getFullYear()
    if (mm.toString().length == 1) mm = '0'+ mm.toString()
    if (dd.toString().length == 1) dd = '0'+ dd.toString()

    return yy + '-' + mm + '-' + dd
  }

  const { data: report } = useGetInfoReportQuery({
    start: formatDates(dateStart),
    end: formatDates(dateEnd),
  })

  /*const { data } = useGetEntityCalendarFullQuery({
    entitySymbolKey: `items=Инфоповод&event_start_date=${formatDates(
      dateStart,
    )}&event_end_date=${formatDates(dateEnd)}`,
  })*/

  const { data } = useGetEntityItemsQuery({
    entitySymbolKey: 'Инфоповод',
    searchParamsString: `&event_start_date=${formatDates(
      dateStart,
    )}&event_end_date=${formatDates(dateEnd)}`,
  })

  const calcDiff = () => {
    setChangeDate(!changeDate)
  }

  const setClocks = (dat:any, type?:string) => {
    var date = new Date(dat);
     type == "end" ? date.setHours(23, 59, 59) : date.setHours(0, 0, 1);
    return(date)
  }

  useEffect(() => {
    const diff =
      Math.ceil(
        Math.abs(setClocks(dateEnd, "end").getTime() - setClocks(dateStart, "start").getTime()) / (1000 * 3600 * 24),
      ) 
    setDateDiff(diff)
  }, [dateEnd, dateStart])

  useEffect(() => {
    console.log('@@@@', data?.items)
    const arr = []
    for (let i = 0; i < dateDiff; i++) {
      arr[i] = subDays(new Date(dateEnd.getTime()), dateDiff - i - 1)
      setDateArr(arr)
    }
    widthRef?.current && setWidthItem(widthRef.current.clientWidth - 35)
    const dateArr = []
    dateArr[1] = setClocks(dateStart, "start")
    dateArr[2] = setClocks(dateEnd, "end")
    dateArr[3] = dateDiff
    setDate(dateArr)
    console.log('########', dateArr)
  }, [changeDate])

  const getWeekDay = (date: any) => {
    const days = ['Вс.', 'Пн.', 'Вт.', 'Ср.', 'Чт.', 'Пт.', 'Сб.']

    return days[date.getDay()]
  }

  const getMonthName = (date: any) => {
    const months = [
      'Январь',
      'Февраль',
      'Март',
      'Апрель',
      'Май',
      'Июнь',
      'Июль',
      'Август',
      'Сентябрь',
      'Ноябрь',
      'Декабрь',
    ]
    return date != 0 ? months[date - 1] : 'Январь'
  }

  const formatDate = (date: any) => {
    let dd = date.getDate()
    if (dd < 10) dd = '0' + dd

    let mm = date.getMonth() + 1
    if (mm < 10) mm = '0' + mm

    let yy: any = date.getFullYear() % 100
    if (yy < 10) yy = '0' + yy

    return dd + '.' + mm + '.' + yy
  }

  const sideToShow = (event: any) => {
    const dateStart = new Date(event.date_start)
    const dateEnd = new Date(event.date_end)
    const isStart = dateStart <= date[2]
    const isEnd = dateEnd >= date[1] && dateEnd <= date[2]
    return isStart && isEnd
  }

  const getLastDayOfMonth = (year: any, month: any) => {
    let date = new Date(year, month + 1, 0)
    setDateEnd(date)
    return formatDates(date)
  }

  const getFirstDayOfMonth = (year: any, month: any) => {
    let date = new Date(year, month, 1)
    setDateStart(date)
    return formatDates(date)
  }

  const getWeekNumber = (d: any) => {
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()))
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    setDateEnd(
      new Date(d.setUTCDate(d.getUTCDate() + 7 - (d.getUTCDay() || 7))),
    )
    setDateStart(
      new Date(d.setUTCDate(d.getUTCDate() + 1 - (d.getUTCDay() || 7))),
    )
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7))
    // Get first day of year
    let yearStart: any = new Date(Date.UTC(d.getUTCFullYear(), 0, 1))
    // Calculate full weeks to nearest Thursday
    let weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7)
    // Return array of year and week number
    return [d.getUTCFullYear(), weekNo]
  }

  return (
    <>
      <div className="back-office-header calendar-header">
        <h1>
          <AppTranslation label={AllRoutes.infoCalendar.name} />
        </h1>

        <div className={'header-actions-block'}>
          <div className="app-input-wrapper">
            <div className="app-input-label">Выбрать неделю</div>

            <div className={`app-input`}>
              <DatePicker
                picker="week"
                format={'ww/YYYY'}
                className={'date-picker'}
                allowClear={false}
                locale={locale}
                //value={dateEnd}
                onChange={(value) => {
                  console.log('week', getWeekNumber(value))
                }}
                style={{
                  height: 'auto',
                  width: '100%',
                  border: 'none',
                  borderRadius: '0px',
                  cursor: 'pointer',
                  margin: '0px',
                  padding: '0 20px 0 0',
                }}
                suffixIcon={<i className="an-ico an-ico-calendar" />}
              />
            </div>
          </div>
          <div className="app-input-wrapper">
            <div className="app-input-label">Выбрать месяц</div>
            <div className={`app-input`}>
              <DatePicker
                picker="month"
                format={'MM.YYYY'}
                className={'date-picker'}
                allowClear={false}
                locale={locale}
                value={dateEnd}
                onChange={(value) => {
                  getLastDayOfMonth(value?.getFullYear(), value?.getMonth())
                  getFirstDayOfMonth(value?.getFullYear(), value?.getMonth())
                }}
                style={{
                  height: 'auto',
                  width: '100%',
                  border: 'none',
                  borderRadius: '0px',
                  cursor: 'pointer',
                  margin: '0px',
                  padding: '0 20px 0 0',
                }}
                suffixIcon={<i className="an-ico an-ico-calendar" />}
              />
            </div>
          </div>
          <AppButton
            onClick={calcDiff}
            color={AppButtonColor.aquaBlue}
            size={AppButtonSize.base}
            title={'general__txt_search'}
          />
          <div className="value-item" style={{width:"150px"}}>
            <PDFDownloadLink
              document={
                <PDF data={report} title={'Отчёт'} />
              }
              fileName="Отчет_за_период.pdf"
            >
              {
                // @ts-ignore
                ({ blob, url, loading, error }) =>
                  loading ? 'Загрузка...' : 'Скачать отчёт'
              }
            </PDFDownloadLink>
          </div>
        </div>
      </div>
      <div className="app-table-wrapper info-calendar">
        <div className={'rem_calendar'} ref={widthRef}>
          <EventsCards widthItem={widthItem} date={date} events={data?.items}/>
          {dateArr?.map((cell: any, index: number) => (
            <div key={index} className={'rem_day'}>
              <div className={'rem_headLine'}>
                <div>{getWeekDay(cell)}</div>
                <div className={'rem_dayOfWeek'}>
                  {cell.getDate()} {getMonthName(cell.getMonth())}
                </div>
              </div>
              <div className={'rem_delimiter'} />
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default InfoCalendarPage
