import React from 'react'
import { IReferenceValuesTypeObject } from '../../../core/api/dto/EntityDto'
import { ResponsiblePersonView } from '../../../common/components/AppTable/ResponsiblePersonView'
import { ToolTips } from '../../../common/components/ToolTips/ToolTips'
import { Link } from 'react-router-dom'

interface IEntityTableFieldWidget {
  itemData: IReferenceValuesTypeObject | null
  isMulti?: boolean
  tableOptions?: {
    responsiblePersonViewHideIco?: boolean
  }
}

export const ItemRepresentByDataType = ({
  itemData,
  isMulti,
  tableOptions,
}: IEntityTableFieldWidget) => {
  if (!itemData) {
    return <>---</>
  }
  if ('id' in itemData && 'last_name' in itemData && 'first_name' in itemData && 'patronymic' in itemData) {
    return (
      <ResponsiblePersonView
        name={itemData.last_name + ' ' + itemData.first_name + ' ' + `${itemData.patronymic || ''}`}
        img={itemData.photo}
        shortView={isMulti}
        hideIco={tableOptions?.responsiblePersonViewHideIco}
      />
    )
  } else if (
    'id' in itemData &&
    'url' in itemData &&
    typeof itemData.url === 'string' &&
    'title' in itemData
  ) {
    return (
      <ToolTips tooltipText={itemData.url}>
        <>{itemData.title}</>
      </ToolTips>
    )
  } else if ('id' in itemData && !('url' in itemData) && 'title' in itemData) {
    return <>{itemData.title}</>
  } else if ('id' in itemData && 'file' in itemData) {
    return (
      <Link to={itemData.file} target={'_blank'}>
        {itemData.file.split('media/')[1]}
      </Link>
    )
  }
  return <>NaN</>
}
