import React, { ChangeEvent, useState } from 'react'
import {
  AppTranslation,
  translationLabelTape,
} from '../AppTranslation/AppTranslation'
import {
  AppButton,
  AppButtonColor,
  AppButtonSize,
} from '../AppButton/AppButton'

interface IAppInputFileBase {
  accept?: string
  title?: string
  fullWidth?: boolean
  disabled?: boolean
  multiple?: boolean
  label?: translationLabelTape
  error?: string | boolean
  color?: AppButtonColor
  size?: AppButtonSize
  icoClassName?: string | null
  inputName?: string
}

interface IAppInputFileOne extends IAppInputFileBase {
  onChangeFile: (file: File | null) => void
  value: File | null
  multiple?: false
  onChangeFiles?: never
}

interface IAppInputFileMulti extends IAppInputFileBase {
  onChangeFiles: (file: FileList | null) => void
  value: FileList | null
  multiple: true
  onChangeFile?: never
}

// .xls  "application/vnd.ms-excel"
// .xlsx  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"

type IAppInputFile = IAppInputFileMulti | IAppInputFileOne
const AppInputFile = ({
  title,
  accept,
  value,
  onChangeFile,
  onChangeFiles,
  disabled,
  fullWidth,
  multiple = false,
  label,
  error,
  color,
  size,
  icoClassName,
  inputName = 'file',
}: IAppInputFile) => {
  const [inputValue, setInputValue] = useState('')
  const [resultString, setResultString] = useState('')
  const changeHandler = (e: ChangeEvent<HTMLInputElement> | null) => {
    if (e && e.target.files) {
      e.preventDefault()
      if (multiple && !!onChangeFiles) {
        const file = e.target.files
        setInputValue(e.target.value)
        onChangeFiles(file)
        const inputArr: string[] = []
        Array.from(e.target.files).forEach((file) => {
          inputArr.push(file.name)
        })
        setResultString(inputArr.join(', '))
      } else if (!!onChangeFile) {
        const file = e.target.files && e.target.files[0]
        setInputValue(e.target.value)
        onChangeFile(file)
        setResultString(e.target.files[0].name)
      }
    } else {
      if (multiple && !!onChangeFiles) {
        setInputValue('')
        onChangeFiles(null)
        setResultString('')
      } else if (!!onChangeFile) {
        setInputValue('')
        onChangeFile(null)
        setResultString('')
      }
    }
  }

  return (
    <div
      className={`app-input-wrapper ${fullWidth ? 'full-width-input' : ''}`}
      style={{ maxWidth: '100%' }}
    >
      {label && (
        <div className={`app-input-label${disabled ? ' disabled' : ''}`}>
          <b>
            <AppTranslation label={label} />
          </b>
        </div>
      )}
      <div
        className={`app-input-file ${disabled ? ' disabled' : ''}${
          error ? ' error' : ''
        }`}
      >
        <div className={`input-block`}>
          {value && (
            <div className={'file-uploaded'}>
              <AppButton
                size={AppButtonSize.square}
                color={color}
                icoClassName={'an-ico-trash'}
                onClick={() => changeHandler(null)}
              />
              <div className="file-name">
                <span>{resultString}</span>
              </div>
            </div>
          )}
          <input
            type={'file'}
            disabled={disabled}
            name={'file'}
            accept={accept}
            id={`${inputName}`}
            value={inputValue}
            onChange={changeHandler}
            multiple={multiple}
          />
          <label htmlFor={`${inputName}`}>
            {!value && (
              <AppButton
                title={title}
                size={size}
                color={color}
                icoClassName={'icoFile'}
              />
            )}
          </label>
        </div>
      </div>
    </div>
  )
}

export default AppInputFile
