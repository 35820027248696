import React, { useMemo } from 'react'
import { AppTranslation } from '../AppTranslation/AppTranslation'

interface ITaskDatesWidget {
  date_start?: Date
  date_end?: Date
}

export const FromDatesWidget = ({ date_start, date_end }: ITaskDatesWidget) => {
  const datesObj = useMemo(() => {
    return {
      date_start: date_start && new Date(date_start),
      date_end: date_end && new Date(date_end),
    }
  }, [date_start, date_end])

  return (
    <React.Fragment>
      {datesObj.date_start && (
        <div>
          <AppTranslation
            label={'task_section_widget_date_start_formatter__txt_title'}
            options={{ date: datesObj.date_start }}
          />
        </div>
      )}
      {datesObj.date_end && (
        <div style={{ marginTop: 7 }}>
          <AppTranslation
            label={'task_section_widget_date_end_formatter__txt_title'}
            options={{ date: datesObj.date_end }}
          />
        </div>
      )}
    </React.Fragment>
  )
}
