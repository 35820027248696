import React, { useMemo } from 'react'
import getUnixTime from 'date-fns/getUnixTime'

interface ITaskStatusBar {
  date_start: Date
  date_end: Date
  showLabel?: boolean
}

export const TaskStatusBar = ({
  date_start,
  date_end,
  showLabel,
}: ITaskStatusBar) => {
  const result = useMemo(() => {
    const dateNow = getUnixTime(new Date())
    const dateStartTimestamp = getUnixTime(new Date(date_start))
    const dateEndTimestamp = getUnixTime(new Date(date_end))
    return dateNow <= dateStartTimestamp
      ? 0
      : dateNow >= dateEndTimestamp
      ? 100
      : ((dateEndTimestamp - dateNow) * 100) /
        (dateEndTimestamp - dateStartTimestamp)
  }, [date_start, date_end])

  return (
    <div className="status-bar">
      <div className="bar-track">
        <div
          className={`bar-value ${
            result === 100 ? 'bg-color-Red' : 'bg-color-Indigo'
          }`}
          style={{ width: `${result}%` }}
        />
      </div>
      {showLabel && <div className="bar-label">{`${Math.round(result)}%`}</div>}
    </div>
  )
}
