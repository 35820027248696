import React, { useMemo } from 'react'
import { TagsListingBlock } from '../TagsListingBlock/TagsListingBlock'
import { EntityForeignKeyFormViewExtractor } from '../EntityForeignKeyFormViewExtractor/EntityForeignKeyFormViewExtractor'

interface ITargetAudienceWidget {
  targetAudienceData: number[] | undefined
}

export const TargetAudienceWidget = ({
  targetAudienceData,
}: ITargetAudienceWidget) => {
  const extractedData = useMemo(() => {
    return targetAudienceData
      ? targetAudienceData.map((item) => (
          <EntityForeignKeyFormViewExtractor
            dataType={'target_audience'}
            value={item}
          />
        ))
      : []
  }, [targetAudienceData])

  return (
    <div className="section-widget">
      <h2>Целевая аудитория</h2>
      <div className="widget-content">
        <div className="content-children">
          {<TagsListingBlock tagsData={extractedData} />}
        </div>
      </div>
    </div>
  )
}
