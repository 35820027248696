import { useAppDispatch, useAppSelector } from '../store/store'
import { NotAuthRoutes } from './NotAuthRoutes'
import { AuthRoutes } from './AuthRoutes'
import { useDetectRoleByPath } from '../../helpers/useDetectRoleByPath'
import { useEffect } from 'react'
import { setCurrentRoleByPath } from '../store/auth/authSlice'
import { Role } from '../api/dto/RolesDto'

const RequireAuth = ({
  children,
  routesWithAuth,
  currentRole,
}: {
  children: JSX.Element
  routesWithAuth: JSX.Element
  currentRole: Role
}) => {
  const isAuthenticated = useAppSelector(
    (state) => state.mediaPlanAuth[currentRole]!.credentials.refresh,
  )
  const rehydrated = useAppSelector((state) => state.system.rehydrated)

  if (!rehydrated) {
    return null
  }

  if (!isAuthenticated) {
    return children
  }

  return routesWithAuth
}

export const AppRouter = () => {
  const currentRole = useDetectRoleByPath()
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(setCurrentRoleByPath({ currentRoleByPath: currentRole }))
  }, [currentRole, dispatch])
  return (
    <RequireAuth routesWithAuth={<AuthRoutes />} currentRole={currentRole}>
      <NotAuthRoutes />
    </RequireAuth>
  )
}
