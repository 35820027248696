import { api } from '../../BaseApi'
import {
  ICheckResetPasswordTokenRequestDto,
  ISetNewPasswordRequestDto,
  IUserCredentialsResponseDto,
  IUserResetPasswordRequestDto,
} from '../../dto/AuthDto'

export const resetPasswordApi = api.injectEndpoints({
  endpoints: (builder) => ({
    resetPassword: builder.mutation<null, IUserResetPasswordRequestDto>({
      query: ({ email }) => {
        return {
          url: `/reset-password/request/`,
          method: 'POST',
          body: { email },
        }
      },
      invalidatesTags: ['User'],
    }),
    checkResetPasswordToken: builder.mutation<
      null,
      ICheckResetPasswordTokenRequestDto
    >({
      query: ({ token }) => {
        return {
          url: `/token/verify/`,
          method: 'POST',
          body: { token },
        }
      },
      invalidatesTags: ['User'],
    }),
    setNewPassword: builder.mutation<
      IUserCredentialsResponseDto,
      ISetNewPasswordRequestDto
    >({
      query: ({ ...formState }) => {
        return {
          url: `/reset-password/reset`,
          method: 'POST',
          body: formState,
        }
      },
      invalidatesTags: ['User'],
    }),
  }),
})

export const {
  useResetPasswordMutation,
  useCheckResetPasswordTokenMutation,
  useSetNewPasswordMutation,
} = resetPasswordApi
