import React from 'react'
import { useRoutes } from 'react-router-dom'
import { appRoutes } from './RoutesByRoles'

const AuthRoutesComponent = React.memo(() => {
  return useRoutes(appRoutes.authorized)
})
export const AuthRoutes = () => {
  return (
    <React.Fragment>
      <AuthRoutesComponent />
    </React.Fragment>
  )
}
