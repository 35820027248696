import React, { useCallback } from 'react'
import { AppDropdown } from './AppDropdown'
import { translationLabelTape } from '../AppTranslation/AppTranslation'
import { AppCheckbox } from '../AppCheckbox/AppCheckbox'

interface IMultiselectDropdownBase<T, TKey> {
  data: T[]
  value: T[] | null
  valuePropName: TKey
  onChange: (value: T[] | null) => void
  label?: translationLabelTape
  emptyLabel?: translationLabelTape
  valueRows?: number
  error?: translationLabelTape | boolean
  disabled?: boolean
  withFilter?: boolean
}

interface IMultiselectDropdownShowByProp<T, TKey>
  extends IMultiselectDropdownBase<T, TKey> {
  propToShowInList: TKey
  showInListRepresent?: never
}

interface IMultiselectDropdownShowByRepresentFunction<T, TKey>
  extends IMultiselectDropdownBase<T, TKey> {
  propToShowInList?: never
  showInListRepresent: (item: T) => string
}

type IMultiselectDropdown<T, TKey> =
  | IMultiselectDropdownShowByProp<T, TKey>
  | IMultiselectDropdownShowByRepresentFunction<T, TKey>

export const MultiselectDropdown = <T, TKey extends keyof T>({
  data,
  value,
  propToShowInList,
  valuePropName,
  onChange,
  label,
  disabled,
  valueRows,
  error,
  showInListRepresent,
  emptyLabel,
  withFilter,
}: IMultiselectDropdown<T, TKey>) => {
  const clickHandler = useCallback(
    (newValue: T) => {
      const result = value ? [...value] : []
      const newValueStackIndex = result
        ? result.findIndex((item) => {
            return item[valuePropName] === newValue[valuePropName]
          })
        : -1
      if (newValueStackIndex >= 0) {
        result.splice(newValueStackIndex, 1)
      } else {
        result.push(newValue)
      }
      onChange(result)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value],
  )

  const getInputValue = (item: T) => {
    return showInListRepresent
      ? showInListRepresent(item)
      : item[propToShowInList]
  }

  const renderItem = useCallback(
    (item) => {
      const currentValue = value?.find(
        (i) => i[valuePropName] === item[valuePropName],
      )
      return (
        <div className={'multiselect-item'}>
          <AppCheckbox value={!!currentValue} uniqId={`multiselect-${item}`} />
          <span>{getInputValue(item)}</span>
        </div>
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value, showInListRepresent, propToShowInList],
  )

  const customInput = useCallback(() => {
    const currentValue = value
      ? value.reduce((acc, value) => {
          return `${acc}${acc !== '' ? ',' : ''} ${getInputValue(value)}`
        }, '')
      : ''
    return (
      <div className={'multiselect-selected-value-wrapper'}>
        <div
          className={`multiselect-selected-value text-ellipsis ${
            currentValue ? '' : 'color-Gray-light'
          }`}
        >
          {currentValue ? currentValue : '...'}
        </div>
      </div>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <AppDropdown
      data={data}
      label={label}
      emptyLabel={emptyLabel}
      propToShowInList={propToShowInList}
      value={value && value.length > 0}
      onChange={clickHandler}
      disabled={disabled}
      showInListRepresent={renderItem}
      customInput={customInput}
      dontHideListOnChange={true}
      valueRows={valueRows}
      error={error}
      withFilter={withFilter}
    />
  )
}
