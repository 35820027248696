import React, { useCallback, useMemo, useState } from 'react'
import { AppTranslation } from '../../../common/components/AppTranslation/AppTranslation'
import { AllRoutes } from '../../../core/routes/AllRoutes'
import { AppTable } from '../../../common/components/AppTable/AppTable'
import { AppTableCell } from '../../../common/components/AppTable/AppTableCell'
import { ResponsiblePersonView } from '../../../common/components/AppTable/ResponsiblePersonView'
import { IWithPaginationResponse } from '../../../core/api/dto/BaseDto'
import { NavLink } from 'react-router-dom'
import Avatar from '../../../common/components/UserBlocks/img/avatar.png'
import { useGetMembersQuery } from 'core/api/BaseApiEndpoints/Members/MembersApi'
import { HeaderSearchInput } from 'common/components/HeaderSearchInput/HeaderSearchInput'
import { useSearchParamsHelper } from 'helpers/setSearchParam'
import { AppDropdown } from 'common/components/AppDropdown/AppDropdown'
import { EmployeePopup } from './EmployeePopup'

const tableConfig = {
  tableHeaders: [
    'employees_page__txt_table_header_name',
    'employees_page__txt_table_header_businessUnit',
    'employees_page__txt_table_header_businessRole',
    'employees_page__txt_table_header_businessContacts',
  ],
  width: ['auto', '25%', '25%', '10%'],
}

const prioritySelectData: any = [
  { value: 'Отдел маркетинга', name: 'Отдел маркетинга' },
]

interface IEmployeeDTO {
  id: number
  last_name: string
  first_name: string
  patronymic: string
  organization_structure: string
  post: string
  phone: string
  email: string
  photo: string
}

interface IProtocolsResponseDTO extends IWithPaginationResponse {
  items: IEmployeeDTO[]
}

export const EmployeesListingPage = () => {
  const { searchParamsString } = useSearchParamsHelper({})
  const { data } = useGetMembersQuery({ searchParamsString })
  const [addSourcePopupOpen, setAddSourcePopupOpen] = useState(false)
  const [structureItem, setStructureItem] = useState<any>(0)
  const addSourceBtnCLickHandler = useCallback(() => {
    setAddSourcePopupOpen(true)
    console.log('id', structureItem)
  }, [setAddSourcePopupOpen])
  const onCloseSourceBtnHandler = useCallback(() => {
    setAddSourcePopupOpen(false)
  }, [setAddSourcePopupOpen])
  const changeHandler = (value: any) => {
    console.log(value.value)
  }
  const tableSelectors = useMemo(() => {
    return [
      {
        renderItem: (item: IEmployeeDTO) => (
          //<NavLink to={`${item.id}`} className="employees-img">
          <a
            onClick={() => {
              addSourceBtnCLickHandler()
              setStructureItem(item)
            }}
            className="employees-img"
          >
            <ResponsiblePersonView
              name={item.last_name + ' ' + item.first_name + ' ' + `${item.patronymic || ''}`}
              img={item.photo}
            />
          </a>
          //</NavLink>
        ),
      },
      {
        renderItem: (item: IEmployeeDTO) => (
          <AppTableCell>
            <AppTableCell>{item.organization_structure}</AppTableCell>
          </AppTableCell>
        ),
      },
      {
        renderItem: (item: IEmployeeDTO) => (
          <AppTableCell>
            <AppTableCell>{item.post}</AppTableCell>
          </AppTableCell>
        ),
      },
      {
        renderItem: (item: IEmployeeDTO) => (
          <AppTableCell>
            <AppTableCell>
              <div className="employee-description-value">
                {item.email && (
                  <a href={'mailto:' + item.email}>
                    <i className="an-ico an-ico-email"></i>
                  </a>
                )}
                {item.phone && (
                  <a href={'tel:' + item.phone}>
                    <i className="an-ico an-ico-phone"></i>
                  </a>
                )}
              </div>
            </AppTableCell>
          </AppTableCell>
        ),
      },
    ]
  }, [])
  return (
    <>
      <div className={'back-office-header'}>
        <h1>
          <AppTranslation label={AllRoutes.employees.name} />
        </h1>
        <div className={'header-actions-block'}>
          <div className="actions-left-block">
            <HeaderSearchInput
              searchParam={'search'}
              placeholder={'general__txt_search'}
            />
            <AppDropdown
              data={prioritySelectData ? prioritySelectData : []}
              propToShowInList={'name'}
              value={prioritySelectData[0]}
              withFilter={true}
              onChange={changeHandler}
              disabled={false}
            />
          </div>
        </div>
      </div>
      <AppTable
        headerTitles={tableConfig.tableHeaders}
        cellWidth={tableConfig.width}
        tableDataSelectors={tableSelectors}
        data={data ? data : null}
        lastColAlignLeft={true}
      />
      {addSourcePopupOpen && (
        <EmployeePopup
          onClose={onCloseSourceBtnHandler}
          structureItem={structureItem}
        />
      )}
    </>
  )
}
