import { EyeOutlined, LogoutOutlined, PrinterOutlined } from '@ant-design/icons';
import { useGetOrganizationStructureQuery } from 'core/api/BaseApiEndpoints/Members/MembersApi';
import React from 'react';
import { Link } from 'react-router-dom';

const controlStyles = { fontSize: 10 };

type Props = {
  layout: string;
  orientation: string;
  linkType: string;
  stepPercent: number;
  setLayout: (layout: string) => void;
  setOrientation: (orientation: string) => void;
  setLinkType: (linkType: string) => void;
  setStepPercent: (percent: number) => void;
};

export default function LinkControls({
  layout,
  orientation,
  linkType,
  stepPercent,
  setStepPercent,
}: Props) {
  const { data } = useGetOrganizationStructureQuery()
  console.log('data', data)
  return (
    <div style={controlStyles} className="noPrint">
      {/*{linkType === 'step' && layout !== 'polar' && (
        <>
          &nbsp;&nbsp;
          <label>step:</label>&nbsp;
          <input
            onClick={(e) => e.stopPropagation()}
            type="range"
            min={0}
            max={1}
            step={0.1}
            onChange={(e) => setStepPercent(Number(e.target.value))}
            value={stepPercent}
            disabled={linkType !== 'step' || layout === 'polar'}
          />
        </>
      )}*/}
      
      </div>
  );
}
