import React, { useEffect, useRef, useState } from 'react'
import { AppTranslation } from '../../../common/components/AppTranslation/AppTranslation'
import { AllRoutes } from '../../../core/routes/AllRoutes'
import AppDatePicker from 'common/components/AppDatePicker/AppDatePicker'
import { subDays } from 'date-fns'
import { EventsCards } from 'common/components/Tasks/TasksForCalendar'
import {
  AppButton,
  AppButtonColor,
  AppButtonSize,
} from 'common/components/AppButton/AppButton'
import { useGetTasksQuery } from 'core/api/BaseApiEndpoints/Tasks/TasksApi'
import { Link } from 'react-router-dom'

type Event = {
  id: number
  title: string
  statusDone: number
  date_start: string
  date_end: string
  performer: string
}

const TasksCalendarPage = () => {
  const { data } = useGetTasksQuery({})
  const [date, setDate] = useState<any>([])
  const now = new Date()
  now.setDate(now.getDate() - 6)
  const [dateStart, setDateStart] = useState<any>(now)
  const [dateEnd, setDateEnd] = useState<any>(new Date())
  const [dateDiff, setDateDiff] = useState<number>(7)
  const [widthItem, setWidthItem] = useState<number>(190)
  const [changeDate, setChangeDate] = useState<boolean>(false)
  const [dateArr, setDateArr] = useState<any>([])
  const widthRef = useRef<any>(null)

  const calcDiff = () => {
    setChangeDate(!changeDate)
  }

  useEffect(() => {
    const diff =
      Math.ceil(
        Math.abs(dateEnd.getTime() - dateStart.getTime()) / (1000 * 3600 * 24),
      ) + 1
    setDateDiff(diff)
  }, [dateEnd, dateStart])

  useEffect(() => {
    console.log(data)
    const arr = []
    for (let i = 0; i < dateDiff; i++) {
      arr[i] = subDays(new Date(dateEnd.getTime()), dateDiff - i - 1)
      setDateArr(arr)
    }
    widthRef?.current && setWidthItem(widthRef.current.clientWidth - 35)
    const dateArr = []
    dateArr[1] = dateStart
    dateArr[2] = dateEnd
    dateArr[3] = dateDiff
    setDate(dateArr)
  }, [changeDate])

  const getWeekDay = (date: any) => {
    const days = ['Вс.', 'Пн.', 'Вт.', 'Ср.', 'Чт.', 'Пт.', 'Сб.']

    return days[date.getDay()]
  }

  const getMonthName = (date: any) => {
    const months = [
      'Январь',
      'Февраль',
      'Март',
      'Апрель',
      'Май',
      'Июнь',
      'Июль',
      'Август',
      'Сентябрь',
      'Ноябрь',
      'Декабрь',
    ]
    return months[date?.getMonth() - 1]
  }

  const formatDate = (date: any) => {
    let dd = date.getDate()
    if (dd < 10) dd = '0' + dd

    let mm = date.getMonth() + 1
    if (mm < 10) mm = '0' + mm

    let yy: any = date.getFullYear() % 100
    if (yy < 10) yy = '0' + yy

    return dd + '.' + mm + '.' + yy
  }

  const sideToShow = (event: any) => {
    const dateStart = new Date(event.date_start)
    const dateEnd = new Date(event.date_end)
    const isStart = dateStart <= date[2]
    const isEnd = dateEnd >= date[1] && dateEnd <= date[2]
    return isStart && isEnd
  }

  return (
    <>
      <div className="back-office-header calendar-header">
        <h1>
          <AppTranslation label={AllRoutes.taskCalendar.name} />
        </h1>

        <div className={'header-actions-block'}>
          <p>Выбрать период</p>
          <AppDatePicker
            value={dateStart}
            onChange={(value) => setDateStart(value)}
          />
          <AppDatePicker
            value={dateEnd}
            onChange={(value) => setDateEnd(value)}
          />
          <AppButton
            onClick={calcDiff}
            color={AppButtonColor.aquaBlue}
            size={AppButtonSize.base}
            title={'general__txt_search'}
          />
        </div>
      </div>
      <div className="app-table-wrapper calendar-table-wrapper">
        <div className="tasks-names">
          {/*<div className="rem_headMonth">
          {dateArr[0]?.getMonth() !== 0 ? getMonthName(dateArr[0]) : 'Январь'}
        </div>*/}
          <div className="rem_headPeriod">
            <b>Указанный период</b>
            <span>
              {formatDate(dateStart)} - {formatDate(dateEnd)}
            </span>
          </div>
          <div className="rem_tasks">
            {data?.items?.map((task: any) => {
              if (sideToShow(task))
                return (
                  <>
                    <Link to={`/tasks/${task.id}`}>
                      <b>{task.name}</b>

                      {Object.keys(task.entities).map((entityKey) => {
                        return (
                          <React.Fragment key={entityKey}>
                            {task.entities[entityKey].map((entityItem: any) => {
                              return (
                                <div
                                  key={`${entityKey}-${entityItem.id}`}
                                  //to={`/${AllRoutes.entity.path}/${entityKey}/${entityItem.id}`}
                                >
                                  {entityItem.title}
                                </div>
                              )
                            })}
                          </React.Fragment>
                        )
                      })}
                    </Link>
                  </>
                )
            })}
          </div>
        </div>
        <div className={'rem_calendar'} ref={widthRef}>
          <EventsCards widthItem={widthItem} date={date} />
          {dateArr?.map((cell: any, index: number) => (
            <div
              key={index}
              className={
                cell.getDate() === new Date().getDate()
                  ? 'current_day rem_day'
                  : 'rem_day'
              }
            >
              <div className={'rem_headLine'}>
                <div>{getWeekDay(cell)}</div>
                <div className={'rem_dayOfWeek'}>{cell.getDate()}</div>
              </div>
              <div className={'rem_delimiter'} />
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default TasksCalendarPage
