import { useGetEntityItemsQuery } from 'core/api/BaseApiEndpoints/Entity/EntityApi'
import { useGetProjectItemsQuery } from 'core/api/BaseApiEndpoints/Projects/ProjectsApi'
import { AllRoutes } from 'core/routes/AllRoutes'
import {
  AppButton,
  AppButtonColor,
  AppButtonSize,
} from '../AppButton/AppButton'
import { PersonsRepresentWidget } from '../PersonsRepresentWidget/PersonsRepresentWidget'

export const UserProjects = () => {
  const { data } = useGetProjectItemsQuery({})
  return (
    <>
      <div className="projects">
        <h3 className="projects_in_process">Проекты в работе</h3>
        <div className="projects_tab">
          <div className="projects_tab_top">
            <p>Название</p>
            <p>Автор</p>
          </div>
          <div className="projects_tab_bottom">
            {data?.items?.slice(0, 3).map((item: any, index) => {
              return (
                <div className="projects_tab_item" key={index}>
                  <p>{item.title}</p>
                  <p>
                    {item?.responsible?.full_name ? (
                      item?.responsible?.full_name
                    ) : (
                      <PersonsRepresentWidget
                        data={item?.responsible}
                        dataType={'responsible'}
                        label={''}
                      />
                    )}
                  </p>
                  <AppButton
                    path={`/${AllRoutes.projects.path}/${item.id}/${AllRoutes.projectRootCreateTask.path}/${AllRoutes.tasksListing.path}`}
                    icoClassName={'an-ico-plus-thin'}
                    title={{
                      label: 'tasks_create_page__txt_nav_button_title',
                    }}
                    color={AppButtonColor.lightBlue}
                    size={AppButtonSize.small}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}
