export enum Role {
  ROLE_ADMIN = 'ROLE_ADMIN',
}

export type objectFromRoleType = {
  [key in Role]?: string
}

export const apiPathNameByRole: objectFromRoleType = {
  [`${Role.ROLE_ADMIN}`]: 'admins',
}

export const workDirPaths: objectFromRoleType = {
  [`${Role.ROLE_ADMIN}`]: '/',
}
