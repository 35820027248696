import React from 'react'

export const DashboardEvents = () => {
  return (
    <div className="dashboard__events dashboard-events dashboard-card">
      <div className="dashboard-events__head">
        <div className="dashboard-events__title">Последние события</div>
        <div className="dashboard-events__filter">Фильтр</div>
      </div>
      <div className="dashboard-events__wrap">
        <div className="dashboard-events__list">
          <div className="dashboard-events-item">
            <div className="dashboard-events-item__head">
              <div className="dashboard-events-item__icon">
                <svg
                  width="31"
                  height="30"
                  viewBox="0 0 31 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.47656 5C8.37656 5 7.48656 5.9 7.48656 7L7.47656 23C7.47656 24.1 8.36656 25 9.46656 25H21.4766C22.5766 25 23.4766 24.1 23.4766 23V11.83C23.4766 11.3 23.2666 10.79 22.8866 10.42L18.0566 5.59C17.6866 5.21 17.1766 5 16.6466 5H9.47656ZM16.4766 11V6.5L21.9766 12H17.4766C16.9266 12 16.4766 11.55 16.4766 11Z"
                    fill="#38A7DF"
                  />
                </svg>
              </div>
              <div className="dashboard-events-item__time">11:20</div>
              <div className="dashboard-events-item__name">
                Новый пользователь
              </div>
              <div className="dashboard-events-item__author">Имя автора</div>
            </div>
            <div className="dashboard-events-item__content">
              <p>О новом проекте</p>
            </div>
          </div>
          <div className="dashboard-events-item">
            <div className="dashboard-events-item__head">
              <div className="dashboard-events-item__icon">
                <svg
                  width="31"
                  height="30"
                  viewBox="0 0 31 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M23.4023 5H7.40234C6.30234 5 5.41234 5.9 5.41234 7L5.40234 25L9.40234 21H23.4023C24.5023 21 25.4023 20.1 25.4023 19V7C25.4023 5.9 24.5023 5 23.4023 5ZM20.4023 17H10.4023C9.85234 17 9.40234 16.55 9.40234 16C9.40234 15.45 9.85234 15 10.4023 15H20.4023C20.9523 15 21.4023 15.45 21.4023 16C21.4023 16.55 20.9523 17 20.4023 17ZM20.4023 14H10.4023C9.85234 14 9.40234 13.55 9.40234 13C9.40234 12.45 9.85234 12 10.4023 12H20.4023C20.9523 12 21.4023 12.45 21.4023 13C21.4023 13.55 20.9523 14 20.4023 14ZM20.4023 11H10.4023C9.85234 11 9.40234 10.55 9.40234 10C9.40234 9.45 9.85234 9 10.4023 9H20.4023C20.9523 9 21.4023 9.45 21.4023 10C21.4023 10.55 20.9523 11 20.4023 11Z"
                    fill="#38A7DF"
                  />
                </svg>
              </div>
              <div className="dashboard-events-item__time">11:20</div>
              <div className="dashboard-events-item__name">
                Новый пользователь
              </div>
              <div className="dashboard-events-item__author">Имя автора</div>
            </div>
            <div className="dashboard-events-item__content">
              <p>
                Прежде всего, граница обучения кадров предполагает независимые
                способы реализации модели развития. Таким образом, постоянное
                информационн ...
              </p>
            </div>
          </div>
          <div className="dashboard-events-item">
            <div className="dashboard-events-item__head">
              <div className="dashboard-events-item__icon">
                <svg
                  width="31"
                  height="30"
                  viewBox="0 0 31 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.40234 12C7.95234 12 8.40234 11.55 8.40234 11C8.40234 10.45 7.95234 10 7.40234 10C6.85234 10 6.40234 10.45 6.40234 11C6.40234 11.55 6.85234 12 7.40234 12ZM8.40234 15C8.40234 15.55 7.95234 16 7.40234 16C6.85234 16 6.40234 15.55 6.40234 15C6.40234 14.45 6.85234 14 7.40234 14C7.95234 14 8.40234 14.45 8.40234 15ZM8.40234 19C8.40234 19.55 7.95234 20 7.40234 20C6.85234 20 6.40234 19.55 6.40234 19C6.40234 18.45 6.85234 18 7.40234 18C7.95234 18 8.40234 18.45 8.40234 19ZM24.4023 15C24.4023 15.55 23.9523 16 23.4023 16H11.4023C10.8523 16 10.4023 15.55 10.4023 15C10.4023 14.45 10.8523 14 11.4023 14H23.4023C23.9523 14 24.4023 14.45 24.4023 15ZM23.4023 20C23.9523 20 24.4023 19.55 24.4023 19C24.4023 18.45 23.9523 18 23.4023 18H11.4023C10.8523 18 10.4023 18.45 10.4023 19C10.4023 19.55 10.8523 20 11.4023 20H23.4023ZM11.4023 12C10.8523 12 10.4023 11.55 10.4023 11C10.4023 10.45 10.8523 10 11.4023 10H23.4023C23.9523 10 24.4023 10.45 24.4023 11C24.4023 11.55 23.9523 12 23.4023 12H11.4023Z"
                    fill="#38A7DF"
                  />
                </svg>
              </div>
              <div className="dashboard-events-item__time">11:20</div>
              <div className="dashboard-events-item__name">
                Новый пользователь
              </div>
              <div className="dashboard-events-item__author">Имя автора</div>
            </div>
            <div className="dashboard-events-item__content">
              <p>Инфоповод “Название инфовопода”</p>
            </div>
          </div>
          <div className="dashboard-events-item">
            <div className="dashboard-events-item__head">
              <div className="dashboard-events-item__icon">
                <svg
                  width="31"
                  height="30"
                  viewBox="0 0 31 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19.4023 14C21.0623 14 22.3923 12.66 22.3923 11C22.3923 9.34 21.0623 8 19.4023 8C17.7423 8 16.4023 9.34 16.4023 11C16.4023 12.66 17.7423 14 19.4023 14ZM11.4023 14C13.0623 14 14.3923 12.66 14.3923 11C14.3923 9.34 13.0623 8 11.4023 8C9.74234 8 8.40234 9.34 8.40234 11C8.40234 12.66 9.74234 14 11.4023 14ZM11.4023 16C9.07234 16 4.40234 17.17 4.40234 19.5V21C4.40234 21.55 4.85234 22 5.40234 22H17.4023C17.9523 22 18.4023 21.55 18.4023 21V19.5C18.4023 17.17 13.7323 16 11.4023 16ZM19.4023 16C19.1123 16 18.7823 16.02 18.4323 16.05C18.4523 16.06 18.4623 16.08 18.4723 16.09C19.6123 16.92 20.4023 18.03 20.4023 19.5V21C20.4023 21.35 20.3323 21.69 20.2223 22H25.4023C25.9523 22 26.4023 21.55 26.4023 21V19.5C26.4023 17.17 21.7323 16 19.4023 16Z"
                    fill="#38A7DF"
                  />
                </svg>
              </div>
              <div className="dashboard-events-item__time">11:20</div>
              <div className="dashboard-events-item__name">
                Новый пользователь
              </div>
              <div className="dashboard-events-item__author">Имя автора</div>
            </div>
            <div className="dashboard-events-item__content">
              <p>Новый пользователь “Имя пользователя”</p>
            </div>
          </div>
          <div className="dashboard-events-item">
            <div className="dashboard-events-item__head">
              <div className="dashboard-events-item__icon">
                <svg
                  width="31"
                  height="30"
                  viewBox="0 0 31 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.40234 9C3.85234 9 3.40234 9.45 3.40234 10V14H3.41234L3.40234 23C3.40234 24.1 4.30234 25 5.40234 25H22.4023C22.9523 25 23.4023 24.55 23.4023 24C23.4023 23.45 22.9523 23 22.4023 23H6.40234C5.85234 23 5.40234 22.55 5.40234 22V10C5.40234 9.45 4.95234 9 4.40234 9ZM25.4023 7H17.4023L15.9923 5.59C15.6123 5.21 15.1023 5 14.5723 5H9.40234C8.30234 5 7.41234 5.9 7.41234 7L7.40234 19C7.40234 20.1 8.30234 21 9.40234 21H25.4023C26.5023 21 27.4023 20.1 27.4023 19V9C27.4023 7.9 26.5023 7 25.4023 7ZM11.0023 17.2L14.5123 12.52C14.7123 12.26 15.1023 12.25 15.3123 12.51L18.4123 16.5L20.5123 13.97C20.7123 13.72 21.0923 13.73 21.2923 13.98L23.7823 17.18C24.0423 17.51 23.8023 17.99 23.3923 17.99H11.4023C10.9923 18 10.7523 17.53 11.0023 17.2Z"
                    fill="#38A7DF"
                  />
                </svg>
              </div>
              <div className="dashboard-events-item__time">11:20</div>
              <div className="dashboard-events-item__name">
                Новый пользователь
              </div>
              <div className="dashboard-events-item__author">Имя автора</div>
            </div>
            <div className="dashboard-events-item__content">
              <p>Добавил задачу “Название задачи”</p>
              <p>
                Пользователь добавил задачу, краткое описание задачи в текстовом
                формате или фото
              </p>
            </div>
          </div>
          <div className="dashboard-events-item">
            <div className="dashboard-events-item__head">
              <div className="dashboard-events-item__icon">
                <svg
                  width="31"
                  height="30"
                  viewBox="0 0 31 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.47656 5C8.37656 5 7.48656 5.9 7.48656 7L7.47656 23C7.47656 24.1 8.36656 25 9.46656 25H21.4766C22.5766 25 23.4766 24.1 23.4766 23V11.83C23.4766 11.3 23.2666 10.79 22.8866 10.42L18.0566 5.59C17.6866 5.21 17.1766 5 16.6466 5H9.47656ZM16.4766 11V6.5L21.9766 12H17.4766C16.9266 12 16.4766 11.55 16.4766 11Z"
                    fill="#38A7DF"
                  />
                </svg>
              </div>
              <div className="dashboard-events-item__time">11:20</div>
              <div className="dashboard-events-item__name">
                Новый пользователь
              </div>
              <div className="dashboard-events-item__author">Имя автора</div>
            </div>
            <div className="dashboard-events-item__content">
              <p>Добавил задачу “Название задачи”</p>
              <p>
                Пользователь добавил задачу, краткое описание задачи в текстовом
                формате или фото
              </p>
            </div>
          </div>
          <div className="dashboard-events-item">
            <div className="dashboard-events-item__head">
              <div className="dashboard-events-item__icon">
                <svg
                  width="31"
                  height="30"
                  viewBox="0 0 31 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.47656 5C8.37656 5 7.48656 5.9 7.48656 7L7.47656 23C7.47656 24.1 8.36656 25 9.46656 25H21.4766C22.5766 25 23.4766 24.1 23.4766 23V11.83C23.4766 11.3 23.2666 10.79 22.8866 10.42L18.0566 5.59C17.6866 5.21 17.1766 5 16.6466 5H9.47656ZM16.4766 11V6.5L21.9766 12H17.4766C16.9266 12 16.4766 11.55 16.4766 11Z"
                    fill="#38A7DF"
                  />
                </svg>
              </div>
              <div className="dashboard-events-item__time">11:20</div>
              <div className="dashboard-events-item__name">
                Новый пользователь
              </div>
              <div className="dashboard-events-item__author">Имя автора</div>
            </div>
            <div className="dashboard-events-item__content">
              <p>О новом проекте</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}