import React from 'react'
import { Outlet } from 'react-router-dom'

export enum authBgTypes {
  'auth',
  'reset',
}

const bgImages = {
  [authBgTypes.auth]: 'assets/images/auth/auth-background-1.svg',
  [authBgTypes.reset]: 'assets/images/auth/auth-background-2.svg',
}

interface IAuthPageLayout {
  bgType: authBgTypes
}

export const AuthPageLayout = ({ bgType }: IAuthPageLayout) => {
  return (
    <div className="main-container">
      <div className="nav-bar">
        <div className="auth-form-wrapper">
          <div className="auth-form">
            <div className="auth-logo">
              <img src="assets/images/media-plan-logo.svg" alt="" />
            </div>
            <Outlet />
          </div>
        </div>
      </div>
      <div className="content-area container-fluid">
        <div
          className="auth-form-step-bg"
          style={{
            backgroundImage: `url(assets/images/auth/auth-background.png)`,
          }}
        >
          <img src={`${bgImages[bgType]}`} alt="" />
        </div>
      </div>
    </div>
  )
}
