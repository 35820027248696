import { api } from '../../BaseApi'
import { ICommentBase, ICommentsWithPaginationDto } from '../../dto/CommentsDto'

export const taskComments = api.injectEndpoints({
  endpoints: (builder) => ({
    getCommentsByTaskId: builder.query<
      ICommentsWithPaginationDto,
      { taskId: number }
    >({
      query: ({ taskId }) => ({
        url: `/task/${taskId}/comments/`,
      }),
      providesTags: ['Tasks', 'TaskComments'],
    }),
    createComment: builder.mutation<
      {
        author: number
        id: number
        message: string
        parent: number
      },
      { taskId: number; message: string }
    >({
      query: ({ taskId, message }) => ({
        url: `/task/${taskId}/comments/`,
        method: 'POST',
        body: { message },
      }),
      invalidatesTags: ['Tasks', 'TaskComments'],
    }),
    editComment: builder.mutation<
      {
        author: number
        id: number
        message: string
        parent: number
      },
      { commentId: number; message: string }
    >({
      query: ({ commentId, message }) => ({
        url: `/comment/${commentId}/`,
        method: 'PATCH',
        body: { message },
      }),
      invalidatesTags: ['Tasks', 'TaskComments'],
    }),
    replyComment: builder.mutation<
      {
        author: number
        id: number
        message: string
        parent: number
      },
      { taskId: number; message: string; parent: number }
    >({
      query: ({ taskId, message, parent }) => ({
        url: `/task/${taskId}/comments/`,
        method: 'POST',
        body: { message, parent },
      }),
      invalidatesTags: ['Tasks', 'TaskComments'],
    }),
  }),
})

export const {
  useGetCommentsByTaskIdQuery,
  useCreateCommentMutation,
  useEditCommentMutation,
  useReplyCommentMutation,
} = taskComments
