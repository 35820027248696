import React from 'react'

export const DashboardInfo = () => {
  return (
    <div className="dashboard__info dashboard-info dashboard-card">
      <div className="dashboard-info__wrap">
        <div className="dashboard-info__item">
          <div className="dashboard-info__icon">
            <svg
              width="44"
              height="46"
              viewBox="0 0 44 46"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.6211 20.7207C16.4396 20.7017 16.2219 20.7017 16.0223 20.7207C11.7032 20.5682 8.27344 16.8511 8.27344 12.2763C8.27344 7.60608 11.8666 3.81274 16.3308 3.81274C20.7768 3.81274 24.3881 7.60608 24.3881 12.2763C24.3699 16.8511 20.9401 20.5682 16.6211 20.7207Z"
                stroke="#38A7DF"
                strokeWidth="2.625"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M29.7789 7.62439C33.2994 7.62439 36.1304 10.6171 36.1304 14.2961C36.1304 17.8988 33.4083 20.8343 30.0148 20.9678C29.8696 20.9487 29.7063 20.9487 29.543 20.9678"
                stroke="#38A7DF"
                strokeWidth="2.625"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.55152 27.7538C3.15991 30.8419 3.15991 35.8742 7.55152 38.9432C12.542 42.4506 20.7263 42.4506 25.7168 38.9432C30.1084 35.8552 30.1084 30.8228 25.7168 27.7538C20.7445 24.2655 12.5601 24.2655 7.55152 27.7538Z"
                stroke="#38A7DF"
                strokeWidth="2.625"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M33.2812 38.1243C34.5878 37.8384 35.8218 37.2856 36.8381 36.4659C39.669 34.2357 39.669 30.5567 36.8381 28.3265C35.84 27.5259 34.6241 26.9921 33.3357 26.6871"
                stroke="#38A7DF"
                strokeWidth="2.625"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="dashboard-info__info">
            <div className="dashboard-info__name">Все задачи</div>
            <div className="dashboard-info__value">5,423</div>
            <div className="dashboard-info__change dashboard-info__change--up">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 17L10 5"
                  stroke="#38A7DF"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M4.16797 9.99996L10.0013 4.16663L15.8346 9.99996"
                  stroke="#38A7DF"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span>16%</span> в этом месяце
            </div>
          </div>
        </div>
        <div className="dashboard-info__item">
          <div className="dashboard-info__icon">
            <svg
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24.957 32.9527L27.584 35.7121L32.8381 30.1932"
                stroke="#38A7DF"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M21.0166 18.1028C20.8438 18.0847 20.6364 18.0847 20.4463 18.1028C16.333 17.9576 13.0665 14.4175 13.0665 10.0605C13.0492 5.6127 16.4885 2 20.7228 2C24.9572 2 28.3965 5.6127 28.3965 10.0605C28.3965 14.4175 25.1127 17.9576 21.0166 18.1028Z"
                stroke="#38A7DF"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M20.7214 37.963C17.5759 37.963 14.4477 37.1279 12.0626 35.4577C7.88016 32.5168 7.88016 27.724 12.0626 24.8012C16.8155 21.4608 24.6101 21.4608 29.3629 24.8012"
                stroke="#38A7DF"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="dashboard-info__info">
            <div className="dashboard-info__name">Новые</div>
            <div className="dashboard-info__value">1,893</div>
            <div className="dashboard-info__change dashboard-info__change--down">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 3L10 15"
                  stroke="#C65959"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15.832 10L9.9987 15.8334L4.16536 10"
                  stroke="#C65959"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span>1%</span> в этом месяце
            </div>
          </div>
        </div>
        <div className="dashboard-info__item">
          <div className="dashboard-info__icon">
            <svg
              width="44"
              height="46"
              viewBox="0 0 44 46"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.6862 3.81238H31.8477C38.3081 3.81238 39.9232 5.50889 39.9232 12.2759V24.3421C39.9232 31.1282 38.3081 32.8057 31.8658 32.8057H11.6862C5.244 32.8247 3.62891 31.1282 3.62891 24.3612V12.2759C3.62891 5.50889 5.244 3.81238 11.6862 3.81238Z"
                stroke="#38A7DF"
                strokeWidth="2.625"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M21.7773 32.8242V41.9358"
                stroke="#38A7DF"
                strokeWidth="2.625"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M3.62891 24.7805H39.9232"
                stroke="#38A7DF"
                strokeWidth="2.625"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M13.6094 41.9364H29.9418"
                stroke="#38A7DF"
                strokeWidth="2.625"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="dashboard-info__info">
            <div className="dashboard-info__name">Мои</div>
            <div className="dashboard-info__value">189</div>
            <div className="dashboard-info__change dashboard-info__change--down">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 3L10 15"
                  stroke="#C65959"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15.832 10L9.9987 15.8334L4.16536 10"
                  stroke="#C65959"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span>3%</span> в этом месяце
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}