import React from 'react'
import { IBaseProfileDto } from '../../../core/api/dto/ProfilesDto'
import { NavLink } from 'react-router-dom'
import { ISourceShot } from '../../../core/api/dto/SourcesDto'

export const TableExecutors = ({
  data,
  maxWidth,
}: {
  data: IBaseProfileDto[] | ISourceShot[] | string[]
  maxWidth: number
}) => {
  return (
    <div
      className={'table-executors text-ellipsis'}
      style={{ maxWidth: maxWidth ? maxWidth : 'auto' }}
    >
      {data.slice(0,1).map((executor, index) => {
        if (typeof executor === 'string') {
          return (
            <span
              key={`executor-${executor}-${index}`}
              className={'table-executor-item'}
            >
              {executor}
            </span>
          )
        }
        return (
          <NavLink
            key={`executor-${executor.id}-${index}`}
            className={'table-executor-item'}
            to={`/employees/${executor.id}`}
          >
            {`${
              'first_name' in executor && executor.first_name && executor.last_name
                ? executor.last_name + " " + executor.first_name + `${executor.patronymic ? executor.patronymic : ''}`
                : 'name' in executor && executor.name
                ? executor.name
                : '---'
            }`}
          </NavLink>
        )
      })}
    </div>
  )
}
