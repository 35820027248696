import { AppTranslation } from 'common/components/AppTranslation/AppTranslation'
import { AllRoutes } from 'core/routes/AllRoutes'
import { StructureGraph } from 'common/components/StructureGraph/graphComponent'

export const StructureListingPage = () => {
  return (
    <div>
      <div className={'back-office-header'}>
        <h1>
          <AppTranslation label={AllRoutes.structure.name} />
        </h1>
      </div>
      <StructureGraph />
    </div>
  )
}
