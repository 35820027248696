import React, { useMemo } from 'react'
import { AppTranslation } from '../../../common/components/AppTranslation/AppTranslation'
import { HeaderSearchInput } from '../../../common/components/HeaderSearchInput/HeaderSearchInput'
import {
  AppButton,
  AppButtonColor,
  AppButtonSize,
} from '../../../common/components/AppButton/AppButton'
import { AllRoutes } from '../../../core/routes/AllRoutes'
import { AppTable } from '../../../common/components/AppTable/AppTable'
import { AppTableCell } from '../../../common/components/AppTable/AppTableCell'
import { useSearchParamsHelper } from '../../../helpers/setSearchParam'
import { Link, NavLink } from 'react-router-dom'
import { AppFilterDropdown } from '../../../common/components/AppDropdown/AppFilterDropdown'
import { useGetReferenceValuesForeignQuery } from 'core/api/BaseApiEndpoints/Entity/EntityApi'
import { useGetProjectItemsQuery } from 'core/api/BaseApiEndpoints/Projects/ProjectsApi'
import { PersonsRepresentWidget } from 'common/components/PersonsRepresentWidget/PersonsRepresentWidget'
import NewTable from 'common/components/AppTable/NewTable'

const tableConfig = {
  width: [100, 380, 200, 100, 200, 200, 230, 300],
}

const tableHeaders = [
  'ID',
  'Наименование',
  'Дата начала',
  'Дата окончания',
  'Ответственный',
  '',
]

export const ProjectsList = () => {
  const { searchParamsString } = useSearchParamsHelper({
    withPagination: true,
    shouldResetPaginationByParamsChanged: true,
  })

  const { data: usersItems } = useGetReferenceValuesForeignQuery({
    ForeignKey: 'coowner',
  })

  const usersSelectList = useMemo(() => {
    if (usersItems) {
      return usersItems.map((item) => ({
        value: item.id,
        name: item.title,
      }))
    }
  }, [usersItems])

  const publishedList = [
    { value: 'True', name: 'нет' },
    { value: 'False', name: 'да' },
  ]
  const { data } = useGetProjectItemsQuery({ searchParamsString })

  const tableSelectors = useMemo(() => {
    return [
      {
        renderItem: ({ id }: any) => <AppTableCell>{id}</AppTableCell>,
      },
      {
        renderItem: ({ title, id, is_published }: any) => (
          <AppTableCell>
            <NavLink to={`/projects/${id}`} className="project-title">
              {title}
              <p className="project-title_draft">
                {!is_published && 'Черновик'}
              </p>
            </NavLink>
          </AppTableCell>
        ),
      },
      {
        renderItem: ({ start_date }: any) => (
          <AppTableCell>
            <div>
              <AppTranslation
                label={'task_section_widget_date_formatter__txt_title'}
                options={{
                  date: start_date && new Date(start_date),
                }}
              />
            </div>
          </AppTableCell>
        ),
      },
      {
        renderItem: ({ end_date }: any) => (
          <AppTableCell>
            <div>
              <AppTranslation
                label={'task_section_widget_date_formatter__txt_title'}
                options={{ date: end_date && new Date(end_date) }}
              />
            </div>
          </AppTableCell>
        ),
      },
      {
        renderItem: ({ responsible }: any) => (
          <AppTableCell>
            {responsible ? (
              <PersonsRepresentWidget
                data={responsible}
                dataType={'responsible'}
                label={''}
              />
            ) : (
              <span>---</span>
            )}
          </AppTableCell>
        ),
      },
      {
        renderItem: ({ id }: any) => (
          <AppTableCell>
            <div className="entity-actions">
              <div
                className="add-task"
                onClick={(evt) => evt.stopPropagation()}
              >
                <AppButton
                  //path={`/${AllRoutes.tasksListing.path}/${AllRoutes.tasksCreate.path}`}
                  path={`${id}/${AllRoutes.projectRootCreateTask.path}/${AllRoutes.tasksListing.path}`}
                  icoClassName={'an-ico-plus-thin'}
                  title={{
                    label: 'tasks_create_page__txt_nav_button_title',
                    // options: { entityName: entitySymbolKey },
                  }}
                  color={AppButtonColor.lightBlue}
                  size={AppButtonSize.small}
                />
              </div>
              <Link
                to={`${id}/edit`}
                className="edit"
                onClick={(evt) => evt.stopPropagation()}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15V13"
                    stroke="#818181"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16.0418 3.02001L8.16183 10.9C7.86183 11.2 7.56183 11.79 7.50183 12.22L7.07183 15.23C6.91183 16.32 7.68183 17.08 8.77183 16.93L11.7818 16.5C12.2018 16.44 12.7918 16.14 13.1018 15.84L20.9818 7.96001C22.3418 6.60001 22.9818 5.02001 20.9818 3.02001C18.9818 1.02001 17.4018 1.66001 16.0418 3.02001Z"
                    stroke="#818181"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14.9102 4.15002C15.5802 6.54002 17.4502 8.41002 19.8502 9.09002"
                    stroke="#818181"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Link>
            </div>
          </AppTableCell>
        ),
      },
    ]
  }, [])

  if (!data) {
    return null
  }

  return (
    <>
      <div className={'back-office-header'}>
        <h1>
          <AppTranslation label={AllRoutes.projects.name} options={{}} />
        </h1>
        <div className={'header-actions-block'}>
          <div className="actions-left-block">
            <HeaderSearchInput
              searchParam={'search'}
              placeholder={'general__txt_search'}
            />
            <div className={'header-filters-block'}>
              <AppFilterDropdown
                searchParam={'responsible'}
                propToShowInList={'name'}
                valuePropName={'value'}
                data={usersSelectList || []}
                placeHolder={'Ответственный'}
                withFilter={true}
                style={{ width: 200 }}
              />
              <AppFilterDropdown
                searchParam={'co_executor'}
                propToShowInList={'name'}
                valuePropName={'value'}
                data={usersSelectList || []}
                placeHolder={'Участник'}
                withFilter={true}
                style={{ width: 200 }}
              />
              <AppFilterDropdown
                searchParam={'is_published'}
                propToShowInList={'name'}
                valuePropName={'value'}
                data={publishedList || []}
                placeHolder={'Черновик'}
                withFilter={true}
                style={{ width: 200 }}
              />
            </div>
          </div>
          <div className="actions-right-block">
            <AppButton
              path={AllRoutes.elementCreate.path}
              icoClassName={'an-ico-plus-thin'}
              title={{
                label: 'entity_element_create__txt_nav_bnt_title',
                options: { entityName: 'Проект' },
              }}
              color={AppButtonColor.lightBlue}
              size={AppButtonSize.small}
            />
          </div>
        </div>
      </div>
      {/*<AppTable
        headerTitles={tableHeaders}
        cellWidth={tableConfig.width}
        tableDataSelectors={tableSelectors}
        data={data ? data : null}
        tableAsEntity={true}
        lastColAlignLeft={true}
            />*/}
      <NewTable headerTitles={tableHeaders} tableDataSelectors={tableSelectors} dataTable={data ? data : null}/>
    </>
  )
}
