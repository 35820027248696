import React, { useCallback, useMemo, useState } from 'react'
import { AppTranslation } from '../../../common/components/AppTranslation/AppTranslation'
import { AllRoutes } from '../../../core/routes/AllRoutes'
import { AppTable } from '../../../common/components/AppTable/AppTable'
import { HeaderSearchInput } from '../../../common/components/HeaderSearchInput/HeaderSearchInput'
import {
  AppButton,
  AppButtonColor,
  AppButtonSize,
} from '../../../common/components/AppButton/AppButton'
import { AppTableCell } from '../../../common/components/AppTable/AppTableCell'
import {
  useDelSourceMutation,
  useGetAllSourcesQuery,
} from '../../../core/api/BaseApiEndpoints/Sources/SourcesApi'
import { SourceDTO } from '../../../core/api/dto/SourcesDto'
import { TableExecutors } from '../../../common/components/Tasks/TableExecutors'
import { AppCheckbox } from '../../../common/components/AppCheckbox/AppCheckbox'
import { useSearchParamsHelper } from '../../../helpers/setSearchParam'
import {
  ICheckedItems,
  MakeGroupFromCheckedSourcesPopup,
} from './MakeGroupFromCheckedSourcesPopup'
import {
  PageNavTabs,
  PageNavTabsType,
} from '../../../common/components/Breadcrumbs/PageNavTabs'
import { AddSourcePopup } from './AddSourcePopup'
import { AddSourceFromListPopup } from './AddSourceFromListPopup'

const tableConfig = {
  tableHeaders: [
    'sources_page__txt_table_header_name',
    'sources_page__txt_table_header_url',
    'sources_page__txt_table_header_creator',
    'sources_page__txt_table_header_email',
    'sources_page__txt_table_header_rights',
    'sources_page__txt_table_header_actions',
  ],
  width: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
}

const subPages = [
  {
    name: 'Источники',
    path: `/${AllRoutes.sources.path}/${AllRoutes.sourcesList.path}`,
  },
  {
    name: 'Группы',
    path: `/${AllRoutes.sources.path}/${AllRoutes.sourcesGroups.path}`,
  },
]

export const Sources = () => {
  const { searchParamsString } = useSearchParamsHelper({})
  const { data } = useGetAllSourcesQuery({ searchParamsString })
  const [delSources] = useDelSourceMutation()
  const [checkedItems, setCheckedItems] = useState<{
    count: number
    data: ICheckedItems
  }>({ count: 0, data: {} })
  const [
    makeGroupFromCheckedSourcesPopupOpen,
    setMakeGroupFromCheckedSourcesPopupOpen,
  ] = useState(false)
  const [addSourcePopupOpen, setAddSourcePopupOpen] = useState(false)
  const [addSourcesListPopupOpen, setAddSourcesListPopupOpen] = useState(false)

  const tableSelectors = useMemo(() => {
    return [
      {
        renderItem: (item: SourceDTO) => (
          <AppTableCell>
            <AppTableCell>{item.name}</AppTableCell>
          </AppTableCell>
        ),
      },
      {
        renderItem: (item: SourceDTO) => (
          <AppTableCell>
            <AppTableCell>{item.url}</AppTableCell>
          </AppTableCell>
        ),
      },
      {
        renderItem: (item: SourceDTO) => (
          <AppTableCell>
            <AppTableCell>{item.user.first_name}</AppTableCell>
          </AppTableCell>
        ),
      },
      {
        renderItem: (item: SourceDTO) => (
          <AppTableCell>
            <AppTableCell>
              <TableExecutors data={item.recipients} maxWidth={300} />
            </AppTableCell>
          </AppTableCell>
        ),
      },
      {
        renderItem: (item: SourceDTO) => (
          <AppTableCell>
            <AppTableCell>
              <AppTranslation
                label={
                  item.is_public
                    ? 'sources_page__txt_public'
                    : 'sources_page__txt_private'
                }
              />
            </AppTableCell>
          </AppTableCell>
        ),
      },
      {
        renderItem: (item: SourceDTO) => (
          <AppTableCell>
            <AppTableCell>
              <AppCheckbox
                value={!!checkedItems.data[item.id]}
                onChange={(value) =>
                  setCheckedItems(({ data }) => {
                    const newData = { ...data }
                    if (!value) {
                      delete newData[item.id]
                    } else {
                      newData[item.id] = item
                    }

                    return {
                      data: newData,
                      count: Object.keys(newData).length,
                    }
                  })
                }
                uniqId={`item-${item.id}`}
              />
            </AppTableCell>
          </AppTableCell>
        ),
      },
    ]
  }, [checkedItems])

  const onCloseMakeGroupBtnHandler = useCallback(() => {
    setMakeGroupFromCheckedSourcesPopupOpen(false)
    setCheckedItems({ count: 0, data: {} })
  }, [setMakeGroupFromCheckedSourcesPopupOpen, setCheckedItems])

  const groupBtnClickHandler = useCallback(() => {
    setMakeGroupFromCheckedSourcesPopupOpen(true)
  }, [setMakeGroupFromCheckedSourcesPopupOpen])

  const addSourceBtnCLickHandler = useCallback(() => {
    setAddSourcePopupOpen(true)
  }, [setAddSourcePopupOpen])

  const onCloseSourceBtnHandler = useCallback(() => {
    setAddSourcePopupOpen(false)
  }, [setAddSourcePopupOpen])

  const addSourcesListBtnCLickHandler = useCallback(() => {
    setAddSourcesListPopupOpen(true)
  }, [setAddSourcesListPopupOpen])

  const onCloseSourcesListBtnCLickHandler = useCallback(() => {
    setAddSourcesListPopupOpen(false)
  }, [setAddSourcesListPopupOpen])

  const delBtnClickHandler = useCallback(() => {
    const ids = Object.keys(checkedItems.data).map((key) => {
      // @ts-ignore
      return checkedItems.data[key].id
    })
    delSources({ ids: ids.join(',') })
      .unwrap()
      .then(() => {
        setCheckedItems({ count: 0, data: {} })
      })
  }, [checkedItems, setCheckedItems, delSources])

  return (
    <>
      <div className={'back-office-header'}>
        <h1>
          <AppTranslation label={AllRoutes.sources.name} />
        </h1>
        <div className={'header-actions-block'}>
          <div className="actions-left-block">
            <HeaderSearchInput
              searchParam={'search'}
              placeholder={'general__txt_search'}
            />
          </div>
          <div className="actions-right-block">
            <AppButton
              onClick={addSourceBtnCLickHandler}
              icoClassName={'an-ico-plus-thin'}
              title={{
                label: 'sources_page_add_source__txt_btn',
              }}
              color={AppButtonColor.lightBlue}
              size={AppButtonSize.small}
            />
            <AppButton
              onClick={addSourcesListBtnCLickHandler}
              icoClassName={'an-ico-plus-thin'}
              title={{
                label: 'sources_page_add_upload_list__txt_btn',
              }}
              color={AppButtonColor.lightBlue}
              size={AppButtonSize.small}
            />
          </div>
        </div>
        <div className={'header-actions-block'}>
          <div className="actions-left-block">
            <PageNavTabs
              data={subPages}
              propToShow={'name'}
              valuePropName={'path'}
              type={PageNavTabsType.navLink}
            />
          </div>
          <div className="actions-right-block">
            <AppButton
              title={'Сгруппировать'}
              icoClassName={'an-ico-items'}
              color={AppButtonColor.aquaBlue}
              disabled={checkedItems.count < 2}
              size={AppButtonSize.small}
              onClick={groupBtnClickHandler}
            />
            <AppButton
              title={'Удалить'}
              icoClassName={'an-ico-trash'}
              color={AppButtonColor.aquaBlue}
              size={AppButtonSize.small}
              disabled={checkedItems.count === 0}
              onClick={delBtnClickHandler}
            />
          </div>
        </div>
      </div>
      <AppTable
        headerTitles={tableConfig.tableHeaders}
        cellWidth={tableConfig.width}
        tableDataSelectors={tableSelectors}
        data={data ? data : null}
        lastColAlignLeft={true}
      />
      {makeGroupFromCheckedSourcesPopupOpen && (
        <MakeGroupFromCheckedSourcesPopup
          data={checkedItems.data}
          onClose={onCloseMakeGroupBtnHandler}
        />
      )}
      {addSourcePopupOpen && (
        <AddSourcePopup onClose={onCloseSourceBtnHandler} />
      )}
      {addSourcesListPopupOpen && (
        <AddSourceFromListPopup onClose={onCloseSourcesListBtnCLickHandler} />
      )}
    </>
  )
}
