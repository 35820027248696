import React, { useEffect, useMemo } from 'react'
import ReactDOM from 'react-dom'
import {
  AppTranslation,
  IAppTranslation,
  translationLabelTape,
} from '../AppTranslation/AppTranslation'

export enum popupType {
  info = 'info',
  danger = 'danger',
  warning = 'warning',
}

interface IColor {
  [key: string]: string
}

const popupBgColor: IColor = {
  info: 'bg-color-Grey-medium',
  danger: 'bg-color-Red-transparent',
  warning: 'bg-color-Yellow-transparent',
}

interface IAppPopup {
  type?: popupType
  title?: translationLabelTape | IAppTranslation
  description?: translationLabelTape | IAppTranslation
  children?: JSX.Element
  onClose?: () => void
  popupWidth?: number
  tabs?: {
    tabsData: translationLabelTape[]
    toggleHandler: (value: string) => void
    activeTab: string
  }
}

export const AppPopup = React.memo(
  ({
    type = popupType.info,
    children,
    title,
    description,
    onClose,
    popupWidth = 390,
    tabs,
  }: IAppPopup) => {
    useEffect(() => {
      const scrollTop = window.scrollY
      const bodyNode = document.querySelector('body')
      if (bodyNode) {
        bodyNode.style.position = 'fixed'
        bodyNode.style.top = -scrollTop + 'px'
      }
      return () => {
        if (bodyNode) {
          bodyNode.style.removeProperty('top')
          bodyNode.style.removeProperty('position')
          window.scrollTo(0, scrollTop)
        }
      }
    }, [])
    const getRootPopup = () => {
      let PopupRoot = document.getElementById('popup-root')

      if (PopupRoot === null) {
        PopupRoot = document.createElement('div')
        PopupRoot.setAttribute('id', 'popup-root')
        document.body.appendChild(PopupRoot)
      }

      return PopupRoot
    }
    const onCloseHandler = () => {
      if (onClose) {
        onClose()
      }
    }

    const currentTitle = useMemo(() => {
      if (!title) {
        return null
      }
      if (typeof title === 'object') {
        return <AppTranslation label={title.label} options={title.options} />
      } else {
        return <AppTranslation label={title} />
      }
    }, [title])

    const currentDescription = useMemo(() => {
      if (!description) {
        return null
      }
      if (typeof description === 'object') {
        return (
          <AppTranslation
            label={description.label}
            options={description.options}
          />
        )
      } else {
        return <AppTranslation label={description} />
      }
    }, [description])

    const renderContent = () => {
      return (
        <div className="app-popup">
          <div className="popup-content" style={{ width: popupWidth }}>
            {onClose && (
              <div className="btn square popup-close" onClick={onCloseHandler}>
                <i className="an-ico an-ico-cross" />
              </div>
            )}
            {currentTitle && <h1>{currentTitle}</h1>}
            {tabs && (
              <div className="tabs-filter popup-tabs">
                {tabs.tabsData.map((tab) => {
                  return (
                    <div
                      key={`popup-tab-${tab}`}
                      className={`btn small ${
                        tabs.activeTab === tab ? 'black' : ''
                      }`}
                      onClick={() => tabs.toggleHandler(tab)}
                    >
                      {<AppTranslation label={tab} />}
                    </div>
                  )
                })}
              </div>
            )}
            {currentDescription && (
              <p className="color-Grey-medium">{currentDescription}</p>
            )}
            {children && children}
          </div>
          <div
            className={`popup-bg ${popupBgColor[type]}`}
            onClick={onCloseHandler}
          />
        </div>
      )
    }

    return <>{ReactDOM.createPortal(renderContent(), getRootPopup())}</>
  },
)
