import React, { useCallback, useEffect, useState } from 'react'

import {
  AppTranslation,
  translationLabelTape,
} from '../AppTranslation/AppTranslation'
import { ResponsiblePersonView } from '../AppTable/ResponsiblePersonView'
import { AppButton, AppButtonColor } from '../AppButton/AppButton'
import { onChangeExecutorsHandlerType } from './FormExecutors'
import { EditExecutorsPopup } from './EditExecutorsPopup'
import { IBaseProfileDto } from '../../../core/api/dto/ProfilesDto'
import { MultiselectDropdown } from '../AppDropdown/MultiselectDropdown'
import { AppFilterDropdown } from '../AppDropdown/AppFilterDropdown'
import { useGetUsersProfileQuery } from 'core/api/BaseApi'
import { IReferenceValuesSimple } from 'core/api/dto/EntityDto'
import { AppDropdown } from '../AppDropdown/AppDropdown'

interface IExecutorsGroup {
  executors: IBaseProfileDto[] | undefined
  executorsType: 'executor' | 'co_executor'
  label: translationLabelTape
  onChange: onChangeExecutorsHandlerType
  disabled?: boolean
}

export const ExecutorsGroup = ({
  executors,
  executorsType,
  label,
  onChange,
  disabled,
}: IExecutorsGroup) => {
  const [isAddPopupOpen, setIsAddPopupOpen] = useState(false)

  const onChangeHandler = useCallback(
    (executors) => {
      onChange({ executors, propName: executorsType })
    },
    [onChange, executorsType],
  )
  const onPopupBtnClickHandler = useCallback(() => {
    setIsAddPopupOpen((prev) => !prev)
  }, [setIsAddPopupOpen])

  const [currentValue, setCurrentValue] =
    useState<IReferenceValuesSimple | null>(null)

  const { data: profiles } = useGetUsersProfileQuery()

  return (
    <React.Fragment>
      {executorsType == 'co_executor' ? (
        <MultiselectDropdown
          data={profiles ? profiles : []}
          label={`task_create_page__select_title_${executorsType}`}
          propToShowInList={'full_name'}
          valuePropName={'id'}
          value={executors ? executors : []}
          onChange={onChangeHandler}
          disabled={false}
          withFilter={true}
        />
      ) : (
        <AppFilterDropdown
          label={label ? label : `task_create_page__select_title_${executorsType}`}
          searchParam={'full_name'}
          propToShowInList={'full_name'}
          valuePropName={'full_name'}
          data={profiles ? profiles : []}
          placeHolder={'Ответственный'}
          withFilter={true}
          onChange={onChangeHandler}
          value={executors ? executors[0] : null}
        />
      )}
      {/*<AppDropdown
          propToShowInList={'full_name'}
          label={label ? label : `task_create_page__select_title_${executorsType}`}
          data={profiles ? profiles : []}
          withFilter={true}
          onChange={onChangeHandler}
          value={executors && executors[0] ? executors[0] : null}
          disabled={disabled}
      />*/}

      {/*<div className="executors-group">
        <div className="title">
          <AppTranslation label={label} />
        </div>
        <div
          className={`executors-row app-input ${
            !(executors && executors.length > 0) ? 'empty' : ''
          }`}
        >
          <div className="executors-items">
            {executors &&
              executors.map((executor) => {
                return (
                  <div className="executor-item" key={executor.id}>
                    <ResponsiblePersonView name={executor.full_name} />
                  </div>
                )
              })}
          </div>
          {!disabled && (
            <div className="executors-actions">
              {/*<AppButton
                icoClassName={'an-ico-edit'}
                color={AppButtonColor.lightBlue}
                disabled={!executors || executors.length === 0}
                onClick={onPopupBtnClickHandler}
                />*/}
      {/*<AppButton
                icoClassName={'an-ico-plus-thin'}
                color={AppButtonColor.lightBlue}
                onClick={onPopupBtnClickHandler}
              />
            </div>
          )}
        </div>
      </div>
      {isAddPopupOpen && (
        <EditExecutorsPopup
          executors={executors ? executors : []}
          executorsType={executorsType}
          onClose={onPopupBtnClickHandler}
          onChange={onChangeHandler}
        />
      )}*/}
    </React.Fragment>
  )
}
