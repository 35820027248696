import React from 'react'

interface IToolTips {
  children: JSX.Element
  tooltipText: string
}

export const ToolTips = ({ children }: IToolTips) => {
  return <>{children}</>
}
