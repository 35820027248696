import { SectionWidget } from '../SectionWidget/SectionWidget'
import {
  IEntitiesObject,
  IReferenceValuesSimple,
} from '../../../core/api/dto/EntityDto'
import React, { FC, useMemo, useState } from 'react'
import { AppPopup, popupType } from '../AppPopup/AppPopup'
import { Link } from 'react-router-dom'
import { AllRoutes } from '../../../core/routes/AllRoutes'
import { HeaderSearchInput } from '../HeaderSearchInput/HeaderSearchInput'
import { useSearchParamsHelper } from 'helpers/setSearchParam'
import { useGetEntityByIdQuery } from 'core/api/BaseApiEndpoints/Entity/EntityApi'
import { PersonsRepresentWidget } from '../PersonsRepresentWidget/PersonsRepresentWidget'

type PropsType = {
  data: IEntitiesObject
}

export const EntityAnaliticWidget: FC<PropsType> = ({ data }) => {
  const [selectedKey, setSelectedKey] = useState<string | null>(null)
  const [selectedId, setSelectedId] = useState<string | null>(null)
  const { searchParamsString } = useSearchParamsHelper({})
  const { data: materialData } = useGetEntityByIdQuery({
    entitySymbolKey: 'Материал',
    entityId: data.id.toString(),
  })

  const relatedKeys = useMemo(() => {
    return Object.keys(data)
  }, [data])

  return (
    <div className="entity-related-widget">
      <div className="col-12">
        <div className="form-block">
          <div className="block-section">
            <SectionWidget title={''}>
              <>
                <div>
                  <b>Готовый материал</b>
                  <div
                    style={{ display: 'grid', gridTemplateColumns: '1fr 6fr' }}
                  >
                    <div
                      style={{
                        width: 150,
                        background: '#e5f0ff',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: 10,
                        padding: 10,
                      }}
                    >
                      Название
                    </div>
                    <Link
                      to={`/${AllRoutes.entity.path}/${selectedKey}/${data.id}`}
                      onClick={() => setSelectedKey(null)}
                      className="entity-related-widget-popup__link"
                    >
                      {data.title}
                    </Link>
                  </div>
                  <div
                    style={{ display: 'grid', gridTemplateColumns: '1fr 6fr' }}
                  >
                    <div
                      style={{
                        width: 150,
                        background: '#e5f0ff',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: 10,
                        padding: 10,
                      }}
                    >
                      Описание
                    </div>
                    <div>{materialData?.description}</div>
                  </div>
                  <div
                    style={{ display: 'grid', gridTemplateColumns: '1fr 6fr' }}
                  >
                    <div
                      style={{
                        width: 150,
                        background: '#e5f0ff',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: 10,
                        padding: 10,
                      }}
                    >
                      Ответственный
                    </div>
                    <PersonsRepresentWidget
                      data={materialData?.responsible as number[]}
                      dataType={'responsible'}
                    />
                  </div>

                  <div
                    style={{ display: 'grid', gridTemplateColumns: '1fr 6fr' }}
                  >
                    <div
                      style={{
                        width: 150,
                        background: '#e5f0ff',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: 10,
                        padding: 10,
                      }}
                    >
                      Соисполнители
                    </div>
                    {materialData?.coowner && materialData?.coowner > 0 ? <PersonsRepresentWidget
                      data={materialData?.coowner as number[]}
                      dataType={'responsible'}
                    /> : '-' }
                  </div>
                </div>
              </>
            </SectionWidget>
          </div>
        </div>
      </div>
      {selectedKey ? (
        <AppPopup
          title={selectedKey}
          onClose={() => setSelectedKey(null)}
          type={popupType.info}
        >
          <div className="entity-related-widget-popup">
            <HeaderSearchInput
              searchParam={'search'}
              placeholder={'general__txt_search'}
            />
          </div>
        </AppPopup>
      ) : null}
    </div>
  )
}
