import { api } from '../../BaseApi'

export const membersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getOrganizationStructure: builder.query<any, void>({
      query: () => ({
        url: `/organization-structure/`,
      }),
      providesTags: ['Members'],
    }),
    getMembers: builder.query<any, {searchParamsString?: string | void | null }>({
      query: ({searchParamsString}) => ({
        url: `/staff/${
          searchParamsString ? `?${searchParamsString}` : ''}`,
      }),
      providesTags: ['Members'],
    }),
  }),
})

export const { useGetOrganizationStructureQuery, useGetMembersQuery } = membersApi
