import { SystemInitialState } from './systemState'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { REHYDRATE } from 'redux-persist/es/constants'

export const systemSlice = createSlice({
  name: 'system',
  initialState: SystemInitialState,
  reducers: {
    setConnectionError: (
      state,
      { payload: { value } }: PayloadAction<{ value: boolean }>,
    ) => {
      state.connectionError = value
    },
    setPushNotificationError: (
      state,
      { payload: { value } }: PayloadAction<{ value: boolean }>,
    ) => {
      state.pushNotificationError = value
    },
  },
  extraReducers: (builder) => {
    builder.addCase(REHYDRATE, (state) => {
      state.rehydrated = true
    })
  },
})

export const { setConnectionError, setPushNotificationError } =
  systemSlice.actions
export const { reducer: SystemReducer } = systemSlice
