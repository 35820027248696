import React from 'react'

export type TagColorType = 'blue'

interface ITagsListingBlock {
  tagsData: string[] | JSX.Element[]
  color?: TagColorType
}

export const TagsListingBlock = ({
  tagsData,
  color = 'blue',
}: ITagsListingBlock) => {
  return (
    <div className="tags-block">
      {tagsData.map((tag, id) => {
        return (
          <div key={`tag-key-${id}`} className={`tag-view ${color}`}>
            {tag}
          </div>
        )
      })}
    </div>
  )
}
