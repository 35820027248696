import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { AppTranslation } from '../AppTranslation/AppTranslation'
import {
  useCreateCommentMutation,
  useEditCommentMutation,
  useGetCommentsByTaskIdQuery,
  useReplyCommentMutation,
} from '../../../core/api/BaseApiEndpoints/Tasks/TaskComments'
import { ICommentBase, ICommentRoot } from '../../../core/api/dto/CommentsDto'
import { AppInput } from '../AppInput/AppInput'
import { AppNotification, NotificationType } from '../Notification/Notification'
import { t } from 'i18next'
import {
  AppButton,
  AppButtonColor,
  AppButtonSize,
} from '../AppButton/AppButton'
import { useSearchParamsHelper } from '../../../helpers/setSearchParam'

interface ICommentItem {
  comment: ICommentBase | ICommentRoot
  isReply?: boolean
}

const CommentItem = ({ comment, isReply }: ICommentItem) => {
  const { setNewSearchParams, getCurrentSearchParamValue, removeSearchParams } =
    useSearchParamsHelper({})
  const currentReplyId = getCurrentSearchParamValue('replyCommentId')
  const currenEditId = getCurrentSearchParamValue('editCommentId')

  const setReplyCommentIdHandler = useCallback(
    (replyCommentId) => {
      if (Number(currentReplyId) === replyCommentId) {
        removeSearchParams(['replyCommentId'])
      } else {
        setNewSearchParams(
          [{ searchParamName: 'replyCommentId', value: replyCommentId }],
          ['editCommentId'],
        )
      }
    },
    [setNewSearchParams],
  )
  const setEditCommentIdHandler = useCallback(
    (editCommentId) => {
      if (Number(currenEditId) === editCommentId) {
        removeSearchParams(['editCommentId'])
      } else {
        setNewSearchParams(
          [{ searchParamName: 'editCommentId', value: editCommentId }],
          ['replyCommentId'],
        )
      }
    },
    [setNewSearchParams],
  )

  const [isOpen, setIsOpen] = useState(false)
  const [isRepliesOpen, setIsRepliesOpen] = useState(false)
  const [needToHide, setNeedToHide] = useState(false)
  const [height, setHeight] = useState(0)
  const changeOpenHandler = useCallback(() => {
    setIsOpen((prev) => !prev)
  }, [setIsOpen])
  const openRepliesHandler = useCallback(() => {
    setIsRepliesOpen((prev) => !prev)
  }, [setIsRepliesOpen])
  const ref = useRef<HTMLDivElement>(null)

  const setNewHeight = () => {
    setNeedToHide(ref && ref?.current?.clientHeight! > 60)
    setHeight(ref?.current?.clientWidth!)
  }

  useEffect(() => {
    setNewHeight()
    window.addEventListener('resize', setNewHeight, false)
    return () => {
      window.removeEventListener('resize', setNewHeight, false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref])

  return (
    <React.Fragment>
      <div className={`comment-item ${isReply ? 'is-reply' : ''}`}>
        <div className="comment-data">
          <h3 className="person">
            <AppTranslation
              label={`${comment.author.first_name} ${comment.author.last_name} ${comment.author.patronymic || ''}`}
            />
          </h3>
          <div className="date">
            {'created_at' in comment && (
              <AppTranslation
                label={'task_section_widget_date_formatter__txt_title'}
                options={{ date: new Date(`${comment.created_at}`) }}
              />
            )}
          </div>
          {needToHide && (
            <div className="hide-btn" onClick={changeOpenHandler}>
              {isOpen ? (
                <AppTranslation label={'Свернуть'} />
              ) : (
                <AppTranslation label={'Развернуть'} />
              )}
            </div>
          )}
        </div>
        <div
          className={`value ${isOpen ? 'open' : ''} ${
            needToHide ? 'hide-text' : ''
          }`}
          style={{ maxHeight: height }}
        >
          <div className="" ref={ref}>
            {comment.message}
          </div>
        </div>
        {!isReply && (
          <div className="comment-actions">
            {'replies' in comment && comment.replies.length > 0 && (
              <div
                className={`answers ${isRepliesOpen ? 'open' : ''}`}
                onClick={openRepliesHandler}
              >
                {isRepliesOpen ? (
                  <AppTranslation label={'Скрыть ответы'} />
                ) : (
                  <AppTranslation label={'Показать ответы'} />
                )}
              </div>
            )}
            <div
              className={`edit ${
                Number(currenEditId) === comment.id && 'active'
              }`}
              onClick={() => setEditCommentIdHandler(comment.id)}
            >
              <AppTranslation label={'Редактировать'} />
            </div>
            <div
              className={`reply ${
                Number(currentReplyId) === comment.id && 'active'
              }`}
              onClick={() => setReplyCommentIdHandler(comment.id)}
            >
              <AppTranslation label={'Ответить'} />
            </div>
          </div>
        )}
      </div>
      {'replies' in comment &&
        isRepliesOpen &&
        comment.replies.map((repliesComment) => (
          <CommentItem
            comment={repliesComment}
            key={`${comment.id}-${repliesComment.id}`}
            isReply={true}
          />
        ))}
    </React.Fragment>
  )
}

export const Comments = () => {
  const { taskId } = useParams()
  const { data } = useGetCommentsByTaskIdQuery({ taskId: Number(taskId) })
  const { getCurrentSearchParamValue, removeSearchParams } =
    useSearchParamsHelper({})
  const replyCommentId = getCurrentSearchParamValue('replyCommentId')
  const editCommentId = getCurrentSearchParamValue('editCommentId')

  const currentReplyComment = useMemo(
    () =>
      replyCommentId && data
        ? data.items.find((item) => item.id === Number(replyCommentId))
        : null,
    [replyCommentId, data],
  )

  const [createComment] = useCreateCommentMutation()
  const [replyComment] = useReplyCommentMutation()
  const [editComment] = useEditCommentMutation()

  const [message, setMessage] = useState<string>('')

  const closeReplyCommentHandler = useCallback(() => {
    removeSearchParams(['replyCommentId'])
  }, [removeSearchParams])

  useEffect(() => {
    removeSearchParams(['replyCommentId', 'editCommentId'])
  }, [])

  useEffect(() => {
    if (data && editCommentId) {
      const currentComment = data.items.find(
        (item) => item.id === Number(editCommentId),
      )
      if (currentComment) {
        setMessage(currentComment.message)
      }
    } else if (data && replyCommentId) {
      setMessage('')
    } else if (data) {
      setMessage('')
    }
  }, [editCommentId, replyCommentId, data])

  const onMessageChangeHandler = useCallback(
    (newValue: string) => {
      setMessage(newValue)
    },
    [setMessage],
  )

  const onSubmitComment = useCallback(() => {
    if (message !== '') {
      if (!editCommentId && !replyCommentId) {
        createComment({ taskId: Number(taskId), message })
          .unwrap()
          .then(() => {
            AppNotification({
              msg: [t('general__msg_saved')],
              type: NotificationType.success,
            })
          })
      } else if (replyCommentId) {
        replyComment({
          taskId: Number(taskId),
          message,
          parent: Number(replyCommentId),
        })
          .unwrap()
          .then(() => {
            AppNotification({
              msg: [t('general__msg_saved')],
              type: NotificationType.success,
            })
            removeSearchParams(['replyCommentId', 'editCommentId'])
          })
      } else if (editCommentId) {
        editComment({
          commentId: Number(editCommentId),
          message,
        })
          .unwrap()
          .then(() => {
            AppNotification({
              msg: [t('general__msg_saved')],
              type: NotificationType.success,
            })
            removeSearchParams(['replyCommentId', 'editCommentId'])
          })
      }
    }
  }, [createComment, message, editCommentId, replyCommentId])

  return (
    <div className="comments">
      {data && (
        <div className="comment-items">
          {data.items.map((comment) => {
            return <CommentItem comment={comment} key={comment.id} />
          })}
        </div>
      )}
      {currentReplyComment && (
        <div className="reply-message">
          <i className="an-ico an-ico-reply" />
          <div className="reply-message-wrapper">
            <div className="message-content">
              <div className="person">
                <AppTranslation
                  label={`${currentReplyComment.author.first_name} ${currentReplyComment.author.last_name}`}
                />
              </div>
              <div className="message text-ellipsis">
                {currentReplyComment.message}
              </div>
            </div>
          </div>
          <i className="an-ico an-ico-del" onClick={closeReplyCommentHandler} />
        </div>
      )}
      <div className="add-comment-block">
        <AppInput
          onChange={onMessageChangeHandler}
          value={message}
          fullWidth={true}
          isTextArea={true}
          rows={4}
        />
        <div style={{ width: 250 }}>
          <AppButton
            onClick={onSubmitComment}
            fullWidth={true}
            title={
              editCommentId
                ? 'Изменить'
                : replyCommentId
                ? 'Ответить'
                : 'Отправить'
            }
            color={AppButtonColor.aquaBlue}
            size={AppButtonSize.small}
          />
        </div>
      </div>
    </div>
  )
}
