import { useEffect, useState } from 'react'
import { useGetEntityCalendarQuery } from 'core/api/BaseApiEndpoints/Entity/EntityApi'
import { useParams } from 'react-router'
import { TaskStatusBar } from '../Tasks/TaskStatusBar'
import { Link } from 'react-router-dom'

export const EventsCards = (props: { widthItem: number; date: any[]; events: any }) => {
  const { entitySymbolKey } = useParams()
  const data = props.events
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [event, setEvent] = useState<Event | null>(null)

  const sideToCut = (event: any) => {
    const event_start_date = new Date(event.event_start_date)
    const event_end_date = new Date(event.event_end_date)
    const isStart =
      props?.date[1] <= event_start_date && props?.date[2] >= event_start_date
    const isEnd =
      props?.date[1] <= event_end_date && props?.date[2] >= event_end_date
    return 'noCut'
    /*isStart
      ? isEnd
        ? 'noCut'
        : 'cutRight'
      : isEnd
      ? 'cutLeft'
      : 'cutBoth'*/
  }

  const sideToShow = (event: any) => {
    const event_start_date = new Date(event.event_start_date)
    const event_end_date = new Date(event.event_end_date)
    const isStart = event_start_date <= props?.date[2]
    const isEnd = event_start_date >= props?.date[1]
    return isStart && isEnd
  }

  useEffect(() => {
    console.log(
      'event',
      data?.filter((event: any) => sideToShow(event)),
    )
  }, [props])

  const daysDuration = (event: any) => {
    return Math.ceil(
      Math.abs(
        new Date(event?.event_end_date).getTime() -
          new Date(event?.event_start_date).getTime(),
      ) /
        (1000 * 3600 * 24),
    )
  }
  const cardWidth = (event: any) => {
    return (
      (props.widthItem - props.widthItem / 6) / props.date[3]
    )
  }

  const fromStart = (event: any) => {
    console.log('fromLeft', props.widthItem / props.date[3])
    var date = new Date(event?.event_start_date);
    date.setHours(0, 0, 0);
    console.log('!!!!!@@@@@@@@!!!!!!!', props.widthItem, props.date[3])
    return (
      (Math.ceil(
        Math.abs(date.getTime() - props?.date[1]) /
          (1000 * 3600),
      ) +
        7) *
      (props.widthItem / props.date[3] / 24)
    )
  }

  const fromStartMinus = (event: any) => {
    var date = new Date(event?.event_start_date);
    date.setHours(0, 0, 0);
    console.log('fromLeft',(Math.ceil(
      Math.abs(date.getTime() - props?.date[1]) /
        (1000 * 3600 * 24),
    ) + 1 ))
    return (
      (Math.ceil(
        Math.abs(date.getTime() - props?.date[1]) /
          (1000 * 3600 * 24),
      ) + 1 ) * (props.widthItem / props.date[3] )
    )
  }

  return (
    <div className="events" style={{position:"fixed", top: 200}}>
      <div className="events-list">
        {data
          ?.filter((event: any) => sideToShow(event))
          .map((event: any, index: number) => {
            return (
              <Link
                key={index}
                className={`calendar-event ${
                  sideToShow(event) ? 'show' : ''
                } ${sideToCut(event)} ${
                  cardWidth(event) / 50 < 3 ? 'small' : ''
                }`}
                style={{
                  height: "auto",
                  background: "#e5f0ff",
                  width: cardWidth(event),
                  top: index * 60,
                  left:
                  props.date[3] > 20 
                      ? fromStartMinus(event) - 10
                      : fromStart(event) - 20,
                }}
                to={`/entity/Инфоповод/${event.pk}`}
              >
                <div
                  className={'eventLeft'}
                >
                  <div className={'rem_eventy'}>
                    <div
                      className={'rem_eventTitle'}
                      style={{
                        width:
                          cardWidth(event) -
                          (cardWidth(event) / 50 < 3
                            ? 9
                            : cardWidth(event) / 50 < 4
                            ? 28
                            : 87),
                      }}
                    >
                      {event.title}
                    </div>
                    <div className={'rem_priority'}>{event.priority}</div>
                  </div>
                  <div
                    className={`'rem_duration ${
                      cardWidth(event) / 50 < 2 && 'rem_small'
                    }`}
                  >
                    {event?.event_start_date.slice(0,10)}
                  </div>
                </div>
                {cardWidth(event) / 50 > 3 && (
                  <div className={'rem_performer'}>{event.performer}</div>
                )}
              </Link>
            )
          })}
      </div>
    </div>
  )
}
