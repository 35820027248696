import React, { useEffect, useState } from 'react'
import { AppDropdown } from './AppDropdown'
import { ITaskPriority } from '../../../core/api/dto/TasksDto'

interface ITaskPrioritySelect {
  defaultValue: string | null
  onChange: (value: string) => void
  error?: string | boolean
  valueRows?: number
  label?: string
  disabled?: boolean
}

interface ISelectItem {
  value: ITaskPriority
  name: string
}

export const prioritySelectData: ISelectItem[] = [
  { value: ITaskPriority.LOW, name: 'task_priority_LOW' },
  { value: ITaskPriority.MEDIUM, name: 'task_priority_MEDIUM' },
  { value: ITaskPriority.HIGH, name: 'task_priority_HIGH' },
]

export const TaskPrioritySelect = ({
  defaultValue,
  onChange,
  error,
  valueRows,
  label,
  disabled,
}: ITaskPrioritySelect) => {
  const [currentValue, setCurrentValue] = useState<ISelectItem | null>(null)

  useEffect(() => {
    if (prioritySelectData && defaultValue) {
      const result = prioritySelectData.find(
        (item) => item.value === defaultValue,
      )
      setCurrentValue(result ? result : null)
    }
  }, [defaultValue])

  const changeHandler = (value: ISelectItem) => {
    onChange(value.value)
  }

  return (
    <AppDropdown
      data={prioritySelectData ? prioritySelectData : []}
      label={label ? label : 'general_select_task_status__txt_default_label'}
      propToShowInList={'name'}
      value={currentValue}
      error={error}
      withFilter={true}
      onChange={changeHandler}
      valueRows={valueRows}
      disabled={disabled}
    />
  )
}
