import React from 'react'
import { NavLink } from 'react-router-dom'

export enum AppTableCellTypes {
  navLink = 'navLink',
  externalLink = 'externalLink',
  ico = 'ico',
}

export enum AppTableCellJustify {
  left = 'justify-left',
  right = 'justify-right',
  center = 'justify-center',
}

interface IAppTableAlign {
  justifyCell?: AppTableCellJustify
}

interface IAppTableCellJsx extends IAppTableAlign {
  children: JSX.Element | string | number | null
  type?: never
  path?: never
  icoClassName?: never
  className?: string
}

interface IAppTableCellNavLink extends IAppTableAlign {
  children: JSX.Element | string | number | null
  type: AppTableCellTypes.navLink | AppTableCellTypes.externalLink
  path: string
  icoClassName?: never
  className?: string
}

interface IAppTableCellIcoLeft extends IAppTableAlign {
  children?: never
  type: AppTableCellTypes.ico
  icoClassName: string
  path?: never
  className?: never
}

type TProps = IAppTableCellNavLink | IAppTableCellJsx | IAppTableCellIcoLeft

export const AppTableCell = ({
  children,
  type,
  path,
  icoClassName,
  className = '',
  justifyCell,
}: TProps) => {
  const valueToShow = children ? children : '---'
  const justifyProps = justifyCell ? justifyCell : AppTableCellJustify.left
  switch (type) {
    case AppTableCellTypes.navLink:
      return (
        <NavLink className={`${className} ${justifyProps}`} to={path!}>
          {valueToShow}
        </NavLink>
      )
    case AppTableCellTypes.externalLink:
      return (
        <div className={`app-table-cell ${justifyProps}`}>
          <a
            className={`${className}`}
            target="_blank"
            href={path!}
            rel="noreferrer"
          >
            {valueToShow}
          </a>
        </div>
      )
    case AppTableCellTypes.ico:
      return (
        <div
          className={`app-table-cell ${
            justifyCell ? justifyCell : AppTableCellJustify.center
          }`}
        >
          <i className={`an-ico ${icoClassName}`} />
        </div>
      )
    default:
      if (typeof children === 'string') {
        return (
          <div className={`app-table-cell ${justifyProps} ${className}`}>
            <span>{valueToShow}</span>
          </div>
        )
      } else {
        return (
          <div className={`app-table-cell ${justifyProps} ${className}`}>
            {valueToShow}
          </div>
        )
      }
  }
}
