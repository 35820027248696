import React from 'react'
import { add } from 'date-fns'
import { AppTranslation } from '../../common/components/AppTranslation/AppTranslation'

export const UiTime = () => {
  const newDate = new Date()
  const today = add(newDate, { hours: 1 })
  const tomorrow = add(today, { days: 1 })

  return (
    <div className="row">
      <div className="col-12">
        <p>
          <b>OPERATOR ORDERS TABLE TIMESLOTS:</b>
          <br />
          <small>DATE_AND_TIME_STRING</small>
        </p>
        <p>
          <b>today: </b>
          <AppTranslation
            label={'date_time_string'}
            options={{ date: today }}
          />
        </p>
        <p>
          <b>not today: </b>
          <AppTranslation
            label={'date_month_and_time_string'}
            options={{ date: tomorrow }}
          />
        </p>
      </div>
      <div className="col-12">
        <p>
          <small>DATE_MONTH</small>
        </p>
        <p>
          <b>today: </b>
          <AppTranslation
            label={'date_month_day_string'}
            options={{ date: today }}
          />
        </p>
      </div>
      <div className="col-12">
        <p>
          <b>DateSelector:</b>
          <br />
          <small>DATE_YEAR_MONTH_STRING</small>
        </p>
        <p>
          <b>today: </b>
          <AppTranslation
            label={'date_month_year_string'}
            options={{ date: today }}
          />
        </p>
      </div>
      <div className="col-12">
        <p>
          <b>Calendar filter view:</b>
          <br />
          <small>DATE_YEAR_MONTH_DAY_NUMBER</small>
        </p>
        <p>
          <b>today: </b>
          <AppTranslation
            label={'date_year_month_day_number'}
            options={{ date: today }}
          />
        </p>
      </div>
    </div>
  )
}
