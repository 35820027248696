import React, { useCallback, useState } from 'react'
import {
  useCreateSourcesFromListMutation,
  useLazyGetSourcesFileTemplateQuery,
} from '../../../core/api/BaseApiEndpoints/Sources/SourcesApi'
import { AppPopup } from '../../../common/components/AppPopup/AppPopup'
import { AppCheckbox } from '../../../common/components/AppCheckbox/AppCheckbox'
import {
  AppButton,
  AppButtonColor,
  AppButtonSize,
} from '../../../common/components/AppButton/AppButton'
import AppInputFile from '../../../common/components/AppInputFile/AppInputFile'
import { AppTranslation } from '../../../common/components/AppTranslation/AppTranslation'
import {
  AppNotification,
  NotificationType,
} from '../../../common/components/Notification/Notification'

interface IAddSourcePopup {
  onClose: () => void
}

export const AddSourceFromListPopup = ({ onClose }: IAddSourcePopup) => {
  const [createSource] = useCreateSourcesFromListMutation()
  const [getTemplate] = useLazyGetSourcesFileTemplateQuery()
  const [file, setFile] = useState<null | File>(null)
  const [state, setState] = useState({
    is_public: false,
  })

  const getTemplateHandler = useCallback(() => {
    getTemplate()
      .unwrap()
      .then((file) => {
        const hiddenElement = document.createElement('a')
        const url = window.URL || window.webkitURL
        hiddenElement.href = url.createObjectURL(file)
        hiddenElement.target = '_blank'
        hiddenElement.download = `file.csv`
        hiddenElement.click()
        hiddenElement.remove()
      })
  }, [getTemplate])

  const onSaveHandler = () => {
    if (file) {
      const formData = new FormData()
      formData.append('is_public', `${state.is_public}`)
      formData.append('file', file, 'file.csv')
      createSource({ data: formData })
        .unwrap()
        .then((result) => {
          AppNotification({
            msg: [result],
            type: NotificationType.success,
          })
          onClose()
        })
        .catch((error) => console.log('error => ', error))
    }
  }
  return (
    <AppPopup onClose={onClose} popupWidth={600}>
      <div className="row">
        <div className="col-8">
          <AppInputFile
            title={'Загрузить XLSX файл'}
            onChangeFile={setFile}
            value={file}
            accept={'.csv'}
            label={'E-mail источников'}
            fullWidth={false}
            color={AppButtonColor.aquaBlue}
            size={AppButtonSize.small}
            icoClassName={'an-ico-plus-thin'}
          />
        </div>
        <div className="col-4">
          <AppCheckbox
            value={state.is_public}
            uniqId={'is_public'}
            label={'Общедоступная'}
            onChange={(value) =>
              setState((prevState) => {
                return { ...prevState, is_public: value }
              })
            }
          />
        </div>
        <div className="col-12">
          <div onClick={getTemplateHandler} className={'decoration-underline'}>
            <AppTranslation label={'Скачать шаблон'} />
          </div>
        </div>
        <div className="col-12">
          <AppButton
            size={AppButtonSize.small}
            onClick={onSaveHandler}
            title={'Создать источники'}
            disabled={!file}
          />
        </div>
      </div>
    </AppPopup>
  )
}
