import { AppTranslation } from 'common/components/AppTranslation/AppTranslation'
import { AllRoutes } from 'core/routes/AllRoutes'
import { useEffect, useState } from 'react'
import { read, utils } from "xlsx";
import "./rating-styles.scss";

const url = 'https://tsr.glassen-it.com/rating.xlsx'

export const RatingListingPage = () => {
  const [columns, setColumns] = useState([])
  const [columns2, setColumns2] = useState([])
  const [rows, setRows] = useState([])
  const [title, setTitle] = useState([])

  useEffect(() => {
    ;(async () => {
      const wb = read(await (await fetch(url)).arrayBuffer())

      /* use sheet_to_json with header: 1 to generate an array of arrays */
      const data: any = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {
        header: 1,
      })
      const full: any = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {
        header: 1,
      })
      setTitle(data[20][0])
      /* see react-data-grid docs to understand the shape of the expected data */
      data[0]?.map((r: any, id: number) => ({ key: id, name: r }))
      let col: any = []
      Array.prototype.push.apply(col, data[0])
      setColumns(col)

      data[2]?.map((r: any, id: number) => ({ key: id, name: r }))
      let col2: any = []
      Array.prototype.push.apply(col2, data[2])
      setColumns2(col2)

      data.slice(0)?.map((r: any[]) =>
        r.reduce((acc: { [x: string]: any }, x: any, i: string | number) => {
          acc[data[0][i]] = x
          return acc
        }, {}),
      )

      let rows: any = []
      Array.prototype.push.apply(rows, data.slice(3, 20))
      const dataRows = rows.filter((item:any) => item.length > 1)
      dataRows.map((item:any) => item)

      setRows(dataRows)
    })()
  }, [])

  let maximum = []
  // @ts-ignore
  for (var i = 1; i < rows[0]?.length; i++) {
    maximum[i] = rows?.map((item:any) =>
      typeof item[i] == 'number'
        ? item[i]
        : +item[i]?.split('(')[0].replace(/\s/g, ''),
    )
  }

  let max = maximum.map((item) => Math.max.apply(null, item))
  const compareType = (it:any) => {
    if (typeof it == 'number') return it
    else return +it.split('(')[0].replace(/\s/g, '')
  }
  return (
    <div>
      <div className={'back-office-header'}>
        <h1>
          <AppTranslation label={AllRoutes.rating.name} />
        </h1>
      </div>
      <>
 
        <h2 className="title">{title}</h2>
        <div className="wrapper">
          <div className="one">{columns[0]}</div>
          <div className="includeOne">{columns[1]}</div>
          <div className="two">{columns2[1]}</div>
          <div className="three">{columns2[2]}</div>

          <div className="includeTwo">{columns[3]}</div>
          <div className="four">{columns2[3]}</div>
          <div className="five">{columns2[4]}</div>
          <div className="six">{columns2[5]}</div>
          <div className="includeThree">{columns[6]}</div>
          <div className="seven">{columns2[6]}</div>
          <div className="eight">{columns2[7]}</div>
          <div className="nine">{columns2[8]}</div>
          <div className="ten">{columns2[9]}</div>
          <div className="eleven">{columns[10]}</div>
          <div className="twelve">{columns[11]}</div>
          <div className="thirteen">{columns[12]}</div>
          <div className="fourteen">{columns[13]}</div>
          <div className="fifteen">{columns[14]}</div>
          <div className="sixteen">{columns[15]}</div>
          <div className="seventeen">{columns[16]}</div>

          {rows.map((item: any, index) =>
            item.map((it: any, id:any) => (
              <div
                className={`item${id} ${
                  index <= 2
                    ? 'green'
                    : index > rows.length - 4
                    ? 'red'
                    : 'yellow'
                } ${
                  max[id] == compareType(item[id]) && it != '0' ? 'maximum' : ''
                }`}
                key={index + id}
              >
                {id == 15 ? (it * 100).toFixed(2) + '%' : it}
              </div>
            )),
          )}
        </div>
      </>
    </div>
  )
}
