import React, { useCallback, useEffect, useState } from 'react'
import { useGetInfoBlockTypesReferenceQuery } from '../../../core/api/BaseApiEndpoints/InfoBlocks/InfoBlocksApi'
import { AppDropdown } from './AppDropdown'

import { translationLabelTape } from '../AppTranslation/AppTranslation'
import { IPropType } from '../../../core/api/dto/InfoBlockDto'

interface IDataTypeSelect {
  defaultValue: IPropType | string | null
  onChange: (value: IPropType | null) => void
  error?: translationLabelTape | boolean
  valueRows?: number
  label?: string
  disabled?: boolean
}

export const DataTypeSelect = ({
  defaultValue,
  onChange,
  error,
  valueRows,
  label,
  disabled,
}: IDataTypeSelect) => {
  const { data: projectTypesReferenceData } =
    useGetInfoBlockTypesReferenceQuery()

  const [isInitState, setIsInitState] = useState(true)
  const [currentValue, setCurrentValue] = useState<IPropType | null>(null)

  const changeHandler = useCallback(
    (value: IPropType | null) => {
      onChange(value ? value : null)
    },
    [onChange],
  )

  useEffect(() => {
    if (defaultValue && projectTypesReferenceData) {
      const result = projectTypesReferenceData.find(
        (item) =>
          item.name ===
          (typeof defaultValue === 'string' ? defaultValue : defaultValue.name),
      )
      setCurrentValue(result ? result : null)
      if (isInitState) {
        setIsInitState(false)
      }
    } else if (projectTypesReferenceData && !defaultValue && !isInitState) {
      setCurrentValue(null)
      changeHandler(null)
    }
  }, [projectTypesReferenceData, defaultValue, changeHandler])
  return (
    <AppDropdown
      data={projectTypesReferenceData ? projectTypesReferenceData : []}
      label={label ? label : 'info_blocks_create_page__txt_form_field_type'}
      propToShowInList={'description'}
      value={currentValue}
      error={error}
      onChange={changeHandler}
      valueRows={valueRows}
      disabled={disabled}
    />
  )
}
