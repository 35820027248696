import React from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import { AllRoutes } from '../../../core/routes/AllRoutes'
import { AppTranslation } from '../../../common/components/AppTranslation/AppTranslation'

export const AcceptInvitationLayout = () => {
  return (
    <React.Fragment>
      <h1>
        <AppTranslation label={'accept_invitation__txt_title'} />
      </h1>
      <Outlet />
      <div className="action-more">
        <NavLink className="action-more" to={`../${AllRoutes.login.path}`}>
          <b className="decoration-underline">
            <AppTranslation label={'reset_password_layout__txt_back_btn'} />{' '}
          </b>
        </NavLink>
      </div>
    </React.Fragment>
  )
}
