import {
  createApi,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react'

import { RootState } from '../store/rootReducer'

import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import {
  FetchArgs,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query/fetchBaseQuery'
import { deleteCredentials, setCredentials } from '../store/auth/authSlice'
import { Role } from './dto/RolesDto'
import { IBaseProfileDto } from './dto/ProfilesDto'
import { Mutex } from 'async-mutex'

export interface ICustomErrorDto {
  data:
    | {
        status_code: number
        status_text: string
        form_fields?: { [key: string]: string }
      }
    | string
  status: number
}

interface IFormFields {
  [key: string]: string[]
}

export interface IFormErrorDto {
  data: { status_code: number; status_text: string; form_fields: IFormFields }
  status: number
}

const mutex = new Mutex()

const baseQueryWithToken = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_URL}`,
  prepareHeaders: (headers, { getState }) => {
    // const contentType = headers.get('Content-Type')
    // headers.set('Content-Type', contentType ? contentType : 'application/json')
    const currentRole = (getState() as RootState).mediaPlanAuth.currentRole
    const token = (getState() as RootState).mediaPlanAuth[currentRole]!
      .credentials.access
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }
    return headers
  },
})

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock()
  let result = await baseQueryWithToken(args, api, extraOptions)
  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire()
      try {
        const { refresh } = (api.getState() as RootState).mediaPlanAuth
          .ROLE_ADMIN!.credentials

        const refreshResult = (await baseQueryWithToken(
          {
            url: `/token/refresh/`,
            method: 'POST',
            body: { refresh },
          },
          api,
          extraOptions,
        )) as { data: { access: string | null; refresh: string | null } }
        if (refreshResult.data) {
          api.dispatch(setCredentials({ ...refreshResult.data }))
          result = await baseQueryWithToken(args, api, extraOptions)
        } else {
          api.dispatch(deleteCredentials({ role: Role.ROLE_ADMIN }))
        }
      } finally {
        // release must be called once the mutex should be released again.
        release()
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock()
      result = await baseQueryWithToken(args, api, extraOptions)
    }
  }
  return result
}

export const api = createApi({
  reducerPath: 'baseApi',
  baseQuery: baseQueryWithReauth as BaseQueryFn<
    string | FetchArgs,
    unknown,
    ICustomErrorDto,
    {},
    FetchBaseQueryMeta
  >,
  // refetchOnMountOrArgChange: 30,
  keepUnusedDataFor: 30,
  refetchOnReconnect: true,
  tagTypes: [
    'User',
    'InfoBlocks',
    'Company',
    'Projects',
    'Tasks',
    'Entity',
    'Members',
    'Source',
    'TaskComments',
  ],
  endpoints: (builder) => ({
    getUsersProfile: builder.query<IBaseProfileDto[], void>({
      query: () => {
        return {
          url: `/profiles/`,
        }
      },
      providesTags: ['User'],
    }),
    getUserProfile: builder.query<IBaseProfileDto, void>({
      query: () => {
        return {
          url: `/profile/me/`,
        }
      },
      providesTags: ['User'],
    }),
  }),
})

export const { useGetUsersProfileQuery, useGetUserProfileQuery } = api
