import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ICustomErrorDto } from '../../../core/api/BaseApi'
import { AppInput } from '../../../common/components/AppInput/AppInput'
import { AppButton } from '../../../common/components/AppButton/AppButton'
import { useResetPasswordMutation } from '../../../core/api/BaseApiEndpoints/Auth/ResetPasswordApi'
import { AllRoutes } from '../../../core/routes/AllRoutes'
import { AppTranslation } from '../../../common/components/AppTranslation/AppTranslation'

const isEmailValid = (email: string) => {
  return /^([A-Za-z0-9_-]+\.)*[A-Za-z0-9_-]+@[A-Za-z0-9_-]+(\.[A-Za-z0-9_-]+)*\.[A-Za-z]{2,6}$/.test(
    email,
  )
}

export const ResetPasswordPage = () => {
  const [resetPassword, { isLoading }] = useResetPasswordMutation()
  const [formState, setFormState] = useState({ email: '' })
  const [error, setError] = useState('')
  const [searchParams] = useSearchParams()
  const resetToken = searchParams.get('token')
  const navigate = useNavigate()

  useEffect(() => {
    if (resetToken) {
      navigate(`${AllRoutes.confirmNewPassword.path}/?token=${resetToken}`)
    }
  }, [resetToken, navigate])
  const formStateChangeHandler = (propName: string, value: string) => {
    setError('')
    setFormState((prev) => {
      return {
        ...prev,
        [propName]: value,
      }
    })
  }

  const sendEmail = () => {
    if (isEmailValid(formState.email) || !isLoading) {
      resetPassword({ email: formState.email })
        .unwrap()
        .then(() => navigate(AllRoutes.emailCheck.path))
        .catch((error: ICustomErrorDto) =>
          setError(
            typeof error.data === 'string'
              ? error.data
              : error.data.status_text,
          ),
        )
    }
  }

  return (
    <React.Fragment>
      {error && (
        <div className="form-error-msg">
          <small className="color-Red">
            <AppTranslation label={'????'} />
          </small>
        </div>
      )}
      <div className={'color-Grey form-msg'}>
        <small>
          <AppTranslation label={'reset_password_page__txt_description'} />
        </small>
      </div>
      <AppInput
        onChange={(value) => formStateChangeHandler('email', value)}
        value={formState.email}
        type={'email'}
        placeholder={'reset_password_page__txt_form_field_email'}
        onPressEnter={sendEmail}
        error={error}
        fullWidth={true}
      />
      <AppButton
        title={'reset_password_page__txt_get_code'}
        onClick={sendEmail}
        disabled={!isEmailValid(formState.email) || isLoading}
      />
    </React.Fragment>
  )
}
