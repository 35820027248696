import React, { PropsWithChildren } from 'react'
import { useSearchParamsHelper } from '../../../helpers/setSearchParam'
import { NavLink } from 'react-router-dom'
import { AppTranslation } from '../AppTranslation/AppTranslation'

export enum tabsNavigationType {
  filter,
  link,
}

interface IFilter<T, TKey> {
  propToShow: TKey
  valuePropName: TKey
  data: T[]
}

interface ITypeFilter<T, TKey> extends IFilter<T, TKey> {
  type: tabsNavigationType.filter
  searchParam: string
}

interface ITypeLink<T, TKey> extends IFilter<T, TKey> {
  type: tabsNavigationType.link
  searchParam?: never
}

type FilterType<T, TKey> = ITypeFilter<T, TKey> | ITypeLink<T, TKey>

const FilterNavigation = <T, TKey extends keyof T>({
  searchParam,
  propToShow,
  valuePropName,
  data,
}: PropsWithChildren<ITypeFilter<T, TKey>>) => {
  const { setNewSearchParams, getCurrentSearchParamValue } =
    useSearchParamsHelper({})

  const currentSearchParamValue = getCurrentSearchParamValue(
    searchParam,
  ) as unknown as T[TKey]

  const changeHandler = (item: T) => {
    setNewSearchParams(
      [{ searchParamName: searchParam, value: `${item[valuePropName]}` }],
      ['page', 'limit'],
    )
  }

  return (
    <div className="tabs-filter">
      {data.map((item, index) => {
        return (
          <div
            key={`${searchParam}-${index}`}
            className={`btn small ${
              !currentSearchParamValue && index === 0
                ? 'black'
                : currentSearchParamValue === item[valuePropName]
                ? 'black'
                : ''
            }`}
            onClick={() => changeHandler(item)}
          >
            <AppTranslation label={`${item[propToShow]}`} />
          </div>
        )
      })}
    </div>
  )
}

const LinkNavigation = <T, TKey extends keyof T>({
  propToShow,
  valuePropName,
  data,
}: PropsWithChildren<ITypeLink<T, TKey>>) => {
  return (
    <div className="tabs-filter">
      {data.map((item, index) => {
        return (
          <NavLink
            key={`tab-${index}`}
            className={({ isActive }) =>
              ` nav-link btn small ${isActive ? 'black' : ''}`
            }
            to={`${item[valuePropName]}`}
          >
            <AppTranslation label={`${item[propToShow]}`} />
          </NavLink>
        )
      })}
    </div>
  )
}

const Navigation = <T, TKey extends keyof T>(
  props: PropsWithChildren<FilterType<T, TKey>>,
) => {
  switch (props.type) {
    case tabsNavigationType.filter:
      return <FilterNavigation {...props} />
    case tabsNavigationType.link:
      return <LinkNavigation {...props} />
  }
}

const typedMemo: <T>(c: T) => T = React.memo
export const TabsNavigation = typedMemo(Navigation)
