import React, { FC, useEffect, useRef, useState } from 'react'
import { AppCheckbox } from '../../../common/components/AppCheckbox/AppCheckbox'

const graphOneValues = [
  570, 420, 390, 410, 305, 210, 375, 410, 315, 400, 515, 600, 111, 231, 511,
  222, 144, 111, 231, 555, 134, 125,
]
const graphTwoValues = [
  350, 10, 490, 210, 205, 110, 475, 20, 115, 50, 30, 300, 211, 31, 11, 22, 44,
  11, 131, 655, 234, 105,
]
const graphThreeValues = [
  20, 115, 50, 234, 105, 11, 22, 44, 11, 131, 345, 30, 300, 211, 31, 350, 10,
  205, 110, 475, 234, 105,
]
const graphFourValues = [
  111, 231, 111, 231, 570, 420, 515, 600, 511, 222, 144, 555, 390, 410, 305,
  210, 375, 410, 315, 400, 515, 55,
]

type CheckboxPropsType = {
  checked: boolean
  color: string
  label: string
  onChange: () => void
}

const Checkbox: FC<CheckboxPropsType> = ({
  checked,
  color,
  label,
  onChange,
}) => {
  return (
    <div
      className={`dashboard-checkbox${
        checked ? ' dashboard-checkbox--active' : ''
      }`}
      onClick={onChange}
    >
      <div className="dashboard-checkbox__wrap">
        <div
          className="dashboard-checkbox__color"
          style={{ backgroundColor: color }}
        />
        <div className="dashboard-checkbox__label">{label}</div>
        <div className="dashboard-checkbox__box">
          <svg
            stroke="#38a7df"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 24 24"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
          </svg>
        </div>
      </div>
    </div>
  )
}

export const DashboardStats = () => {
  const [currentPeriod, setCurrentPeriod] = useState<'day' | 'week' | 'month'>(
    'day',
  )
  const [filter, setFilter] = useState<string[]>([
    'Задачи',
    'Риски',
    'Медиакампании',
    'Инфоповоды',
    'Материалы',
  ])
  const [data, setData] = useState<any[]>([])

  useEffect(() => {
    const mockData: any[] = []
    graphOneValues.map((item, idx) => {
      mockData.push({
        entity: 'Задачи',
        type: idx + 1,
        value: item,
      })
    })
    graphTwoValues.map((item, idx) => {
      mockData.push({
        entity: 'Риски',
        type: idx + 1,
        value: item,
      })
    })
    graphThreeValues.map((item, idx) => {
      mockData.push({
        entity: 'Медиакомпании',
        type: idx + 1,
        value: item,
      })
    })
    graphFourValues.map((item, idx) => {
      mockData.push({
        entity: 'Инфоповоды',
        type: idx + 1,
        value: item,
      })
    })
    setData(mockData)
  }, [])

  const asyncFetch = () => {
    fetch(
      'https://gw.alipayobjects.com/os/antfincdn/PC3daFYjNw/column-data.json',
    )
      .then((response) => response.json())
      .then((json) => {
        console.log('json', json)
        setData(json)
      })
      .catch((error) => {
        console.log('fetch data failed', error)
      })
  }

  const filterHandler = (val: string) => {
    if (filter.includes(val)) {
      setFilter((prevState) => prevState.filter((it) => it !== val))
    } else {
      setFilter((prevState) => [...prevState, val])
    }
  }

  const config = {
    data,
    xField: 'type',
    yField: 'value',
    seriesField: 'entity',
    isGroup: true,
    columnStyle: {
      radius: [20, 20, 0, 0],
    },
  }

  return (
    <div className="dashboard__stats dashboard-stats dashboard-card">
      <div className="dashboard-stats__head">
        <div className="dashboard-stats__checkboxes">
          <Checkbox
            checked={filter.includes('Задачи')}
            color={'#38A7DF'}
            label={'Задачи'}
            onChange={() => filterHandler('Задачи')}
          />
          <Checkbox
            checked={filter.includes('Риски')}
            color={'#F3C8CD'}
            label={'Риски'}
            onChange={() => filterHandler('Риски')}
          />
          <Checkbox
            checked={filter.includes('Медиакампании')}
            color={'#B5DC9E'}
            label={'Медиакампании'}
            onChange={() => filterHandler('Медиакампании')}
          />
          <Checkbox
            checked={filter.includes('Инфоповоды')}
            color={'#B5DC9E'}
            label={'Инфоповоды'}
            onChange={() => filterHandler('Инфоповоды')}
          />
          <Checkbox
            checked={filter.includes('Материалы')}
            color={'#FFD991'}
            label={'Материалы'}
            onChange={() => filterHandler('Материалы')}
          />
        </div>
        <div className="dashboard-stats__period dashboard-period">
          <div
            onClick={() => setCurrentPeriod('day')}
            className={`dashboard-period__item${
              currentPeriod === 'day' ? ' dashboard-period__item--active' : ''
            }`}
          >
            Сегодня
          </div>
          <div
            onClick={() => setCurrentPeriod('week')}
            className={`dashboard-period__item${
              currentPeriod === 'week' ? ' dashboard-period__item--active' : ''
            }`}
          >
            Неделя
          </div>
          <div
            onClick={() => setCurrentPeriod('month')}
            className={`dashboard-period__item${
              currentPeriod === 'month' ? ' dashboard-period__item--active' : ''
            }`}
          >
            Месяц
          </div>
        </div>
      </div>
      <div className="dashboard-stats__wrap">
        {/*<Column {...config} />*/}
        {/*<canvas*/}
        {/*  className="dashboard-stats__chart"*/}
        {/*  width="980"*/}
        {/*  height="284"*/}
        {/*  ref={canvasRef}*/}
        {/*/>*/}
      </div>
    </div>
  )
}
