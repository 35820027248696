import { api } from '../../BaseApi'
import {
  EntityDataType,
  entityTypesDto,
  IEntitiesSchemasDto,
  IEntityItemDto,
  IEntityItemsDto,
  IEntitySchema,
  IReferenceValuesSimple,
} from '../../dto/EntityDto'
import { SourceDTO } from '../../dto/SourcesDto'
import { ITask } from '../../dto/TasksDto'

export const entityApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getEntitiesSchemas: builder.query<IEntitiesSchemasDto, void>({
      query: () => ({
        url: `/schema/`,
      }),
      providesTags: ['Entity'],
    }),
    getEntitySchema: builder.query<IEntitySchema, { entitySymbolKey: string }>({
      query: ({ entitySymbolKey }) => ({
        url: `/schema/${entitySymbolKey}/`,
      }),
      providesTags: ['Entity'],
    }),
    getEntityTypes: builder.query<entityTypesDto, void>({
      query: () => ({
        url: `/schema/names/`,
      }),
      providesTags: ['Entity'],
    }),
    getReferenceValuesForeign: builder.query<
      IReferenceValuesSimple[],
      { ForeignKey: string }
    >({
      query: ({ ForeignKey }) => ({
        url: `/modal/${ForeignKey}/`,
      }),
      providesTags: ['Entity'],
    }),
    createEntity: builder.mutation<
      IEntityItemDto,
      {
        body: { [key: string]: EntityDataType }
        entitySymbolKey: string
      }
    >({
      query: ({ entitySymbolKey, body }) => ({
        url: `/entity/${entitySymbolKey}/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Entity'],
    }),
    createEntityEmail: builder.mutation<
      IEntityItemDto,
      {
        body: { [key: string]: any }
        entitySymbolKey: string
        entityId: string | number
      }
    >({
      query: ({ entitySymbolKey, entityId, body }) => ({
        url: `/entity/${entitySymbolKey}/${entityId}/email/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Entity'],
    }),
    createRelatedEntity: builder.mutation<
      IEntityItemDto,
      {
        entitySymbolKey: string
        entityId: string
        relatedEntityId: string
      }
    >({
      query: ({ entitySymbolKey, entityId, relatedEntityId }) => ({
        url: `/entity/${entitySymbolKey}/${entityId}/${relatedEntityId}/`,
        method: 'POST',
      }),
      invalidatesTags: ['Entity'],
    }),
    updateEntity: builder.mutation<
      IEntityItemDto,
      {
        body: { [key: string]: EntityDataType }
        entitySymbolKey: string
        entityId: string
      }
    >({
      query: ({ entitySymbolKey, entityId, body }) => ({
        url: `/entity/${entitySymbolKey}/${entityId}/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Entity'],
    }),
    getEntityById: builder.query<
      IEntityItemDto,
      { entitySymbolKey: string; entityId: string }
    >({
      query: ({ entitySymbolKey, entityId }) => ({
        url: `/entity/${entitySymbolKey}/${entityId}/`,
      }),
      providesTags: ['Entity'],
    }),
    getEntityByIdHistory: builder.query<
      IEntityItemDto,
      { entitySymbolKey: string; entityId: string }
    >({
      query: ({ entitySymbolKey, entityId }) => ({
        url: `/entity/${entitySymbolKey}/${entityId}/history/`,
      }),
      providesTags: ['Entity'],
    }),
    getEntityItems: builder.query<
      IEntityItemsDto,
      { entitySymbolKey: string; searchParamsString?: string | void | null }
    >({
      query: ({ entitySymbolKey, searchParamsString }) => ({
        url: `/entity/${entitySymbolKey}/list/${
          searchParamsString ? `?${searchParamsString}` : ''
        }`,
      }),
      providesTags: ['Entity'],
    }),
    getEntityItemsShort: builder.query<
      IEntityItemDto[],
      { entitySymbolKey: string }
    >({
      query: ({ entitySymbolKey }) => ({
        url: `/entity/${entitySymbolKey}/short/`,
      }),
      providesTags: ['Entity'],
    }),
    getEntityCalendar: builder.query<
      IEntityItemDto[],
      { entitySymbolKey: string; searchParamsString?: string | void | null }
    >({
      query: ({ entitySymbolKey, searchParamsString }) => ({
        url: `/entity/${entitySymbolKey}/calendar/${
          searchParamsString ? `?${searchParamsString}` : ''
        }`,
      }),
      providesTags: ['Entity'],
    }),
    getEntityCalendarFull: builder.query<
      IEntityItemDto[],
      { entitySymbolKey: string; searchParamsString?: string | void | null }
    >({
      query: ({ entitySymbolKey, searchParamsString }) => ({
        url: `/calendar/${entitySymbolKey ? `?${entitySymbolKey}` : ''}`,
      }),
      providesTags: ['Entity'],
    }),
    entityUploadMedia: builder.mutation<
      IEntityItemDto,
      { data: FormData; entityId: number }
    >({
      query: ({ data, entityId }) => ({
        url: `/entity/upload/images/${entityId}/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Entity'],
    }),
    entityUploadDocuments: builder.mutation<
      IEntityItemDto,
      { data: FormData; entityId: number }
    >({
      query: ({ data, entityId }) => ({
        url: `/entity/upload/docs/${entityId}/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Entity'],
    }),
    delEntity: builder.mutation<
      any,
      {
        entitySymbolKey: string
        entityId: string
      }
    >({
      query: ({ entitySymbolKey, entityId }) => ({
        url: `/entity/${entitySymbolKey}/${entityId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Entity'],
    }),
    getEntitiesReport: builder.query<any, { type: string }>({
      query: ({ type }) => ({
        url: `/report/${type}/`,
      }),
      providesTags: ['Entity'],
    }),
    getInfoReport: builder.query<any, { start: string, end: string }>({
      query: ({ start, end }) => ({
        url: `/report/${start}/${end}/`,
      }),
      providesTags: ['Entity'],
    }),
  }),
})

export const {
  useGetEntitiesSchemasQuery,
  useGetEntitySchemaQuery,
  useGetEntityTypesQuery,
  useGetReferenceValuesForeignQuery,
  useCreateEntityMutation,
  useCreateEntityEmailMutation,
  useCreateRelatedEntityMutation,
  useUpdateEntityMutation,
  useGetEntityByIdQuery,
  useGetEntityByIdHistoryQuery,
  useGetEntityItemsQuery,
  useGetEntityItemsShortQuery,
  useGetEntityCalendarQuery,
  useGetEntityCalendarFullQuery,
  useEntityUploadMediaMutation,
  useEntityUploadDocumentsMutation,
  useDelEntityMutation,
  useGetEntitiesReportQuery,
  useGetInfoReportQuery,
} = entityApi
