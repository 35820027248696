import {
  AppButton,
  AppButtonColor,
  AppButtonSize,
} from 'common/components/AppButton/AppButton'
import { AllRoutes } from 'core/routes/AllRoutes'
import { ProtectedAvaIco } from '../ProtectedImageIco/ProtectedImageIco'
import { useGetUserProfileQuery } from '../../../core/api/BaseApi'

export const UserMainInfo = () => {
  const { data: userData } = useGetUserProfileQuery()

  return (
    <div className="top_left_div">
      <div className="profile_all_info employee-header-block">
        <div className="employee-ava">
          <ProtectedAvaIco src={userData?.photo ? userData?.photo : `/assets/images/ico/person.svg`} />
        </div>
        <div className="profile_info">
          <h2>
            {userData?.first_name || ''} {userData?.last_name || ''}{' '}
            {userData?.patronymic || ''}
          </h2>
          <p>{userData?.post || ''}</p>
          <div className="profile_contacts">
            <div className="profile_contacts_left_side">
              {userData?.department && (
                <p className="employee-description-value">
                  <i className="an-ico an-ico-bag"></i>{' '}
                  {userData?.department || ''}
                </p>
              )}
              {userData?.email && (
                <p className="employee-description-value">
                  <i className="an-ico an-ico-email"></i>{' '}
                  {userData?.email || ''}
                </p>
              )}

              {/*<div className="doc_buttons">
                <AppButton
                  title={'Файлы'}
                  icoClassName={'an-ico-docs'}
                  color={AppButtonColor.aquaBlue}
                />
                <AppButton
                  title={'Черновики'}
                  icoClassName={'an-ico-docs'}
                  color={AppButtonColor.aquaBlue}
                />
                <AppButton
                  title={'Шаблоны'}
                  icoClassName={'an-ico-docs'}
                  color={AppButtonColor.aquaBlue}
                />
              </div>*/}
            </div>
            <div className="profile_contacts_right_side">
            {userData?.address && (
                <p className="employee-description-value">
                  <i className="an-ico an-ico-pin"></i>{' '}
                  {userData?.address || ''}
                </p>
              )}
              {userData?.phone && (
                <p className="employee-description-value">
                  <i className="an-ico an-ico-phone"></i>{' '}
                  {userData?.phone || ''}
                </p>
              )}
              <AppButton
                path={`/tasks/${AllRoutes.tasksCreate.path}`}
                icoClassName={'an-ico-plus-thin'}
                title={{
                  label: 'tasks_create_page__txt_nav_button_title',
                }}
                color={AppButtonColor.lightBlue}
                size={AppButtonSize.small}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
