import React, { useState } from 'react'
import {
  AppInput,
  InputMaskType,
} from '../../common/components/AppInput/AppInput'

export const UiInputs = () => {
  const [input, setInput] = useState('')
  const [inputFloat, setInputFloat] = useState('')
  const [inputNumber, setInputNumber] = useState('')
  const [inputPhone, setInputPhone] = useState('')
  return (
    <div className="row">
      <div className="col-2">
        <AppInput
          label={'normal'}
          onChange={(value) => setInput(value)}
          value={input}
        />
      </div>
      <div className="col-2">
        <AppInput
          label={'float'}
          onChange={(value) => setInputFloat(value)}
          value={inputFloat}
          inputMaskType={InputMaskType.float}
        />
      </div>
      <div className="col-2">
        <AppInput
          label={'integer'}
          onChange={(value) => setInputNumber(value)}
          value={inputNumber}
          inputMaskType={InputMaskType.integer}
        />
      </div>
      <div className="col-2">
        <AppInput
          label={'phone'}
          onChange={(value) => setInputPhone(value)}
          value={inputPhone}
          inputMaskType={InputMaskType.phone}
        />
      </div>
    </div>
  )
}
