import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { authInitialState } from './authState'
import { Role } from '../../api/dto/RolesDto'

const authSlice = createSlice({
  name: 'mediaPlanAuth',
  initialState: authInitialState,
  reducers: {
    setCredentials: (
      state,
      {
        payload,
      }: PayloadAction<{ access: string | null; refresh: string | null }>,
    ) => {
      state[Role.ROLE_ADMIN]!.credentials = {
        ...state[Role.ROLE_ADMIN]!.credentials,
        ...payload,
      }
    },
    deleteCredentials: (
      state,
      { payload: { role } }: PayloadAction<{ role: Role | null }>,
    ) => {
      const currentRole = role ? role : state.currentRole
      state[currentRole]!.credentials.access = null
      state[currentRole]!.credentials.refresh = null
    },
    setCurrentRoleByPath: (
      state,
      {
        payload: { currentRoleByPath },
      }: PayloadAction<{ currentRoleByPath: Role }>,
    ) => {
      state.currentRole = currentRoleByPath
    },
  },
})

export const { setCredentials, deleteCredentials, setCurrentRoleByPath } =
  authSlice.actions
export const { reducer: AuthReducer } = authSlice
