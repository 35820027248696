import React, { useCallback, useMemo } from 'react'
import {
  AppTranslation,
  translationLabelTape,
} from '../AppTranslation/AppTranslation'
import { ConfigProvider } from 'antd'
import ruRU from 'antd/es/locale/ru_RU'
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns'
import generatePicker from 'antd/es/date-picker/generatePicker'
import 'antd/es/date-picker/style/index'
import { RangePickerProps } from 'antd/es/date-picker'
import { endOfDay } from 'date-fns'

if (ruRU.DatePicker) ruRU.DatePicker.lang.locale = 'ru'
const DatePicker = generatePicker<Date>(dateFnsGenerateConfig)

interface IDate {
  showTime?: boolean
  open?: boolean
  value: Date | string | null
  onChange: (value: Date | null) => void
  disabled?: boolean
  fullWidth?: boolean
  error?: translationLabelTape | boolean
  label?: translationLabelTape
  disableDate?: any
}

const AppDatePicker = ({
  value,
  onChange,
  disabled,
  fullWidth,
  error,
  label,
  open,
  showTime,
  disableDate
}: IDate) => {
  const handleOnChange = useCallback(
    (date: Date | null) => {
      onChange(date ? date : null)
    },
    [onChange],
  )

  const currentValue = useMemo(
    () =>
      value ? (typeof value === 'string' ? new Date(value) : value) : null,
    [value],
  )

  const disabledDate = (current: any) => {
    // Can not select days before today
    const date = new Date()
    return disableDate == false ? false : current && current < endOfDay(date.setDate(date.getDate() - 1))
  }

  return (
    <div className={`app-input-wrapper ${fullWidth ? 'full-width-input' : ''}`}>
      {label && (
        <div className={`app-input-label${disabled ? ' disabled' : ''}`}>
          <b>
            <AppTranslation label={label} />
          </b>
        </div>
      )}
      <div className={`app-input ${!value ? ' empty' : ''}`}>
        <ConfigProvider locale={ruRU}>
          <DatePicker
            showTime={showTime}
            format={showTime ? "DD.MM.YYYY HH:mm" : "DD.MM.YYYY"}
            open={open}
            className={'date-picker'}
            value={currentValue}
            onChange={handleOnChange}
            disabled={disabled}
            disabledDate={disabledDate}
            style={{
              height: 'auto',
              width: '100%',
              border: 'none',
              borderRadius: '0px',
              cursor: 'pointer',
              margin: '0px',
              padding: '0 20px 0 0',
            }}
            suffixIcon={<i className="an-ico an-ico-calendar" />}
          />
        </ConfigProvider>
      </div>
      {error && typeof error === 'string' && (
        <div className="error-msg">
          <AppTranslation label={error} />
        </div>
      )}
    </div>
  )
}

export default AppDatePicker
