export interface ISystemState {
  rehydrated: boolean
  connectionError: boolean
  pushNotificationError: boolean
}

export const SystemInitialState: ISystemState = {
  rehydrated: false,
  connectionError: false,
  pushNotificationError: false,
}
