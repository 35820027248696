import React from 'react'
import { Outlet } from 'react-router-dom'
import { NavBar } from '../common/components/NavBar/NavBar'

export const BackOfficePageLayout = () => {
  return (
    <div className="main-container">
      <div className="nav-bar">
        <div className="main-nav-component">
          <NavBar />
        </div>
      </div>
      <div className="content-area container-fluid">
        <Outlet />
      </div>
    </div>
  )
}
