import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import {
  useDelEntityMutation,
  useGetEntityByIdHistoryQuery,
  useGetEntityByIdQuery,
  useGetEntitySchemaQuery,
} from '../../../core/api/BaseApiEndpoints/Entity/EntityApi'
import { Breadcrumbs } from '../../../common/components/Breadcrumbs/Breadcrumbs'
import { AllRoutes } from '../../../core/routes/AllRoutes'
import { SectionWidget } from '../../../common/components/SectionWidget/SectionWidget'
import { FromDatesWidget } from '../../../common/components/Tasks/FromDatesWidget'
import { EntityDetailsWidget } from '../../../common/components/EntityDetailsWidget/EntityDetailsWidget'
import { TargetAudienceWidget } from '../../../common/components/TargetAudienceWidget/TargetAudienceWidget'
import {
  IEntitiesObject,
  IEntityItemDto,
  IReferenceValuesSimple,
} from '../../../core/api/dto/EntityDto'
import { PersonsRepresentWidget } from '../../../common/components/PersonsRepresentWidget/PersonsRepresentWidget'
import { EntityForeignKeyFormViewExtractor } from '../../../common/components/EntityForeignKeyFormViewExtractor/EntityForeignKeyFormViewExtractor'
import {
  AppButton,
  AppButtonColor,
  AppButtonSize,
} from '../../../common/components/AppButton/AppButton'
import { Link, useNavigate } from 'react-router-dom'
import { AppTranslation } from '../../../common/components/AppTranslation/AppTranslation'
import { ISourceGroupDTO } from '../../../core/api/dto/SourcesDto'
import {
  AppPopup,
  popupType,
} from '../../../common/components/AppPopup/AppPopup'
import { MaterialAnalytics } from '../../../common/components/MaterialAnalytics/MaterialAnalytics'
import { EntityRelatedWidget } from '../../../common/components/EntityRelatedWidget/EntityRelatedWidget'
import { SphereWidget } from 'common/components/SphereWidget.tsx/SphereWidget'
import { ItemRepresentByDataType } from './ItemRepresentByDataType'
import { PaperClipOutlined } from '@ant-design/icons'
import { useGetAllSourcesQuery } from 'core/api/BaseApiEndpoints/Sources/SourcesApi'
import { HistoryMode } from './HistoryMode'
import { EntityAnaliticWidget } from 'common/components/EntityRelatedWidget/EntityAnaliticWidget'

export const EntityBlock = (props: { entityId: any; entitySymbolKey: any }) => {
  const entitySymbolKey = props.entitySymbolKey
  const entityId = props.entityId
  const navigate = useNavigate()
  const [isDashboard, setIsDashboard] = useState(false)
  const [currentSource, setCurrentSource] = useState<any>('')
  const [isMaterialAnalytics, setIsMaterialAnalytics] = useState(false)
  const [isHistoryMode, setIsHistoryMode] = useState(false)
  const [showDeleteConfirmPopup, setShowDeleteConfirmPopup] = useState(false)
  const [delEntity] = useDelEntityMutation()
  const { data: entitySchema } = useGetEntitySchemaQuery({
    entitySymbolKey: entitySymbolKey!,
  })
  const { data: detailsData } = useGetEntityByIdQuery({
    entitySymbolKey: entitySymbolKey!,
    entityId: entityId!,
  })
  const { data: historyItems } = useGetEntityByIdHistoryQuery({
    entitySymbolKey: entitySymbolKey!,
    entityId: entityId!,
  })

  const { data: sourcesData } = useGetAllSourcesQuery({})

  const data = useMemo<{
    reservedFields:
      | {
          title: string
          importance: number[]
          description: string
          event_start_date: Date
          event_end_date: Date
          target_audience: number[]
          responsible: number[]
          coowner: number[]
          creator: number
          link: number
          goal: string
          budget: string
          location: string
          recommendation_planning_level: string
          planned_material_type: number[]
          strategy_directions: number[]
          pk: number
          can_be_used_with: string[]
          entities: IEntitiesObject
          parent_entities: IEntitiesObject
          tasks: number[]
          entity_media: any[]
          entity_document: any[]
          sources: number
          is_published: boolean
          history: any
        }
      | {}
    dynamicFields: IEntityItemDto
  }>(() => {
    let result = {
      reservedFields: {},
      dynamicFields: {},
    }

    if (detailsData && entitySchema) {
      const {
        title,
        importance,
        description,
        event_start_date,
        event_end_date,
        target_audience,
        responsible,
        coowner,
        creator,
        link,
        goal,
        budget,
        location,
        recommendation_planning_level,
        planned_material_type,
        strategy_directions,
        pk,
        can_be_used_with,
        entities,
        parent_entities,
        tasks,
        entity_media,
        sources,
        entity_document,
        is_published,
        history,
        ...dynamicFields
      } = detailsData
      result = {
        reservedFields: {
          title: title as string,
          importance: importance as number[],
          description: description as string,
          event_start_date: event_start_date as Date,
          event_end_date: event_end_date as Date,
          target_audience: target_audience as number[],
          responsible: responsible as number[],
          coowner: coowner as number[],
          creator: creator as number,
          link: link as number,
          goal: goal as string,
          budget: budget as string,
          location: location as string,
          recommendation_planning_level:
            recommendation_planning_level as string,
          planned_material_type: planned_material_type as number[],
          strategy_directions: strategy_directions as number[],
          can_be_used_with: can_be_used_with as unknown as string[],
          entities: entities as unknown as IEntitiesObject,
          parent_entities: {
            ...(entities as unknown as IEntitiesObject),
            ...(parent_entities as unknown as IEntitiesObject),
          } as IEntitiesObject,
          pk: pk as number, // TODO -
          tasks: tasks as number[],
          entity_media: entity_media as any,
          entity_document: entity_document as any,
          sources: sources as number,
          is_published: is_published as boolean,
          history: history as any,
        },
        dynamicFields: { dynamicFields },
      }
    }
    return result
  }, [entitySchema, detailsData])

  useEffect(() => {
    setCurrentSource(
      sourcesData?.items
        ?.find((source: any) => source?.id == Number(detailsData?.sources))
        ?.recipients?.join(';'),
    )
  }, [detailsData, sourcesData])

  const subject: any = detailsData?.title
  const body: any = detailsData?.description

  const addSubEntityBtn = useMemo(() => {
    if (!entitySchema || !entitySchema.models) {
      return (
        <React.Fragment>
          <div className={'header-action-block'}>
            <AppButton
              path={`${AllRoutes.entityRootCreateTask.path}/${AllRoutes.tasksListing.path}`}
              color={AppButtonColor.aquaBlue}
              title={'Создать задачу'}
              icoClassName={'an-ico-plus-thin'}
              size={AppButtonSize.small}
            />
            <AppButton
              path={`${AllRoutes.entityElementEdit.path}`}
              color={AppButtonColor.aquaBlue}
              title={'Редактировать'}
              size={AppButtonSize.small}
            />
            {detailsData?.sources && (
              <a
                href={`mailto:${sourcesData?.items
                  ?.find(
                    (source: any) => source?.id == Number(detailsData?.sources),
                  )
                  ?.recipients?.join(';')}?subject=${encodeURIComponent(
                  subject,
                )}&html-body=${encodeURIComponent(body).slice(0, 1500)}`}
                color={AppButtonColor.aquaBlue}
                className="btn aqua-blue small"
              >
                Отправить на почту
              </a>
            )}
          </div>
          <AppButton
            color={AppButtonColor.red}
            title={'Удалить'}
            icoClassName={'an-ico-trash'}
            size={AppButtonSize.small}
            onClick={() => setShowDeleteConfirmPopup(true)}
          />
        </React.Fragment>
      )
    }
    return (
      <React.Fragment>
        <div className={'header-action-block'}>
          {'is_published' in data.reservedFields &&
            data.reservedFields.is_published == true && (
              <>
                {entitySchema.models.map((model:any) => {
                  return (
                    <AppButton
                      key={`add-btn-${model}`}
                      path={`${AllRoutes.entityRootCreateRelated.path}/${model}`}
                      color={AppButtonColor.aquaBlue}
                      title={{
                        label: 'entity_element_edit__txt_add_linked_entity_btn',
                        options: { entityName: model },
                      }}
                      size={AppButtonSize.small}
                    />
                  )
                })}
                <AppButton
                  path={`${AllRoutes.entityRootCreateTask.path}/${AllRoutes.tasksListing.path}`}
                  color={AppButtonColor.aquaBlue}
                  title={'Создать задачу'}
                  icoClassName={'an-ico-plus-thin'}
                  size={AppButtonSize.small}
                />
                <AppButton
                  path={`/${AllRoutes.reports.path}`}
                  color={AppButtonColor.aquaBlue}
                  title={'Отчеты'}
                  size={AppButtonSize.small}
                />
              </>
            )}
          <AppButton
            path={`${AllRoutes.entityElementEdit.path}`}
            color={AppButtonColor.aquaBlue}
            title={'Редактировать'}
            size={AppButtonSize.small}
          />
        </div>
        <AppButton
          color={AppButtonColor.red}
          title={'Удалить'}
          icoClassName={'an-ico-trash'}
          size={AppButtonSize.small}
          onClick={() => setShowDeleteConfirmPopup(true)}
        />
      </React.Fragment>
    )
  }, [entitySchema, data])

  const companiesBreadcrumbs = useMemo(() => {
    return [
      {
        name: {
          label: 'entity_elements_listing_page__txt_title',
          options: { entityName: entitySymbolKey },
        },
        path: `/${AllRoutes.entity.path}/${entitySymbolKey}`,
      },
      {
        name: {
          label: 'entity_element_details__txt_title',
          options: {
            entityTitle: detailsData?.title,
          },
        },
      },
    ]
  }, [detailsData, entitySymbolKey])

  if (!data) {
    return null
  }

  return (
    <React.Fragment>
      <div className="back-office-form">
        <div className="row">
          <div className="col-6">
            <div className="row">
              <div className="col-12">
                <div className="form-block">
                  {'is_published' in data.reservedFields &&
                    data.reservedFields.is_published != null &&
                    !data.reservedFields.is_published && (
                      <span className="form-block_draft">Черновик</span>
                    )}
                  <EntityDetailsWidget
                    title={
                      'title' in data.reservedFields
                        ? data.reservedFields.title
                        : undefined
                    }
                    description={
                      'description' in data.reservedFields
                        ? data.reservedFields.description
                        : undefined
                    }
                    importance={
                      'importance' in data.reservedFields
                        ? data.reservedFields.importance
                        : undefined
                    }
                    goal={
                      'goal' in data.reservedFields
                        ? data.reservedFields.goal
                        : undefined
                    }
                    budget={
                      'budget' in data.reservedFields
                        ? data.reservedFields.budget
                        : undefined
                    }
                    location={
                      'location' in data.reservedFields
                        ? +data.reservedFields.location
                        : undefined
                    }
                    recommendation_planning_level={
                      'recommendation_planning_level' in data.reservedFields
                        ? +data.reservedFields.recommendation_planning_level
                        : undefined
                    }
                  />
                </div>
                <div className="form-block"></div>
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="row">
              {'target_audience' in data.reservedFields &&
                data.reservedFields.target_audience &&
                Object.keys(data.reservedFields.target_audience).length !=
                  0 && (
                  <div className="col-12">
                    <div className="form-block">
                      <div className="block-section">
                        <TargetAudienceWidget
                          targetAudienceData={
                            data.reservedFields.target_audience
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}

              {'planned_material_type' in data.reservedFields &&
                data.reservedFields.planned_material_type &&
                Object.keys(data.reservedFields.planned_material_type).length !=
                  0 && (
                  <div className="col-12">
                    <div className="form-block">
                      <div className="block-section">
                        <SphereWidget
                          targetAudienceData={
                            data.reservedFields.planned_material_type
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}
              {'strategy_directions' in data.reservedFields &&
                data.reservedFields.strategy_directions &&
                Object.keys(data.reservedFields.strategy_directions).length !=
                  0 && (
                  <div className="col-12">
                    <div className="form-block">
                      <div className="block-section">
                        <SphereWidget
                          targetAudienceData={
                            data.reservedFields.strategy_directions
                          }
                          title={'Направления стратегии'}
                          type={'strategy_directions'}
                        />
                      </div>
                    </div>
                  </div>
                )}
              {'location' in data.reservedFields &&
                data.reservedFields.location && (
                  <div className="col-12">
                    <div className="form-block">
                      <div className="block-section">
                        <SphereWidget
                          targetAudienceData={[+data.reservedFields.location]}
                          title={'Локация'}
                          type={'location'}
                        />
                      </div>
                    </div>
                  </div>
                )}
              {'recommendation_planning_level' in data.reservedFields &&
                data.reservedFields.recommendation_planning_level && (
                  <div className="col-12">
                    <div className="form-block">
                      <div className="block-section">
                        <SphereWidget
                          targetAudienceData={[
                            +data.reservedFields.recommendation_planning_level,
                          ]}
                          title={'Рекомендация к уровню планирования'}
                          type={'recommendation_planning_level'}
                        />
                      </div>
                    </div>
                  </div>
                )}
              {('event_start_date' in data.reservedFields ||
                'event_end_date' in data.reservedFields) && (
                <div className="col-12">
                  <div className="form-block">
                    <div className="block-section">
                      <SectionWidget
                        title={'task_section_widget_dates__txt_title'}
                        icoClassName={'an-ico-calendar'}
                      >
                        <FromDatesWidget
                          date_start={
                            'event_start_date' in data.reservedFields
                              ? (data.reservedFields.event_start_date as Date)
                              : undefined
                          }
                          date_end={
                            'event_end_date' in data.reservedFields
                              ? (data.reservedFields.event_end_date as Date)
                              : undefined
                          }
                        />
                      </SectionWidget>
                    </div>
                  </div>
                </div>
              )}
              {'link' in data.reservedFields && data.reservedFields.link && (
                <div className="col-12">
                  <div className="form-block">
                    <div className="block-section">
                      <SectionWidget
                        title={'general_widget_header__txt_link'}
                        icoClassName={'an-ico-link'}
                      >
                        <EntityForeignKeyFormViewExtractor
                          dataType={'link'}
                          value={data.reservedFields.link as unknown as number}
                        />
                      </SectionWidget>
                    </div>
                  </div>
                </div>
              )}
              {'sources' in data.reservedFields && data.reservedFields.sources && (
                <div className="col-12">
                  <div className="form-block">
                    <div className="block-section">
                      <SectionWidget
                        title={'general_widget_header__txt_source'}
                        icoClassName={'an-ico-link'}
                      >
                        <EntityForeignKeyFormViewExtractor
                          dataType={'sources'}
                          value={
                            data.reservedFields.sources as unknown as number
                          }
                        />
                      </SectionWidget>
                    </div>
                  </div>
                </div>
              )}
              {'entity_media' in data.reservedFields &&
                data.reservedFields.entity_media &&
                Array.isArray(data.reservedFields.entity_media) &&
                Object.keys(data.reservedFields.entity_media).length != 0 && (
                  <div className="col-12">
                    <div className="form-block">
                      <div className="block-section">
                        <SectionWidget title={'Медиа'}>
                          <>
                            {data.reservedFields.entity_media.map(
                              (media, index) => {
                                return (
                                  <p key={`media-${index}`}>
                                    <PaperClipOutlined
                                      style={{
                                        fontSize: '20px',
                                        color: '#52acc3',
                                      }}
                                    />{' '}
                                    <a href={media.file} target={'_blank'}>
                                      {media.file.split('media/')[1]}
                                    </a>
                                  </p>
                                )
                              },
                            )}
                          </>
                        </SectionWidget>
                      </div>
                    </div>
                  </div>
                )}
              {'entity_document' in data.reservedFields &&
                data.reservedFields.entity_document &&
                Array.isArray(data.reservedFields.entity_document) &&
                Object.keys(data.reservedFields.entity_document).length !=
                  0 && (
                  <div className="col-12">
                    <div className="form-block">
                      <div className="block-section">
                        <SectionWidget title={'Документы'}>
                          <>
                            {data.reservedFields.entity_document.map(
                              (document, index) => {
                                return (
                                  <p key={`document-${index}`}>
                                    <PaperClipOutlined
                                      style={{
                                        fontSize: '20px',
                                        color: '#52acc3',
                                      }}
                                    />{' '}
                                    <a href={document.file} target={'_blank'}>
                                      {document.file.split('media/')[1]}
                                    </a>
                                  </p>
                                )
                              },
                            )}
                          </>
                        </SectionWidget>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </div>
          <div className="col-3">
            <div className="row">
              {'creator' in data.reservedFields && data.reservedFields.creator && (
                <div className="col-12">
                  <div className="form-block">
                    <div className="block-section">
                      <PersonsRepresentWidget
                        data={data.reservedFields.creator as number}
                        dataType={'creator'}
                        label={'general_widget_header__txt_creator'}
                      />
                    </div>
                  </div>
                </div>
              )}
              {'responsible' in data.reservedFields &&
                data.reservedFields.responsible && (
                  <div className="col-12">
                    <div className="form-block">
                      <div className="block-section">
                        <PersonsRepresentWidget
                          data={data.reservedFields.responsible as number[]}
                          dataType={'responsible'}
                          label={'general_widget_header__txt_responsible'}
                        />
                      </div>
                    </div>
                  </div>
                )}
              {'coowner' in data.reservedFields &&
                data.reservedFields.coowner &&
                Object.keys(data.reservedFields.coowner).length != 0 && (
                  <div className="col-12">
                    <div className="form-block">
                      <div className="block-section">
                        <PersonsRepresentWidget
                          data={data.reservedFields.coowner as number[]}
                          dataType={'coowner'}
                          label={'general_widget_header__txt_coowner'}
                        />
                      </div>
                    </div>
                  </div>
                )}

              {'parent_entities' in data.reservedFields &&
                data.reservedFields.parent_entities &&
                Object.keys(data.reservedFields.parent_entities).length !=
                  0 && (
                  <EntityRelatedWidget
                    data={
                      data.reservedFields.parent_entities as IEntitiesObject
                    }
                  />
                )}
              {/*'entities' in data.reservedFields &&
                  data.reservedFields.entities &&
                  Object.keys(data.reservedFields.entities).length != 0 && (
                    <EntityRelatedWidget
                      data={data.reservedFields.entities as IEntitiesObject}
                    />
                  )*/}
              {'tasks' in data.reservedFields &&
                data.reservedFields.tasks &&
                Array.isArray(data.reservedFields.tasks) &&
                Object.keys(data.reservedFields.tasks).length != 0 && (
                  <div className="col-12">
                    <div className="form-block">
                      <div className="block-section">
                        <SectionWidget title={'Связанные задачи'}>
                          <>
                            {data.reservedFields.tasks.map((item) => {
                              return (
                                <p key={`item-${item}`}>
                                  <i className="an-ico an-ico-link"></i>
                                  <Link
                                    to={`/${AllRoutes.tasksListing.path}/${item}`}
                                  >
                                    <>
                                      <AppTranslation label={'Задача #'} />
                                      {item}
                                    </>
                                  </Link>
                                </p>
                              )
                            })}
                          </>
                        </SectionWidget>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
        {entitySymbolKey == 'Инфоповод' &&
          'entities' in data.reservedFields &&
          data.reservedFields.entities &&
          Object.keys(data.reservedFields.entities).length != 0 &&
          Object.keys(data.reservedFields.entities).includes("Материал") &&
          // @ts-ignore
          data?.reservedFields?.entities['Материал']?.map((it: any, id) => (
            <div key={id}>
              <EntityAnaliticWidget data={it} />
            </div>
          ))}
      </div>
    </React.Fragment>
  )
}
