import React, { useMemo, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { AllRoutes } from '../../../core/routes/AllRoutes'
import { AppTranslation } from '../AppTranslation/AppTranslation'
import { useGetEntityTypesQuery } from '../../../core/api/BaseApiEndpoints/Entity/EntityApi'
import { useGetUserProfileQuery } from '../../../core/api/BaseApi'
import {
  useGetTasksCountsQuery,
  useGetTasksQuery,
} from '../../../core/api/BaseApiEndpoints/Tasks/TasksApi'
import { useGetAllSourcesQuery } from 'core/api/BaseApiEndpoints/Sources/SourcesApi'
import { useGetProjectItemsQuery } from 'core/api/BaseApiEndpoints/Projects/ProjectsApi'

enum navLabelColorTypes {
  NAV_LABEL_COLOR_GREY = 'bg-color-Task-label-background-grey',
  NAV_LABEL_COLOR_BLUE = 'bg-color-Task-label-background-blue',
  NAV_LABEL_COLOR_LAVENDER = 'bg-color-Task-label-background-lavender',
  NAV_LABEL_COLOR_GREEN = 'bg-color-Task-label-background-green',
  NAV_LABEL_COLOR_RED = 'bg-color-Task-label-background-red',
  NAV_LABEL_PLUS = 'an-ico an-ico-plus',
}

interface INavItemBase {
  title: string
  searchParam: string
  count?: number
}

interface INavItemColorLabel extends INavItemBase {
  labelColor?: keyof typeof navLabelColorTypes
  icoClassName?: never
}

interface INavItemIcon extends INavItemBase {
  labelColor?: never
  icoClassName?: string
}

type INavItem = INavItemColorLabel | INavItemIcon

interface INavMenu {
  groupTitle?: string
  groupActionType?: string
  path?: string
  navItems: INavItem[]
}

export const NavBar = () => {
  const { data: entityNames } = useGetEntityTypesQuery()
  const { data: tasksCount } = useGetTasksCountsQuery()
  const { data: tasks } = useGetTasksQuery({})
  const { data: tasksDoing } = useGetTasksQuery({
    searchParamsString: 'status=DOING',
  })
  const { data: tasksDone } = useGetTasksQuery({
    searchParamsString: 'status=DONE',
  })
  const { data: tasksOverdue } = useGetTasksQuery({
    searchParamsString: 'status=OVERDUE',
  })
  const { data: tasksNew } = useGetTasksQuery({
    searchParamsString: 'status=NEW',
  })
  const { data: userData } = useGetUserProfileQuery()
  const { data: sourcesData } = useGetAllSourcesQuery({})
  const { data: projectsData } = useGetProjectItemsQuery({})
  const { entitySymbolKey } = useParams()
  const [activeItem, setActiveItem] = useState('')
  localStorage.setItem('activeItem', activeItem)

  const menuConfig: INavMenu[] = useMemo(() => {
    const navItems: INavItem[] = []
    if (projectsData) {
      navItems.push({
        labelColor: 'NAV_LABEL_COLOR_GREY',
        title: AllRoutes.projects.name,
        count: projectsData.count,
        searchParam: `../${AllRoutes.projects.path}`,
      })
    }
    if (entityNames) {
      entityNames.forEach((item:any) => {
        navItems.push({
          labelColor: 'NAV_LABEL_COLOR_GREY',
          title: item.name,
          count: item.items_count,
          searchParam: item.name,
        })
      })
    }
    if (sourcesData) {
      navItems.push({
        labelColor: 'NAV_LABEL_COLOR_GREY',
        title: AllRoutes.sources.name,
        count: sourcesData.count,
        searchParam: `../${AllRoutes.sources.path}`,
      })
    }
    navItems.push({
      labelColor: 'NAV_LABEL_COLOR_GREY',
      title: AllRoutes.infoCalendar.name,
      searchParam: `../${AllRoutes.infoCalendar.path}`,
    })

    // navItems.push({
    //   labelColor: 'NAV_LABEL_COLOR_GREY',
    //   title: AllRoutes.reports.name,
    //   searchParam: `../${AllRoutes.reports.path}`,
    // })
    return [
      {
        groupTitle: 'Задачи',
        path: AllRoutes.tasksListing.path,
        navItems: [
          {
            labelColor: 'NAV_LABEL_COLOR_GREY',
            title: 'Все',
            count: tasks?.total,
            searchParam: '',
          },
          {
            labelColor: 'NAV_LABEL_COLOR_BLUE',
            title: 'Новые',
            count: tasksNew?.total,
            searchParam: '?status=NEW',
          },
          {
            labelColor: 'NAV_LABEL_COLOR_LAVENDER',
            title: 'В работе',
            count: tasksDoing?.total,
            searchParam: '?status=DOING',
          },
          {
            labelColor: 'NAV_LABEL_COLOR_GREEN',
            title: 'Готово',
            count: tasksDone?.total,
            searchParam: '?status=DONE',
          },
          {
            labelColor: 'NAV_LABEL_COLOR_RED',
            title: 'Просрочено',
            count: tasksOverdue?.total,
            searchParam: '?status=OVERDUE',
          },
          {
            labelColor: 'NAV_LABEL_COLOR_GREY',
            title: 'Календарь задач',
            searchParam: AllRoutes.eventCalendar.path,
          },
          {
            labelColor: 'NAV_LABEL_PLUS',
            title: 'Создать задачу',
            searchParam: 'create',
          },
        ],
      },
      {
        groupTitle: AllRoutes.mediaPlaning.name,
        // groupActionType: 'Создать риск',
        path: AllRoutes.entity.path,
        navItems,
      },
      {
        groupTitle: 'Мониторинг',
        navItems: [
          {
            labelColor: 'NAV_LABEL_COLOR_GREEN',
            title: 'СМИ',
            searchParam: `${AllRoutes.monitoring.path}/summary-smi`,
          },
          {
            labelColor: 'NAV_LABEL_COLOR_GREEN',
            title: 'Соцсети',
            searchParam: `${AllRoutes.monitoring.path}/summary-social`,
          },
          {
            labelColor: 'NAV_LABEL_COLOR_GREEN',
            title: 'Главные темы',
            searchParam: `${AllRoutes.monitoring.path}/feed-general`,
          },
          {
            labelColor: 'NAV_LABEL_COLOR_GREEN',
            title: 'Субъекты',
            searchParam: `${AllRoutes.monitoring.path}/feed-by-posts`,
          },
          {
            labelColor: 'NAV_LABEL_COLOR_GREEN',
            title: 'События',
            searchParam: `${AllRoutes.monitoring.path}/feed-event`,
          },
          {
            labelColor: 'NAV_LABEL_COLOR_GREEN',
            title: 'Сферы',
            searchParam: `${AllRoutes.monitoring.path}/feed-by-spheres`,
          },
          {
            labelColor: 'NAV_LABEL_COLOR_GREEN',
            title: 'Топ СМИ',
            searchParam: `${AllRoutes.monitoring.path}/lk-subject`,
          },
          {
            labelColor: 'NAV_LABEL_COLOR_GREEN',
            title: 'Топ соцсети',
            searchParam: `${AllRoutes.monitoring.path}/lk-social`,
          },
        ],
      },
      {
        groupTitle: 'Дополнительно',
        navItems: [
          {
            labelColor: 'NAV_LABEL_COLOR_GREY',
            title: 'Календарь событий',
            searchParam: AllRoutes.eventCalendar.path,
          },
          {
            labelColor: 'NAV_LABEL_COLOR_GREY',
            title: AllRoutes.employees.name,
            searchParam: AllRoutes.employees.path,
          },
          // {
          //   labelColor: 'NAV_LABEL_COLOR_GREY',
          //   title: AllRoutes.structure.name,
          //   searchParam: AllRoutes.structure.path,
          // },
          {
            labelColor: 'NAV_LABEL_COLOR_GREY',
            title: AllRoutes.rating.name,
            searchParam: AllRoutes.rating.path,
          },
        ],
      },
    ]
  }, [entityNames, tasksCount, tasks, sourcesData, projectsData])

  return (
    <>
      <div className="auth-logo">
        <img src="/assets/images/media-plan-logo.svg" alt="" />
      </div>
      <nav>
        {menuConfig.map((navGroup, navGroupIndex) => (
          <div
            className="nav-group-item"
            key={`navGroupIndex-${navGroupIndex}`}
          >
            {navGroup.groupTitle &&
              (navGroup.path ? (
                <NavLink to={navGroup.path} className="nav-group-title">
                  <AppTranslation label={navGroup.groupTitle} />
                  <i className="title-logo" />
                </NavLink>
              ) : (
                <div className="nav-group-title">
                  <AppTranslation label={navGroup.groupTitle} />
                  <i className="title-logo" />
                </div>
              ))}
            <div className="nav-items">
              {navGroup.groupTitle !== 'Мониторинг'
                ? navGroup.navItems.map((navItem, navItemIndex) => (
                    <NavLink
                      to={`${navGroup.path ? `/${navGroup.path}/` : ''}${
                        navItem.searchParam
                      }`}
                      className={
                        activeItem == navItem.title
                          ? 'nav-item is-active'
                          : 'nav-item'
                      }
                      key={`navGroupIndex-${navGroupIndex}-navItemIndex-${navItemIndex}`}
                      onClick={() => setActiveItem(navItem.title)}
                    >
                      {navItem.icoClassName && (
                        <i className={`an-ico ${navItem.icoClassName}`} />
                      )}
                      {navItem.labelColor && (
                        <div
                          className={`nav-item-color-label ${
                            navLabelColorTypes[navItem.labelColor]
                          }`}
                        />
                      )}
                      <div className="nav-item-title">
                        <AppTranslation label={navItem.title} />
                      </div>
                      <div className="nav-item-count">{navItem.count}</div>
                    </NavLink>
                  ))
                : navGroup.navItems.map((navItem, navItemIndex) => (
                    <NavLink
                      to={navItem.searchParam}
                      key={`navGroupIndex-${navGroupIndex}-navItemIndex-${navItemIndex}`}
                      className={
                        activeItem == navItem.title
                          ? 'nav-item is-active'
                          : 'nav-item'
                      }
                      onClick={() => setActiveItem(navItem.title)}
                    >
                      {navItem.labelColor && (
                        <div
                          className={`nav-item-color-label ${
                            navLabelColorTypes[navItem.labelColor]
                          }`}
                        />
                      )}
                      <div className="nav-item-title">
                        <AppTranslation label={navItem.title} />
                      </div>
                    </NavLink>
                  ))}

              {navGroup.groupActionType && (
                <div className="nav-item">
                  <div className="nav-action-link">
                    <i className="an-ico an-ico-plus" />
                    {navGroup.groupActionType}
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
        <div className="nav-group-item nav-user-block">
          {/*<div className="nav-item">*/}
          {/*  <i className={`an-ico an-ico-settings`} />*/}
          {/*  <NavLink to={'settings'} className="nav-item-title">*/}
          {/*    Настройки*/}
          {/*  </NavLink>*/}
          {/*</div>*/}
          {/*<div className="nav-item">*/}
          {/*  <i className={`an-ico an-ico-help`} />*/}
          {/*  <NavLink to={'help'} className="nav-item-title">*/}
          {/*    Помощь*/}
          {/*  </NavLink>*/}
          {/*</div>*/}
          <div className="user-widget">
            <div className="user-img">
              {userData?.last_name.slice(0, 1) || ''}
              {userData?.first_name?.slice(0, 1) || ''}
            </div>
            <NavLink to={'user'} className="user-txt-group">
              <div className="user-name">
                {userData?.last_name || ''}{' '}
                {userData?.first_name?.slice(0, 1) || ''}.
                {userData?.patronymic?.slice(0, 1) || ''}.
              </div>
              <div className="user-description">{userData?.post || ''}</div>
            </NavLink>
            <NavLink to={'/logout'}>
              <i className={'an-ico an-ico-exit'} />
            </NavLink>
          </div>
          <iframe
            style={{ display: 'none' }}
            src={`https://lk78.glassen-it.com/?login=${userData?.email}&password=${userData?.raw_password}&menu=hide`}
          />
        </div>
      </nav>
    </>
  )
}
