import { IWithPaginationResponse } from './BaseDto'
import { IBaseProfileDto } from './ProfilesDto'
import { IEntitiesObject } from './EntityDto'
import { IFileDto } from './FilesDto'

export interface ITableInTask {
  table_connected_id: number | null
  table: {
    id: number
    name: string
  } | null
}

export enum ITaskStatus {
  DOING = 'DOING',
  NEW = 'NEW',
  DONE = 'DONE',
  OVERDUE = 'OVERDUE',
}

export enum ITaskPriority {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
}

export interface ITaskCreateDTO {
  name: string
  date_end: Date
  date_start?: Date
  description: string
  priority: string
  status?: string
  executor: number[]
  co_executor: number[]
}

export interface ITask extends ITableInTask {
  id: number
  name: string
  status: ITaskStatus
  priority: ITaskPriority
  description: string
  date_start: Date
  date_end: Date
  executor: IBaseProfileDto[]
  co_executor: IBaseProfileDto[]
  entities: IEntitiesObject
  projects?: any
  task_document: IFileDto[]
  task_media: IFileDto[]
}

export interface ITasksDto extends IWithPaginationResponse {
  items: ITask[]
}
