import React from 'react'
import { ProtectedImageIco } from '../../common/components/ProtectedImageIco/ProtectedImageIco'

export const UiImages = () => {
  return (
    <div className="row">
      <div className="col-2 text-a-c">
        <div>
          <small>Protected image (normal url)</small>
        </div>
        <ProtectedImageIco
          src={`https://${process.env.REACT_APP_DOMAIN}/assets/logo@2.png`}
          alt=""
        />
      </div>
      <div className="col-2 text-a-c">
        <div>
          <small>Protected image (broken url)</small>
        </div>
        <ProtectedImageIco
          src={`https://${process.env.REACT_APP_DOMAIN}/assets/logo@2.png`}
          alt=""
        />
      </div>
    </div>
  )
}
