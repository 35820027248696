import React, { useCallback } from 'react'
import { AppCheckbox } from '../AppCheckbox/AppCheckbox'

interface IFormOptions {
  values: { [key: string]: boolean }
  labelPrefix?: string
  onChange: (newPropState: { propName: string; newValue: boolean }) => void
}

interface IOption {
  value: boolean
  labelPrefix?: string
  propName: string
  onChange: (newPropState: { propName: string; newValue: boolean }) => void
}

const OptionRepresent = ({
  value,
  propName,
  onChange,
  labelPrefix,
}: IOption) => {
  const onChangeHandler = useCallback(() => {
    onChange({ propName, newValue: !value })
  }, [onChange, propName, value])
  return (
    <AppCheckbox
      value={value}
      uniqId={propName}
      label={`${labelPrefix ? labelPrefix : ''}${propName}`}
      onChange={onChangeHandler}
    />
  )
}

export const FormOptions = ({
  values,
  onChange,
  labelPrefix,
}: IFormOptions) => {
  return (
    <div className={'options-row'}>
      {Object.keys(values).map((valueKey) => {
        return (
          <div className="options-col" key={valueKey}>
            <OptionRepresent
              value={values[valueKey]}
              onChange={onChange}
              propName={valueKey}
              labelPrefix={labelPrefix}
            />
          </div>
        )
      })}
    </div>
  )
}
